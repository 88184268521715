<div #numberInlineCellEditor class="nkg-inline-edit" (keydown)="onKeyDown($event)" [ngStyle]="cellEditorStyle">
  <ceis-number-input
    [value]="value"
    [maxValue]="maxValue"
    [minValue]="minValue"
    [maxDecimals]="maxDecimals"
    [maxInteger]="maxInteger"
    [inputStyle]="ngStyle"
    [locale]="locale"
    [showPlusSign]="showPlusSign"
    (onchange)="onValueChange($event)"></ceis-number-input>
</div>
