<div class="grid col-12">
  <div class="col-12">
    <ncs-checkbox-basic
      [disabled]="!editMode"
      [title]="'common.labels.use_all_cup_evaluators'"
      [value]="currentItem?.useAllCupEvaluators"
      [label]="'common.labels.use_all_cup_evaluators'"
      (onChange)="onChangeUseCupEvaluators($event)">
    </ncs-checkbox-basic>
  </div>
  <div class="col-12 xl:col-4 lg:col-6 md:col-7 sm:col-10" *ngIf="!currentItem?.useAllCupEvaluators">
    <ncs-generic-search
      [label]="'common.labels.cup_evaluators_by_tenant'"
      [titleGrid]="'common.labels.cup_evaluators_by_tenant'"
      [typeEntitySearch]="cupEvaluatorEntity"
      [value]="selectedCupEvaluators"
      [editable]="editMode"
      [btnMagnifier]="true"
      [multiple]="true"
      [serviceUrl]="'cup_evaluator/' + currentUser?.defaultTenant?.id + '/by_tenant'"
      [paginationRequestParams]="cupEvaluatorRequestParams"
      [fieldDisplay]="['cupEvaluator']"
      [validationResult]="validationResult?.fieldResults?.cuppingSessionCupEvaluators"
      (onUnSelected)="onRemoveCupEvaluator($event)"
      (onchange)="onSelectCupEvaluator($event)"
      (onblur)="onItemFieldBlur('cuppingSessionCupEvaluators')"
      (onSelected)="onSelectCupEvaluator($event)">
    </ncs-generic-search>
  </div>
  <div class="col-12 xl:col-4 lg:col-6 md:col-8 sm:col-10" *ngIf="currentItem?.useAllCupEvaluators">
    <ncs-generic-search
      [label]="'common.labels.all_cup_evaluators'"
      [typeEntitySearch]="cupEvaluatorEntity"
      [btnMagnifier]="true"
      [multiple]="true"
      [value]="selectedCupEvaluators"
      [paginationRequestParams]="cupEvaluatorRequestParams"
      [fieldDisplay]="['cupEvaluator']"
      [validationResult]="validationResult?.fieldResults?.cuppingSessionCupEvaluators"
      (onUnSelected)="onRemoveCupEvaluator($event)"
      (onSelected)="onSelectCupEvaluator($event)"
      (onchange)="onSelectCupEvaluator($event)">
    </ncs-generic-search>
  </div>
  <div class="col-12 xl:col-2 lg:col-2 md:col-4 sm:col-2">
    <div class="col-12">
      <p-button *ngIf="editMode" icon="pi pi-plus" (click)="onEmitGuestDialog()"></p-button>
    </div>
  </div>
</div>
<div class="col-12">
  <nkg-generic-grid
    [id]="'CupEvaluator'"
    [wrappedInCard]="false"
    [agGridStyle]="{ width: '100%', height: '250px' }"
    [rowData]="cuppingSessionCupEvaluatorRowData"
    [colDef]="columnDefsCupEvaluators"
    [gridOptions]="gridCuppingSessionEvaluators"
    [rowId]="'CupEvaluator'">
  </nkg-generic-grid>
</div>
