import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../authorization/services/authorization.service';
import { TimeService } from '../utils/time-service';
import { isNullOrUndefined, nullsafe } from '../utils/object-utils';
import { AbstractCeisService } from '../../core/service/app.abstract.service';
import { AppNotification } from '../../core/domain/models';
import { convertDate } from '../../shared/basic-shared-module/utils/date-utils';
import { Constants } from '../../core/utils/app.constants';

@Injectable()
export class NotificationService extends AbstractCeisService {
  private static WEBSTORAGE_KEY_LAST_NOTIFICATION_DATE = 'lastNotificationDate';
  private static WEBSTORAGE_KEY_LAST_GROWL_DATE = 'lastGrowlDate';
  private static MILLISECONDS_IN_30_DAYS = 1000 * 60 * 60 * 24 * 30;

  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}notifications`;
  }

  /**
   * Retrieve all notifications of the last 30 days from the server.
   *
   * Whether we want to show them as growl and whether they are actually new (i.e. haven't been opened by the user
   * in the notifications bar, is determined by the two timestamps 'growl time' and 'read time'.
   */
  public async getNotifications(): Promise<AppNotification[]> {
    const earliestDate: string = `${new Date(
      new Date().getTime() - NotificationService.MILLISECONDS_IN_30_DAYS,
    ).getTime()}`;
    const res = await this.getWithParams<any[]>(this.actionUrl, { earliestDate: earliestDate }, true, () =>
      this.authService.renewToken(),
    );
    return nullsafe(res).map(n => ({ ...n, createdAt: convertDate(n.createdAt) }));
  }

  public markAsRead(): void {
    this.webStorage.setItem(NotificationService.WEBSTORAGE_KEY_LAST_NOTIFICATION_DATE, `${new Date().getTime()}`);
  }

  public markAsGrowled(): void {
    this.webStorage.setItem(NotificationService.WEBSTORAGE_KEY_LAST_GROWL_DATE, `${new Date().getTime()}`);
  }

  public getLastReadTime(): Date {
    const earliestDate: string = this.webStorage.getItem(NotificationService.WEBSTORAGE_KEY_LAST_NOTIFICATION_DATE);
    if (!isNullOrUndefined(earliestDate)) {
      return new Date(Number(earliestDate));
    }
    return new Date(0);
  }

  public getLastGrowlTime(): Date {
    const lastGrowlTime: string = this.webStorage.getItem(NotificationService.WEBSTORAGE_KEY_LAST_GROWL_DATE);
    if (!isNullOrUndefined(lastGrowlTime)) {
      return new Date(Number(lastGrowlTime));
    }
    return new Date(0);
  }
}
