import { createAction, props } from '@ngrx/store';
import {
  AppNotification,
  AppTimezoneInfo,
  AppVersionInfo,
  ComtrasInstance,
  Country,
  Language,
  PaginatedResponse,
  PaginationRequestParams,
  Role,
  State,
  Tenant,
  View,
} from '../../domain/models';

export enum AppActionTypes {
  FETCH_TIMEZONE = '[App] Get Timezone',
  FETCH_LANGUAGE_LIST = '[App] Fetch Languages',
  FETCH_COUNTRY_LIST = '[App] Fetch Country List',
  FETCH_STATES_LIST = '[App] Fetch States List',
  FETCH_APP_TENANTS = '[App] Fetch Tenants',
  FETCH_APP_TENANTS_SUCCESS = '[App] Fetch Tenants Success',
  FETCH_ROLES = '[App] Fetch Roles',
  FETCH_ROLES_SUCCESS = '[App] Fetch Roles Success',
  FETCH_SEARCH_GENERIC = '[App] Fetch Search Generic Items',
  FETCH_SEARCH_GENERIC_SUCCESS = '[App] Fetch Search Generic Items success',
  FETCH_BACKEND_SUCCESS = '[App] Fetch Success message',
  FETCH_BACKEND_ERROR = '[App] Fetch Error message',
  FETCH_NOTIFICATION = '[App] Fetch Notification',
  SET_LANGUAGES_LIST = '[App] Set Languages List',
  SET_TIMEZONE = '[App] Set Timezone',
  SET_APP_VERSION = '[App] Set App Version',
  SET_VIEW_NAVIGATION = '[App] Set View Navigation',
  SET_NOTIFICATION = '[App] Set Notification',
  SET_COUNTRY_LIST = '[App] Set Country List',
  SET_STATES_LIST = '[App] Set State List',
  ADD_NOTIFICATION = '[App] Add notification from fronted',
  CHANGE_RESPONSE_PENDING = '[APP] Change response pending',
  FETCH_COMTRAS_INSTANCES = '[App] Fetching comtras instances',
  FETCH_COMTRAS_INSTANCES_SUCCESSFUL = '[App] Fetch comtras instances success',
  GET_TENANT_BY_COMTRAS_PREFIX = '[App] Get a tenant by a comtras reference prefix',
  GET_TENANT_BY_COMTRAS_SUCCESS = '[App] Get a tenant by a comtras reference prefix success',
}

export const setBreadcrumbNavigation = createAction(
  AppActionTypes.SET_VIEW_NAVIGATION,
  props<{ view: View; basePath?: string }>(),
);

export const setAllLanguages = createAction(AppActionTypes.SET_LANGUAGES_LIST, props<{ languages: Language[] }>());

export const setAllCountries = createAction(AppActionTypes.SET_COUNTRY_LIST, props<{ countries: Country[] }>());

export const fetchAllStates = createAction(AppActionTypes.FETCH_STATES_LIST);

export const setAllStates = createAction(AppActionTypes.SET_STATES_LIST, props<{ states: State[] }>());

export const setTimezone = createAction(AppActionTypes.SET_TIMEZONE, props<{ timezone: AppTimezoneInfo }>());

export const setAppVersion = createAction(AppActionTypes.SET_APP_VERSION, props<{ appVersion: AppVersionInfo }>());

export const fetchNotification = createAction(AppActionTypes.FETCH_NOTIFICATION, props<{ triggerGrowl: boolean }>());

export const setAppNotifications = createAction(
  AppActionTypes.SET_NOTIFICATION,
  props<{ notifications: AppNotification[] }>(),
);

export const addAppNotification = createAction(
  AppActionTypes.ADD_NOTIFICATION,
  props<{ notification: AppNotification; triggerGrowl: boolean }>(),
);

export const fetchLanguages = createAction(AppActionTypes.FETCH_LANGUAGE_LIST);

export const fetchCountries = createAction(
  AppActionTypes.FETCH_COUNTRY_LIST,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchTimezone = createAction(AppActionTypes.FETCH_TIMEZONE);

export const fetchAllTenants = createAction(AppActionTypes.FETCH_APP_TENANTS);

export const setAllTenants = createAction(AppActionTypes.FETCH_APP_TENANTS_SUCCESS, props<{ tenants: Tenant[] }>());

// TODO remove it is doesn't use
export const fetchRoles = createAction(AppActionTypes.FETCH_ROLES);

export const setRoles = createAction(AppActionTypes.FETCH_ROLES_SUCCESS, props<{ roles: Role[] }>());

export const fetchSearchGenericItems = createAction(
  AppActionTypes.FETCH_SEARCH_GENERIC,
  props<{ typeFunction: Function; paginationRequestParams: PaginationRequestParams; serviceUrl?: string }>(),
);

export const setSearchGenericItems = createAction(
  AppActionTypes.FETCH_SEARCH_GENERIC_SUCCESS,
  props<{ paginationResponse: PaginatedResponse; entityIdentifier?: string }>(),
);

export const setAppMessages = createAction(AppActionTypes.FETCH_BACKEND_SUCCESS, props<{ messages: string[] }>());

export const setAppErrorMessages = createAction(
  AppActionTypes.FETCH_BACKEND_ERROR,
  props<{ errorMessages: string[] }>(),
);

/** Enables the display of the spinner load and disables the user screen.
 * Recommended for use when retrieving data and takes longer than 0.3s.  */
export const setResponsePending = createAction(
  AppActionTypes.CHANGE_RESPONSE_PENDING,
  props<{ isResponsePending: boolean }>(),
);

export const fetchCOMTRASInstance = createAction(
  AppActionTypes.FETCH_COMTRAS_INSTANCES,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const setCOMTRASInstance = createAction(
  AppActionTypes.FETCH_COMTRAS_INSTANCES_SUCCESSFUL,
  props<{ comtrasInstances: ComtrasInstance[] }>(),
);

export const getTenantByCOMTRASPrefix = createAction(
  AppActionTypes.GET_TENANT_BY_COMTRAS_PREFIX,
  props<{ prefix: string; tenantAbbreviation: string }>(),
);

export const setTenantByCOMTRASPrefix = createAction(
  AppActionTypes.GET_TENANT_BY_COMTRAS_SUCCESS,
  props<{ tenant: Tenant }>(),
);
