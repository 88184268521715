import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { DispatchApprovalCredentials } from '../../domain/models';

export enum ApprovalActionTypes {
  VALIDATE_SESSION = '[Guest] Validate Session',
  SESSION_VALIDATED = '[Guest] Session validated ',
  VALIDATION_FAILED = '[Guest] Validate session failed ',
  APPROVE_DISPATCH = '[Guest] Approve dispatch',
  APPROVAL_SUCCESS = '[Guest] Dispatch approved success',
  APPROVING_FAILED = '[Guest] Approving failed',
}

export const approveDispatch = createAction(
  ApprovalActionTypes.APPROVE_DISPATCH,
  props<{ credentials: DispatchApprovalCredentials }>(),
);

export const validateSession = createAction(ApprovalActionTypes.VALIDATE_SESSION, props<{ session: string }>());

export const sessionValidated = createAction(
  ApprovalActionTypes.SESSION_VALIDATED,
  props<{ credential: DispatchApprovalCredentials }>(),
);

export const sessionValidateFailed = createAction(
  ApprovalActionTypes.VALIDATION_FAILED,
  props<{ errorResponse: HttpErrorResponse }>(),
);

export const approvingFailed = createAction(
  ApprovalActionTypes.APPROVING_FAILED,
  props<{ errorResponse: HttpErrorResponse }>(),
);

export const approvalSuccess = createAction(
  ApprovalActionTypes.APPROVAL_SUCCESS,
  props<{ response: DispatchApprovalCredentials }>(),
);
