import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../../core/ngrx/reducers';
import { User } from '../../../core/domain/models';
import { isNullOrUndefined } from '../../utils/object-utils';

export const waitingForUser: () => Observable<User> = () => {
  const store = inject(Store<fromRoot.State>);
  return store.select(fromRoot.getCurrentUser).pipe(filter(user => !isNullOrUndefined(user)));
};
