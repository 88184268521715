import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { PaginationRequestParams, StandardDefinition, StandardDefinitionOption } from '../../domain/models';
import {
  fetchStandardDefinition,
  fetchStandardDefinitionSuccess,
  fetchStandardDefOpt,
  fetchStandardDefOptSuccess,
} from '../actions';
import { QueryService } from '../../../modules/services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { CuppingProcessService } from '../../../modules/master/modules/cupping-session/modules/sample-analysis/modules/cupping-process/services/cupping-process.service';

@Injectable()
export class StandardDefinitionEffects {
  constructor(
    protected actions$: Actions,
    protected queryService: QueryService,
    protected cuppingProcessService: CuppingProcessService,
  ) {}

  public readonly getStandardDefOpt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStandardDefOpt),
      switchMap(() => this.queryService.querySearch(StandardDefinitionOption)),
      map((standardDefOpts: StandardDefinitionOption[]) =>
        fetchStandardDefOptSuccess({ standardDefOptions: standardDefOpts }),
      ),
    ),
  );

  public readonly fetchStandardDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStandardDefinition),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newPagination._include = '*,cupIllustration.cupIllustrationImages';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.cuppingProcessService.getStandardDefinitionsByTenant(
          action.userDefaultTenantId,
          action.paginationRequestParams,
        ),
      ),
      map((standardDefinition: StandardDefinition[]) =>
        fetchStandardDefinitionSuccess({ standardDefinition: standardDefinition }),
      ),
    ),
  );
}
