<div class="layout-rightpanel" [ngClass]="{ 'layout-rightpanel-active': rightPanelVisible }">
  <div class="nano">
    <div class="nano-content right-panel-scroll-content">
      <div class="notifications-list">
        <div *ngIf="newNotifications?.length > 0" class="heading">New</div>
        <div>
          <div class="notification-item" *ngFor="let n of newNotifications" [ngClass]="notificationClass(n) + '-item'">
            <div class="timestamp">
              <span>{{ n.createdAt | date: Constants.getDateFormatShortTime() }}</span>
            </div>
            <div class="content">
              <span class="icon" [ngClass]="notificationClass(n)"></span>
              <span>
                <span class="message" innerHtml="{{ buildMessage(n) }}"></span>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="oldNotifications?.length > 0" class="heading">Earlier</div>
        <div>
          <div class="notification-item" *ngFor="let n of oldNotifications" [ngClass]="notificationClass(n) + '-item'">
            <div class="timestamp">
              <span>{{ n.createdAt | date: Constants.getDateFormatShortTime() }}</span>
            </div>
            <div class="content">
              <span class="icon" [ngClass]="notificationClass(n)"></span>
              <span>
                <span class="message selectable" innerHtml="{{ buildMessage(n) }}"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
