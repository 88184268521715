import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../domain/models';
import { AuthSelector } from '../selectors';
import * as fromRoot from '../reducers';
import { fetchCurrentUser } from '../actions';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Injectable()
export class AuthStoreService {
  private readonly store: Store<fromRoot.State>;
  private readonly selector: AuthSelector;
  private isFetching: boolean = false;

  public constructor(store: Store<fromRoot.State>, selector: AuthSelector) {
    this.store = store;
    this.selector = selector;
  }

  public selectCurrentUser(): Observable<User> {
    return this.store.select(this.selector.currentUser).pipe(
      map(user => {
        this.isFetching = isNullOrUndefined(user);
        return user;
      }),
    );
  }

  public requestCurrentUser(): Promise<boolean> {
    if (!this.isFetching) {
      this.isFetching = true;
      this.store.dispatch(fetchCurrentUser());
    }
    return Promise.resolve(true);
  }
}
