<div
  class="bottombar"
  [ngClass]="{
    xpand: xpand,
    animatingOut: animatingOut,
    animatingIn: animatingIn
  }">
  <ng-content></ng-content>
</div>
<div class="bottombar">
  <span
    class="toggle"
    [ngClass]="{ rotated: xpand }"
    style="
      float: right;
      opacity: 1;
      font-family: Material Icons;
      color: white;
      font-size: 24px;
      line-height: 33px;
      margin-right: 2px;
      cursor: pointer;
    "
    (click)="onExpandBottombar()"
    >more</span
  >
</div>
