<span [ngClass]="{ 'read-only': readOnly }" class="nkg-block autocomplete-magnifier">
  <span *ngIf="label && !onlyGrid" class="mainCaption">{{ label | translate }}</span>
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <nkg-error-tooltip [validationResult]="validationResult">
      <p-autoComplete
        #searchGeneric
        class="ncs-basic-wrapper"
        [ngClass]="{
          'search-magnifier': btnMagnifier,
          'ncs-disabled-wrapper': editable && onlyGrid
        }"
        [appendTo]="'body'"
        [baseZIndex]="100"
        [disabled]="disabled"
        [inputStyle]="{ width: '100%' }"
        [inputStyleClass]="'setFocus'"
        [tabindex]="focusable ? null : -1"
        [ngModel]="internalSelection ? (multiple ? internalSelection : internalSelection[0]) : ''"
        [suggestions]="suggestions"
        [placeholder]="placeholder | translate"
        [autoHighlight]="true"
        [multiple]="multiple"
        [field]="'label'"
        [delay]="100"
        (ngModelChange)="onChangeNgModel($event)"
        (onFocus)="onFocusEvent()"
        (completeMethod)="onSearch($event)"
        (onSelect)="onSelectionEvent($event)"
        (onBlur)="onBlurEvent($event)"
        (onUnselect)="onUnSelection($event)">
        <ng-template let-result pTemplate="item">
          <div [innerHTML]="customResult(result)"></div>
        </ng-template>
      </p-autoComplete>
      <span *ngIf="btnMagnifier && editable" class="btn-magnifier">
        <p-button
          [ngClass]="{ 'search-name-btn': onlyGrid }"
          [icon]="btnIcon"
          [pTooltip]="searchNameBtn"
          [disabled]="disabled || displayGrid"
          (onClick)="onClickMagnifierBtn()">
        </p-button>
      </span>
    </nkg-error-tooltip>
    <ng-content></ng-content>
  </ng-template>
  <!-- only content -->
  <ng-template #secondContent>
    <ul class="read-only-list">
      <li *ngFor="let item of internalSelection; let last = last">
        {{ item.label }}
        <ng-container *ngIf="!last">,&nbsp;</ng-container>
      </li>
    </ul>
  </ng-template>
</span>
<!-- enable filter property is setting as true. Developer criteria for modified -->
<magnifier-basic
  [appendTo]="searchGeneric"
  *ngIf="displayGrid && (colDefArray || colDefinition)"
  [gridColumnType]="genericSearchGridColumnType"
  [titleGrid]="titleGrid"
  [optionsData]="suggestions"
  [colDefArray]="colDefArray"
  [colDefMap]="colDefinition"
  [selectionMode]="multiple ? gridSelectionMode.MULTIPLE : gridSelectionMode.SINGLE"
  [filterEnable]="true"
  [preload]="btnMagnifier"
  [paginationResponse]="pagination"
  (onChangePage)="changePageGrid($event)"
  (onchange)="getResultSelections($event)"
  (onSetQuerySearch)="onSearch($event)"
  (deactivate)="displayGrid = false">
</magnifier-basic>
