import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ValueGetterParams } from 'ag-grid-community';
import { AuthorizationService } from '../../../../authorization/services/authorization.service';
import { TimeService } from '../../../../utils/time-service';
import { QueryService } from '../../../../services/query.service';
import {
  ApprovalStatusLog,
  ComtrasContractData,
  ComtrasStatus,
  CuppingProcess,
  CuppingSession,
  MetricsDefinitionType,
  PaginatedResponse,
  PaginationRequestParams,
  Quality,
  Sample,
  SampleApprovalInternalCondition,
  SampleApprovalInternalLog,
  SampleSearch,
  SampleThirdPartyType,
  SampleType,
  Storage,
  StorageType,
} from '../../../../../core/domain/models';
import { isEmpty, isNotEmpty } from '../../../../utils/string-utils';
import { isNullOrUndefined, nullsafe } from '../../../../utils/object-utils';
import { Rule, Validation } from '../../../services/master-validation';
import { isNumber } from '../../../../utils/number-utils';
import { NkgGridFactory } from '../../../../../shared/grid/services/grid.factory';
import { AbstractCeisService } from '../../../../../core/service/app.abstract.service';
import { Constants } from '../../../../../core/utils/app.constants';
import { BaseMasterGridCol } from '../../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SampleService extends AbstractCeisService {
  private readonly qcUrl: string;
  private currentSampleIdByQuality: number;
  private currentSampleIdByThirdPartyType: number;
  private readonly langQueryUrl: string;
  private readonly SAMPLE_REJECTED_CODE: string;

  constructor(
    http: HttpClient,
    constants: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
    private queryService: QueryService,
    private translationService: TranslateService,
    public gf: NkgGridFactory,
  ) {
    super(http, constants, timeService);
    this.webStorage = window.localStorage;
    this.qcUrl = constants.SERVER_WITH_QC_API_URL;
    this.langQueryUrl = constants.LANG_QUERY_URL;
    this.SAMPLE_REJECTED_CODE = constants.SAMPLE_REJECTED_CODE;
  }

  async fetchSampleTypesForTenant(paginationParams: PaginationRequestParams): Promise<SampleType[]> {
    const resp = await this.getWithParams(`${this.qcUrl}sample_type/tenant`, paginationParams, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(resp);
  }

  async loadQualityByCountryId(countryId: number, query: string, page = 0): Promise<PaginatedResponse> {
    const newQuery = isNotEmpty(query)
      ? `countryId==${countryId};(name==${query},code==${query},coffeeSpecie.coffeeSpecie==${query},millingProcess.millingProcess==${query},coffeeCertifications.name==${query})`
      : `countryId==${countryId}`;
    const paginationRequest = new PaginationRequestParams();
    paginationRequest._include =
      'coffeeSpecie,coffeeCertifications,millingProcess,qualityStageProcessing.stageProcessing';
    paginationRequest._s = newQuery;
    paginationRequest.sort = 'name,ASC';
    paginationRequest.page = page;
    paginationRequest.size = 20;
    paginationRequest.deleted = false;
    const res: PaginatedResponse = await this.getWithParams(
      `${this.qcUrl}quality/country/`,
      paginationRequest,
      true,
      () => this.authService.renewToken(),
    );
    if (!isNullOrUndefined(res)) {
      res.items = nullsafe(res.items).map(i => this.queryService.fromServer(i));
    }
    return res;
  }

  async loadSampleSearches(query: string, page = 0): Promise<PaginatedResponse> {
    const newQuery = isNotEmpty(query)
      ? `country==${query},sample==${query},sampleReference==${query},quality.name==${query}`
      : '';
    const includeParams: any = {
      _include: 'quality',
      _s: newQuery,
      sort: 'id,desc',
      page: page,
      size: 10,
      deleted: false,
    };
    const res = await this.getWithParams<PaginatedResponse>(`${this.qcUrl}sample/`, includeParams, true, () =>
      this.authService.renewToken(),
    );
    if (!isNullOrUndefined(res)) {
      res.items = nullsafe(res.items).map(i => this.queryService.fromServer(i));
    }
    return res;
  }

  async loadCuppingProcessBySampleId(sampleId: number): Promise<CuppingProcess[]> {
    const res = await this.getWithParams(
      `${this.qcUrl}cupping_process/by-sample/${sampleId}`,
      { _include: '*' },
      true,
      () => this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  async onLoadCuppingProcessBySampleAndStandard(sampleId: number, standardId: number): Promise<CuppingProcess[]> {
    const res = await this.getWithParams(
      `${this.qcUrl}cupping_process/by-sample/${sampleId}/by-standard/${standardId}`,
      {
        _include: '*,cuppingProcesses(cupEvaluator,metricsDefinitionType,standardDefinition)',
      },
      true,
      () => this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  async loadApprovalInternalConditionsBySampleStatusId(
    sampleStatusId: number,
  ): Promise<SampleApprovalInternalCondition[]> {
    const res = await this.get(
      `${this.qcUrl}sample_approval_internal_condition/by-sample-status/${sampleStatusId}`,
      true,
      () => this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  public async sampleSmallTag(sampleId: number, language: string): Promise<Blob> {
    const url = `${this.qcUrl}sample/tag/small/${sampleId}/${this.langQueryUrl}${language || this.getLanguage()}`;
    return this.getWithParams<Blob>(url, null, true, () => this.authService.renewToken(), 'application/pdf', 'blob');
  }

  public async getLasStatusLog(dispatchId: number): Promise<ApprovalStatusLog> {
    if (dispatchId > 0) {
      const url = `${this.qcUrl}dispatch/${dispatchId}/status_log/last`;
      const res = await this.getWithParams<ApprovalStatusLog>(
        url,
        { _include: '*,approvalSession.thirdParty' },
        true,
        () => this.authService.renewToken(),
      );
      return <ApprovalStatusLog>res;
    }
  }

  public async getSampleApprovalInternalLogs(sampleId: number): Promise<SampleApprovalInternalLog[]> {
    if (sampleId > 0) {
      const url = `${this.qcUrl}sample/${sampleId}/sample_approval_internal_log`;
      const res = await this.getWithParams<SampleApprovalInternalLog[]>(url, { _include: '*' }, true, () =>
        this.authService.renewToken(),
      );
      return <SampleApprovalInternalLog[]>res;
    }
  }

  public async getCuppingProcessAverage(ids: number[]): Promise<CuppingProcess> {
    if (ids.length > 0) {
      const url = `${this.qcUrl}cupping_process/average`;
      const res = await this.postWithParams<CuppingProcess>(url, {}, ids, true, () => this.authService.renewToken());
      return <CuppingProcess>res;
    }
  }

  public async getCuppingProcessAverageSession(cuppingSessionId: number): Promise<CuppingSession> {
    const url = `${this.qcUrl}cupping_process/average-by-session/${cuppingSessionId}`;
    const cuppingSession = await this.post(url, undefined, true, () => this.authService.renewToken());
    return cuppingSession as CuppingSession;
  }

  public async getComtrasInstanceStatus(): Promise<ComtrasStatus> {
    const resp = await this.getWithParams<ComtrasStatus>(`${this.qcUrl}/comtras/status`, {}, true, () =>
      this.authService.renewToken(),
    );
    return <ComtrasStatus>resp;
  }

  public loadMasterRuleValidation(): Rule {
    return {
      date: (v: Validation): void => {
        if (isNullOrUndefined(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      ourReference: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      sampleReference: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      sampleType: (v: Validation): void => {
        if (isNullOrUndefined(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      sampleSource: (v: Validation): void => {
        if (!isNullOrUndefined(v.root.sample && v.root.sampleSource))
          if (v.root.sample === v.root.sampleSource.sample) {
            v.error('common.validation.sample_source');
          }
      },
      // CAUTION: This method is PROTECTED and must be not include in any Template
      approvalInternalComment: (v: Validation): void => {
        if (
          !v.ctx &&
          !isNullOrUndefined(v.root.approvalInternalSampleStatus) &&
          v.root.approvalInternalSampleStatus.sampleStatusCode == this.SAMPLE_REJECTED_CODE
        )
          v.error('common.validation.empty_value_not_allowed');
      },
      agencyId: (v: Validation): void => {
        if (!isNumber(v.ctx)) v.error('common.validation.empty_value_not_allowed');
      },
      packageType: (v: Validation): void => {
        if (isNullOrUndefined(v.ctx)) v.error('common.validation.empty_value_not_allowed');
      },
      quantity: (v: Validation): void => {
        if (!isNumber(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
          return;
        }
        if (v.ctx < 10) {
          v.errorWithparams('numberinput.validation.must_be_greater_than', { value: 9 });
        }
      },
    };
  }

  public async applySample(sampleId: number, cuppingProcessId: number): Promise<Sample> {
    const sample = await this.put<Sample>(
      `${this.qcUrl}sample/apply-cupping-process/${sampleId}/${cuppingProcessId}`,
      {},
      true,
      () => this.authService.renewToken(),
    );
    return <Sample>sample;
  }

  async getSampleThirdPartiesBySampleId(sampleId: number, force?: boolean): Promise<SampleThirdPartyType[]> {
    if ((sampleId > 0 && this.currentSampleIdByThirdPartyType !== sampleId) || force) {
      this.currentSampleIdByThirdPartyType = sampleId;
      const params = {
        _include: '*,thirdParty(thirdPartyContacts,thirdPartyAddresses,defaultThirdPartyContact)',
      };
      const res = await this.getWithParams(`${this.qcUrl}sample/${sampleId}/third_party_types`, params, true, () =>
        this.authService.renewToken(),
      );
      return this.queryService.fromServer(res);
    }
    if (sampleId < 0) this.currentSampleIdByThirdPartyType = undefined;
    return Promise.resolve([]);
  }

  async OnGetQualityBySampleId(sampleId: number): Promise<Quality> {
    if (sampleId > 0 && this.currentSampleIdByQuality !== sampleId) {
      this.currentSampleIdByQuality = sampleId;
      const params = {
        _include: 'coffeeCertifications,qualityStageProcessing.stageProcessing',
      };
      const res = await this.getWithParams(`${this.qcUrl}sample/${sampleId}/quality`, params, true, () =>
        this.authService.renewToken(),
      );
      return this.queryService.fromServer(res);
    }
    if (sampleId < 0) this.currentSampleIdByQuality = undefined;
    return Promise.resolve(null);
  }

  onGetCuppingProcessColDef(): any[] {
    return [
      { field: 'id', header: '#' },
      { field: 'session', header: 'common.labels.cupping_session' },
      {
        field: 'cupEvaluator.cupEvaluator',
        header: 'common.labels.cup_evaluator',
      },
      { field: 'metricsDefinitionType.typeName', header: 'common.labels.type' },
      {
        field: 'standardDefinition.name',
        header: 'common.labels.standard_definition',
      },
      { field: 'sampleWeight', header: 'common.labels.sample_weight' },
      { field: 'value', header: 'common.labels.metrics_type_result_score' },
      { field: 'applied', header: 'common.labels.applied', type: 'boolean' },
      { field: 'cuppingDate', header: 'common.labels.date' },
    ];
  }

  getColDefCuppingProcessAvg(): any[] {
    return [
      { field: 'id', header: '#' },
      { field: 'sample.sample', header: 'common.labels.sample' },
      { field: 'value', header: 'common.labels.metrics_type_result_score' },
      { field: 'applied', header: 'common.labels.applied', type: 'boolean' },
      { field: 'cuppingDate', header: 'common.labels.date' },
    ];
  }

  onGetSampleQualityFinalColDef(): any[] {
    return [
      { field: 'quality.name', header: 'common.labels.quality' },
      { field: 'weight', header: 'common.labels.weight' },
      { field: 'valueResult', header: 'common.labels.value_in_percent' },
    ];
  }

  public getContract(comtrasReference: string): Promise<ComtrasContractData> {
    return this.getWithParams(
      `${this.qcUrl}contract/${comtrasReference}`,
      {
        _include:
          '*,sampleThirdPartyTypes(thirdParty,thirdPartyType),qualityStageProcessing(quality(qualityStageProcessing(stageProcessing)),stageProcessing)',
      },
      true,
      () => this.authService.renewToken(),
    );
  }

  onGetMasterColDef(): BaseMasterGridCol[] {
    const gridColDefs: BaseMasterGridCol[] = [];
    const cellClassRules = {
      allBorders: function (): boolean {
        return true;
      },
    };

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getNumberColDef('common.labels.id', 'data.id')
        .colId('id')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 35,
          pinned: true,
        })
        .pattern('1.0')
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getDateColDef('common.labels.date', 'data.date')
        .colId('date')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 70,
          pinned: true,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.system_id', 'data.sample')
        .colId('sample')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 200,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.sample_reference', 'data.sampleReference')
        .colId('sampleReference')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 200,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.our_reference', 'data.ourReference')
        .colId('ourReference')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 150,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.contract_ref', 'data.contractReference')
        .colId('contractReference')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 150,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.sample_source', (params: ValueGetterParams) => {
          const { sampleSource } = params.data;
          return isNullOrUndefined(sampleSource) ? '' : sampleSource.sample;
        })
        .colId('sampleSource.sample')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 150,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getNumberColDef('common.labels.quantity', 'data.quantity')
        .colId('quantity')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 100,
        })
        .pattern('1.0')
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getNumberColDef('common.labels.quantity_bags', 'data.quantityPackage')
        .colId('quantityPackage')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 35,
          maxWidth: 100,
        })
        .pattern('1.0')
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.status', (params: ValueGetterParams) => {
          const sample: Sample = params.data;
          return isNullOrUndefined(sample.approvalInternalSampleStatus)
            ? ''
            : sample.approvalInternalSampleStatus.sampleStatusName;
        })
        .colId('approvalInternalSampleStatus.sampleStatusName')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          maxWidth: 100,
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.package_type', (params: ValueGetterParams) => {
          const { packageType } = params.data;
          return isNullOrUndefined(packageType) ? '' : packageType.name;
        })
        .colId('packageType.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.sample_type', (params: ValueGetterParams) => {
          const { sampleType } = params.data;
          return isNullOrUndefined(sampleType) ? '' : sampleType.sampleType;
        })
        .colId('sampleType.sampleType')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.third_party', (params: ValueGetterParams) => {
          const { thirdParty } = params.data;
          return isNullOrUndefined(thirdParty) ? '' : thirdParty.name;
        })
        .colId('thirdParty.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          headerTooltip: this.translationService.instant('common.labels.third_party'),
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.quality', (params: ValueGetterParams) => {
          const { quality } = params.data;
          return isNullOrUndefined(quality) ? '' : quality.name;
        })
        .colId('quality.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.stage_processing', (params: ValueGetterParams) => {
          const { stageProcessing } = params.data;
          return isNullOrUndefined(stageProcessing) ? '' : stageProcessing.stageProcess;
        })
        .colId('stageProcessing.stageProcess')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.location', 'data.location')
        .colId('location')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.country', (params: ValueGetterParams) => {
          return params.context.onFindCountryById(params.data.countryId);
        })
        .colId('country')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.standard_definition', (params: ValueGetterParams) => {
          return params.data.standardDefinition ? params.data.standardDefinition.name : '';
        })
        .colId('standardDefinition.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.quality_description', 'data.qualityDescription')
        .colId('qualityDescription')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.condition', (params: ValueGetterParams) => {
          return params.data.sampleApprovalInternalCondition ? params.data.sampleApprovalInternalCondition.name : '';
        })
        .colId('sampleApprovalInternalCondition.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      primaryColumn: true,
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.crop_sample', 'data.cropSample')
        .colId('cropSample')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    return gridColDefs;
  }

  async getStoragesByType(storageTypeId: number, storageId: number): Promise<Storage[]> {
    const resp = await this.getWithParams(
      `${this.qcUrl}storage/storageType/${storageTypeId}/${storageId}`,
      { _include: '*' },
      true,
      () => this.authService.renewToken(),
    );
    return this.queryService.fromServer(resp);
  }

  async getStorageTypeById(storageTypeId: number): Promise<StorageType> {
    const resp = await this.getWithParams(`${this.qcUrl}storage_type/${storageTypeId}`, { _include: '*' }, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(resp);
  }

  async getSampleById(sampleId: number, params: PaginationRequestParams): Promise<Sample> {
    const resp = await this.getWithParams(`${this.qcUrl}sample/${sampleId}`, params, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(resp);
  }

  public async getStorageById(storageId: number): Promise<any> {
    const resp = await this.getWithParams(`${this.qcUrl}storage/${storageId}`, { _include: '*' }, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(resp);
  }

  public getMetricDefinitionTypes(): Promise<MetricsDefinitionType[]> {
    return this.queryService.querySearch<MetricsDefinitionType[]>(MetricsDefinitionType);
  }

  public async searchCriteria(
    sampleSearch: SampleSearch,
    paginationRequestParams: PaginationRequestParams,
  ): Promise<PaginatedResponse> {
    if (!isNullOrUndefined(sampleSearch)) {
      sampleSearch = this.queryService.toServer(sampleSearch);
    }
    const data = await this.postWithParams(
      `${this.qcUrl}sample/search-criteria`,
      paginationRequestParams,
      sampleSearch,
      true,
      () => this.authService.renewToken(),
    );
    return <PaginatedResponse>data;
  }
}
