import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

/*
    A wrapper for ag-grid components providing common functionality like
        - message overlays
        - loading indicator
        - row re-selection after changes of row data
    Every grid used in this application should use this wrapper around it.

    TODO features to be included in this:
    - calculation of table height (see allocate component)
    - settings icon and column selection (integrate current customize-table-overlay, see allocate component)
    - help icon with tooltip if necessary for a table (see allocate component)
    - could contain ag-grid component itself as soon as all column defs and event handler are defined in the grid options instead of html template
    - provide common functionality to store and re-store current column state (visibility, width, order, sorting, filters) to/from local storage
 */
@Component({
  selector: 'ncs-grid',
  templateUrl: './grid.component.html',
})
export class GridComponent implements OnChanges {
  @Input() gridOptions: GridOptions;
  @Input() rowDataEmpty!: boolean;

  /* fields needed for custom message overlays */
  private onFilterChangedExternal = undefined;
  public currentMsgKey: string = ''; // Do not change the displayed message if a search criteria is entered
  public columnFilterVisible: boolean = false;
  public isRowDataEmpty: boolean = true;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridOptions) {
      if (changes.gridOptions && changes.gridOptions.currentValue && changes.gridOptions.isFirstChange()) {
        this.onFilterChangedExternal = this.gridOptions.onFilterChanged;
        this.gridOptions.onFilterChanged = (): void => {
          if (this.onFilterChangedExternal) {
            this.onFilterChangedExternal();
          }
          this.cd.markForCheck();
        };
        this.columnFilterVisible = this.gridOptions.floatingFilter;
      }
      if (changes.rowDataEmpty && this.gridOptions) {
        this.gridOptions.onModelUpdated = (): void => {
          this.isRowDataEmpty = !(this.gridOptions?.api?.getDisplayedRowCount() > 0);
        };
      }
    }
  }
}
