import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { filter, takeUntil } from 'rxjs/operators';
import { assign, cloneDeep, isEqual, merge } from 'lodash';
import { MasterdataValidationService } from '../services/masterdata-validation.service';
import { extractEvent } from '../../utils/extract-event';
import {
  fetchDataMaster,
  fetchTranslations,
  resetMasterData,
  saveMasterData,
  setBreadcrumbNavigation,
  setMasterDataPagination,
  setMasterDataReference,
  setMasterDataRetiredMode,
  setMasterDataSaveMode,
  setMasterDataSelectedItem,
  setMasterEditMode,
  setMasterModifyMode,
  setResponsePending,
} from '../../../core/ngrx/actions';
import * as fromRoot from '../../../core/ngrx/reducers';
import { isNotEmpty } from '../../utils/string-utils';
import { isNullOrUndefined, mergeImmutable, ObjectPath, Path } from '../../utils/object-utils';
import { Rule, ValidationResult } from '../services/master-validation';
import { GridViewNameReference } from '../../../shared/grid/views/view-type';
import { DisabledGridViewColumns } from '../../../shared/grid/customize-table-overlay/customize-table-overlay.component';
import { NkgGridFactory } from '../../../shared/grid/services/grid.factory';
import {
  AbstractEntity,
  Country,
  Language,
  PaginationMetadata,
  PaginationRequestParams,
  Permission,
  View,
} from '../../../core/domain/models';
import { BaseMasterGridCol, FormMasterViewMode } from '../models/interfaces';
import { TranslationIsoCode } from '../../../shared/basic-shared-module/models/enums';

export abstract class MasterComponent<T> {
  protected abstract MASTER_ENTITY_MODULE_ID: string;
  public abstract paginationRequestParams: PaginationRequestParams;
  protected serviceUrl: string; // force url request to backend
  protected languageCurrentUser: Language = null;
  protected currentItemId: any;
  protected masterColumns: ObjectPath; // Can be used
  protected include: ObjectPath;
  // paginationRequestParams property response
  protected gridColDefs: BaseMasterGridCol[] = [];
  protected responsePending: boolean = false;

  private readonly _destroying$ = new Subject<void>();

  // Variables for template html
  public currentItem: T;
  public filteredItems: T[] = [];
  public gridViewNameReference: GridViewNameReference;
  public disabledGridViewColumns: DisabledGridViewColumns[] = [];
  public gridOptions: GridOptions;
  public formMasterViewMode: FormMasterViewMode;
  public permission = Permission;
  public navigationIdPath: Path;
  public redirectUrl: string;
  public editMode: boolean = false;
  public sizeToFit: boolean = false;
  public gridColDef: BaseMasterGridCol[] = []; // define to gridColDer
  /** Force ever master view to LIST mode */
  public listModeDefault: boolean = false;
  public countries: Country[];
  /** Enable or disable filtering or sorting to server.
   * When enabled the filtering o sorting only applied to AgGrid with current data.
   * Otherwise, retrieve filtering o sorting to server */
  public isLocalFilter: boolean = false;
  public paginationResponse: PaginationMetadata;
  public currentModified: boolean = false;
  public validationResult: ValidationResult;

  typeFunction: Function; // function of the class

  /** Define a new instance abstraction and actions necessaries on new item initialization */
  protected abstract newCurrentItemInstance(): T;

  /** Define rule validation */
  protected abstract getRuleValidation(): Rule;

  /** Define AgGrid Column Definition */
  protected abstract getColDefs(): BaseMasterGridCol[];

  /** Implement when any master is Big and separate nested entities */
  protected onSaveAllNested(): void {}

  protected afterConfirmCancel?(isCancel: boolean): void {}

  protected afterConfirmSave?(isConfirm: boolean): void {}

  protected constructor(
    protected store: Store<fromRoot.State>,
    protected confirmationService: ConfirmationService,
    protected translateService: TranslateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected validationService: MasterdataValidationService,
    public gf: NkgGridFactory,
  ) {
    this.gridOptions = this.gf.getDefaultGridOptions(this.sizeToFit, false, this);
    this.subscribeToMaster();
  }

  // TODO: Set abstract type and implements on all extends
  protected onSetDisplayUrl(view: View, url?: string): void {
    this.store.dispatch(setBreadcrumbNavigation({ view: view, basePath: url }));
  }

  protected onInitData(): void {
    // Should set pagination as params and better control pagination changes
    this.store.dispatch(setMasterDataReference({ masterNameReference: this.MASTER_ENTITY_MODULE_ID }));
    this.onCheckEventsViaURL();
    this.store.dispatch(setMasterDataPagination({ paginationRequestParams: this.paginationRequestParams }));
    this.loadData();
  }

  private onCheckEventsViaURL(): void {
    this.route.params.subscribe(p => {
      if (p.id != '-' && p.id != '_new' && p.id > 0) {
        this.currentItemId = p.id;
      } else if (p.id != '_new') {
        this.router.navigate([`${this.redirectUrl}/-`], { relativeTo: this.route });
      }
    });
  }

  protected onRemoveSubs(): void {
    // WARNINGS: call on all child components for correct functionality and avoid errors on petitions
    this.store.dispatch(resetMasterData());
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private subscribeToMaster(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getMasterDataItems),
      this.store.select(fromRoot.getMasterCurrentItem),
      this.store.select(fromRoot.getModifyMasterModeActive),
      this.store.select(fromRoot.getEditMasterModeActive),
      this.store.select(fromRoot.getMasterReference),
      this.store.select(fromRoot.getCurrentUser).pipe(filter(user => !isNullOrUndefined(user))),
      this.store.select(fromRoot.getIsResponsePending),
      this.store.select(fromRoot.getListCountries),
      this.store.select(fromRoot.getMasterPaginationRequest).pipe(filter(pag => !isNullOrUndefined(pag))),
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(
        ([
          data,
          currentItem,
          modified,
          isEditable,
          masterModuleId,
          currentUser,
          backResponsePending,
          countries,
          pagination,
        ]) => {
          this.countries = countries;
          this.responsePending = backResponsePending;
          this.paginationResponse = data?.__pagination;
          this.filteredItems = data?.items;
          this.gridOptions?.api?.setRowData(data?.items);
          this.currentItem = currentItem;
          this.currentItemId = this.getCurrentItemId();
          this.currentModified = modified;
          this.editMode = isEditable;
          this.languageCurrentUser = currentUser?.language;
          this.onCheckPaginationChanges(pagination, masterModuleId);
        },
      );
  }

  private onCheckPaginationChanges(newPagination: PaginationRequestParams, masterModuleReference: string): void {
    if (
      !isEqual(this.paginationRequestParams, newPagination) &&
      isEqual(this.MASTER_ENTITY_MODULE_ID, masterModuleReference)
    ) {
      this.paginationRequestParams = newPagination;
      this.loadData();
    }
  }

  protected getCurrentItem(): T {
    return this.currentItem;
  }

  protected onSaveMasterData(newCurrentItem?: any, isoCode?: string): void {
    this.store.dispatch(
      saveMasterData({
        typeFunction: this.typeFunction,
        data: newCurrentItem || this.getCurrentItem(),
        paginationRequestParams: this.paginationRequestParams,
        redirectUrl: this.redirectUrl,
        isoCode: isoCode,
      }),
    );
  }

  protected setLocalFilter(isLocalFilter: boolean): void {
    this.isLocalFilter = isLocalFilter;
  }

  protected getCurrentItemId(): number {
    const itemId = (this.getCurrentItem() as AbstractEntity)?.id;
    return itemId || 0;
  }

  protected setCurrentItem(newItem: T): void {
    this.store.dispatch(setMasterDataSelectedItem({ selectedItem: newItem }));
  }

  // TODO: Refactor to {@Param name} string type to string[] type
  protected setDisabledColumns(name: string, disabled: boolean = true, checked: boolean = true): void {
    this.disabledGridViewColumns.push({
      [name]: { disabled: disabled, checked: checked },
    });
  }

  public rowClick(event: any): void {
    const { data: currentItem } = event;
    const currentClickedItemId = (currentItem as AbstractEntity)?.id;

    if (this.currentModified) {
      this.onCancel();
      return;
    }
    this.router
      .navigate([`../${currentClickedItemId}`], {
        relativeTo: this.route,
      })
      .then(() => {
        this.setCurrentItem(currentItem);
        this.switchEditMasterMode(false);
      });
  }

  public onNew(): void {
    if (!this.currentModified) {
      this.router.navigate([`${this.redirectUrl}/_new`], {
        relativeTo: this.route,
      });
      this.setCurrentItem(this.newCurrentItemInstance());
      this.switchEditMasterMode(true);
    } else {
      this.onCancel();
    }
  }

  public onSave(): ValidationResult {
    const fullValidation = this.validationService.validateItem(this.getCurrentItem(), this.getRuleValidation());
    if (!fullValidation.valid) {
      this.validationResult = fullValidation;
      return;
    }
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('common.buttons.yes'),
      rejectLabel: this.translateService.instant('common.buttons.no'),
      key: 'saveConfirmationDialog',
      message: this.translateService.instant('masterdata.dialog.saveMasterConfirmation.message'),
      accept: () => {
        this.onSaveMasterData();
        this.switchEditMasterMode(false);
        this.switchModifyMasterMode(false);
        this.afterConfirmSave(true);
        this.switchSaveMode(true);
      },
      reject: () => {
        this.switchEditMasterMode(true);
        this.switchModifyMasterMode(true);
        this.afterConfirmSave(false);
      },
    });
  }

  private clearValidationResult(): void {
    this.validationResult = undefined;
  }

  public onCancel(isCanceled: boolean = false): void {
    if (isCanceled) {
      this.setCurrentItem(null);
      this.loadData();
      this.clearValidationResult();
    }
    this.switchEditMasterMode(!isCanceled);
    this.switchModifyMasterMode(!isCanceled);
    this.afterConfirmCancel(isCanceled);
  }

  public onItemFieldChange(event: any, fld?: string): void {
    const upd = {};
    // [QC-39] validate if not empty event
    upd[fld] = event && !Object.keys(event).length ? event : extractEvent(event);
    const newItem: T = mergeImmutable(<any>this.getCurrentItem(), upd);
    this.setCurrentItem(newItem);
    this.switchModifyMasterMode(true);
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(this.validationResult, fld);
    newValidationResult = merge(
      newValidationResult,
      this.validationService.validateItem(this.getCurrentItem(), this.getRuleValidation(), fld),
    );
    this.validationResult = newValidationResult;
  }

  public onItemFieldBlur(fld: Path): void {
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(this.validationResult, fld);
    newValidationResult = merge(
      newValidationResult,
      this.validationService.validateItem(this.getCurrentItem(), this.getRuleValidation(), fld),
    );
    this.validationResult = newValidationResult;
  }

  protected switchSaveMode(isSaved: boolean): void {
    this.store.dispatch(setMasterDataSaveMode({ isSaved: isSaved }));
  }

  public switchEditMasterMode(value: boolean): void {
    this.store.dispatch(setMasterEditMode({ isEditMode: value }));
  }

  protected switchModifyMasterMode(value: boolean): void {
    this.store.dispatch(setMasterModifyMode({ isModifyMode: value }));
  }

  protected changeResponsePending(value: boolean): void {
    this.store.dispatch(setResponsePending({ isResponsePending: value }));
  }

  public onSearchRetiredChanged(event: boolean): void {
    if (!isNullOrUndefined(this.paginationRequestParams)) {
      this.paginationRequestParams.deleted = event;
      this.loadData();
    }
    this.store.dispatch(setMasterDataRetiredMode({ showRetiredItems: event }));
  }

  public onSearchQuickEntered(query: string = ''): void {
    // WARNING: This function clears all grid filters or sorting models in AgGrid.
    // It evaluates whether the current gridView is recovering with the latest AgGrid models.
    const newPagination = cloneDeep(this.paginationRequestParams);
    newPagination.q = query;
    // CAUTION: If it makes master general searches <<q>> then filtering search <<_s>> set to empty and vice-verse
    if (isNotEmpty(query)) {
      newPagination._s = '';
      this.gridOptions.api.setFilterModel([]);
    }

    // Set page to 0 when update the query search
    newPagination.page = 0;
    this.store.dispatch(setMasterDataPagination({ paginationRequestParams: newPagination }));
  }

  public onFilterByGrid(event: { sort: any; filter: any }): void {
    const newPagination = assign(cloneDeep(this.paginationRequestParams), {
      sort: event.sort,
      _s: event.filter,
      q: '',
    });
    this.store.dispatch(setMasterDataPagination({ paginationRequestParams: newPagination }));
  }

  public onBackTo(): void {
    this.loadData();
  }

  public onChangePage(page: number): void {
    // Clone paginationRequestParams for correct logical flow of master data loading data
    const newPaginationRequest = assign(cloneDeep(this.paginationRequestParams), { page: page });
    this.store.dispatch(setMasterDataPagination({ paginationRequestParams: newPaginationRequest }));
  }

  protected loadData(): void {
    this.store.dispatch(
      fetchDataMaster({
        typeFunction: this.typeFunction,
        paginationRequestParams: this.paginationRequestParams,
        serviceUrl: this.serviceUrl,
        include: this.include,
      }),
    );
  }

  private loadTranslations(currentItemId: number): void {
    this.store.dispatch(fetchTranslations({ typeFunction: this.typeFunction, currentId: currentItemId }));
  }

  public onSaveTranslation(translation: TranslationIsoCode): void {
    this.onSaveMasterData(translation.item, translation.isoCode);
  }

  public onClickTranslate(): void {
    this.loadTranslations(this.getCurrentItemId());
  }

  /** To correctly display the country column in AgGrid, search the country name by the country id
   * @param {number} countryId Country id
   * @return {string} Country name */
  public onFindCountryById(countryId: number): string {
    let country: Country;
    if (!isNullOrUndefined(countryId)) country = (this.countries || []).find(x => x.id == countryId);
    return (country && country.country) || '';
  }

  // WARNING: Use by AgGrid filter params context
  public isFilterLocal(): boolean {
    return this.isLocalFilter;
  }
}
