import { isArray as _isArray, isEmpty } from 'lodash';
import { nullsafe } from './object-utils';

/**
 * Checks two arrays for equality. Does consider the ordering. Compares array sampleThirdPartyTypes with ===
 * @param a the first array
 * @param b the other array
 * @returns {boolean} true if the arrays are equal, false otherwise
 */
export function arraysEqual(a, b): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function isArray(_array: any): boolean {
  if (nullsafe(_array).length) {
    return _isArray(_array);
  }
  return false;
}

export function isArrayEmpty(_array: any[]): boolean {
  return isEmpty(_array);
}
