import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { handleLabelClick } from '../../utils/component.util';
import { NcsBaseBasicComponent } from '../base-basic/base-basic.component';
import { hasOwnProperty, isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { AbstractEntity } from '../../../../core/domain/models';

@Component({
  selector: 'ncs-selectbox',
  templateUrl: './ncs-select-box.component.html',
})
export class NcsSelectBoxComponent extends NcsBaseBasicComponent implements OnChanges {
  handleLabelClick = handleLabelClick;

  @Input() options: any[]; // values of the options to chose from
  /* XXX IF the options are selectItems, then value is expected to be a value of one of the selectItems.
     If options and value have matching IDs, the value is set to the option with that id XXX */
  @Input() valueId: any; // {id:number, ref:boolean} the id selected
  @Input() valueExpression: string;
  @Input() isEmptyAllowed: boolean = true; // if the user has the option to clear the input or to set it to 'blank'
  @Input() required = true;
  @Input() equalAttribute: string; // property to uniquely identify a value in options
  @Input() inputId: string = 'setInputFocus'; // forward to primeNg 'inputId': "Identifier of the focus input to match a label defined for the dropdown."
  @Input() focusable: boolean = true; // if the component is focusable, this is especially not the case in fieldsets that are collapsed
  /** If initial value isn't AbstractEntity instance, specify other unique property other than id property.
   * Displaying value selected doesn't work if dataKey is not specified
   */
  @Input() dataKey: string = 'id'; // Options identifier (Unique)
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueId) {
      // Instead of setting a selectItem as value, we set a functional value but want the corresponding selectitem of the options
      if (nullsafe(this.options).length > 0 && this.valueId) {
        // check if we have SelectItems as options
        const v: any = this.options.find(i => this.isEqual(i.id, this.valueId.id)); // set the value to the selectItem
        if (v !== undefined) {
          this.value = v[this.fieldReadOnly];
        }
      }
    }
  }

  onChange(event): void {
    let { value: currentItemsSelected } = <any>event;
    // if has any id so mean this instanceof from AbstractEntity (not working comparison)
    // WARN: retrieve all properties when don't instance of AbstractEntity
    if (nullsafe(this.options).length && hasOwnProperty(this.options[0], 'id') && !isNullOrUndefined(event.value)) {
      currentItemsSelected = this.options.find(x => x.id === event.value.id);
    }

    this.onSelect.emit(currentItemsSelected);
  }

  onBlur(): void {
    /* The Problem: The elements of the input trigger an onBlur because the input is not selected for a short time then anymore.
     * We do not want to blur while nothing is selected in the component but the user clicked on something to select.
     * Therefore we inhibit the onblur event for this case, which is "panel is open, user selects an item (input onBlur)".
     * */
    this.onblur.emit();
  }

  private isEqual(a: any, b: any): boolean {
    if (a === b) return true;
    if (this.equalAttribute && a !== undefined && b !== undefined) {
      if (!hasOwnProperty(a, this.equalAttribute)) {
        throw new Error(
          `Item [${JSON.stringify(a)} to check for equality does not have property '${this.equalAttribute}'`,
        );
      } else if (!hasOwnProperty(b, this.equalAttribute)) {
        throw new Error(
          `Item [${JSON.stringify(b)} to check for equality does not have property '${this.equalAttribute}'`,
        );
      }
      return a[this.equalAttribute] === b[this.equalAttribute];
    }
    if (a instanceof AbstractEntity && b instanceof AbstractEntity) {
      return a.id === b.id;
    }
    return false;
  }
}
