import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { fetchTimezone } from '../../../core/ngrx/actions';
import * as fromRoot from '../../../core/ngrx/reducers';

export const AppTimezoneGuard: CanActivateFn = () => {
  const store = inject(Store<fromRoot.State>);
  return store.pipe(
    select(fromRoot.getTimezone),
    map(timezone => {
      if (!timezone) {
        store.dispatch(fetchTimezone());
        return true;
      }
    }),
    take(1),
  );
};
