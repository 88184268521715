import { Injectable } from '@angular/core';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '../reducers/auth.reducer';
import { getAuthState, State } from '../reducers';
import { User } from '../../domain/models';

@Injectable()
export class AuthSelector {
  private readonly loginFeatureSelector: MemoizedSelector<State, AuthState>;

  public constructor() {
    this.loginFeatureSelector = getAuthState;
  }

  public get currentUser(): MemoizedSelector<object, User> {
    return createSelector(this.loginFeatureSelector, state => state.currentUser);
  }
}
