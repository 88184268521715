import { createAction, props } from '@ngrx/store';
import { CoffeeSpecie, PaginationRequestParams, QualityType } from '../../domain/models';

export enum QualityAction {
  FETCH_QUALITY_TYPE = '[Quality] Request Quality Type',
  FETCH_QUALITY_TYPE_SUCCESS = '[Quality] Request Quality Type Success',
  FETCH_COFFEE_SPECIES = '[Quality] Fetch Coffee Species',
  FETCH_COFFEE_SPECIES_SUCCESS = '[Quality] Fetch Coffee Species Success',
}

export const fetchQualityType = createAction(
  QualityAction.FETCH_QUALITY_TYPE,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchQualityTypeSuccess = createAction(
  QualityAction.FETCH_QUALITY_TYPE_SUCCESS,
  props<{ qualityTypes: QualityType[] }>(),
);

export const fetchCoffeeSpecies = createAction(
  QualityAction.FETCH_COFFEE_SPECIES,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchCoffeeSpeciesSuccess = createAction(
  QualityAction.FETCH_COFFEE_SPECIES_SUCCESS,
  props<{ coffeeSpecies: CoffeeSpecie[] }>(),
);
