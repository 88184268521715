import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { AuthorizationService } from './authorization.service';
import { TimeService } from '../../utils/time-service';
import { Body, ContactInfo, Email, EmailAdministration, EmailFile } from '../../../core/domain/models';
import { QueryService } from '../../services/query.service';
import { mergeImmutable } from '../../utils/object-utils';
import { isNotEmpty } from '../../utils/string-utils';
import { Constants } from '../../../core/utils/app.constants';

@Injectable()
export class EmailService extends AbstractCeisService {
  private readonly actionUrl: string;

  constructor(
    httpClient: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
    private queryService: QueryService,
  ) {
    super(httpClient, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}email/`;
  }

  public async sendEmail(emailAdministration: EmailAdministration, files: EmailFile[]): Promise<any> {
    const formData: FormData = new FormData();
    formData.append(
      'metadata',
      new Blob([JSON.stringify(this.formatEmailAdministrationToEmail(emailAdministration))], {
        type: 'application/json',
      }),
    );

    (files || []).forEach(file => {
      const fileBlob = file.blob;
      const ext = (fileBlob.type || '').split('/'); // Example: ('application/pdf').split('/') ===> ['application']['pdf']
      const fileName = `${file.name}.${ext[1]}`;
      formData.append('attachments', fileBlob, fileName.replace(/\/|\s/g, '_'));
    });
    return this.postWithParams(
      `${this.actionUrl}send/`,
      {},
      formData,
      true,
      () => this.authService.renewToken(),
      'multipart/form-data',
    );
  }

  public formatEmailAdministrationToEmail(emailAdministration: EmailAdministration): Email {
    const email = mergeImmutable(new Email(), {
      subject: emailAdministration.subject,
      to: this.splitEmails(emailAdministration.sendTo),
      bcc: this.splitEmails(emailAdministration.bcc),
      cc: this.splitEmails(emailAdministration.cc),
    });

    email.body = mergeImmutable(new Body(), {
      content: emailAdministration.body,
      // set property when force html format
      html: true,
    });
    return this.queryService.toServer(email);
  }

  splitEmails(emails: string): ContactInfo[] {
    if (isNotEmpty(emails)) {
      // clean whitespace and split by ;
      return emails
        .replace(/\s/g, '')
        .split(';')
        .map(x => mergeImmutable(new ContactInfo(), { email: x }));
    }

    return [];
  }
}
