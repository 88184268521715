import { createAction, props } from '@ngrx/store';
import { AddressType, ContactType, PaginationRequestParams, ThirdParty, ThirdPartyType } from '../../domain/models';

export enum ThirdPartyAction {
  FETCH_CONTACT_TYPE = '[Third Party] Fetch Third Party Contact',
  FETCH_CONTACT_TYPE_SUCCESS = '[Third Party] Fetch Third Party Contact Success',
  FETCH_ADDRESS_TYPE = '[Third Party] Fetch Third Party Address',
  FETCH_ADDRESS_TYPE_SUCCESS = '[Third Party] Fetch Third Party Address Success',
  FETCH_THIRD_PARTY_TYPE = '[Third Party] Fetch Third Party Type',
  FETCH_THIRD_PARTY_TYPE_SUCCESS = '[Third Party] Fetch Third Party Type Success',
  FETCH_THIRD_PARTY = '[Third Party] Fetch Third Party',
  FETCH_THIRD_PARTY_SUCCESS = '[Third Party] Fetch Third Party Success',
}

export const fetchContactType = createAction(ThirdPartyAction.FETCH_CONTACT_TYPE);

export const fetchContactTypeSuccess = createAction(
  ThirdPartyAction.FETCH_CONTACT_TYPE_SUCCESS,
  props<{ contactTypes: ContactType[] }>(),
);

export const fetchAddressType = createAction(ThirdPartyAction.FETCH_ADDRESS_TYPE);

export const fetchAddressTypeSuccess = createAction(
  ThirdPartyAction.FETCH_ADDRESS_TYPE_SUCCESS,
  props<{ addressTypes: AddressType[] }>(),
);

export const fetchThirdPartyType = createAction(
  ThirdPartyAction.FETCH_THIRD_PARTY_TYPE,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchThirdPartyTypeSuccess = createAction(
  ThirdPartyAction.FETCH_THIRD_PARTY_TYPE_SUCCESS,
  props<{ thirdPartyType: ThirdPartyType[] }>(),
);

export const fetchThirdParty = createAction(
  ThirdPartyAction.FETCH_THIRD_PARTY,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchThirdPartySuccess = createAction(
  ThirdPartyAction.FETCH_THIRD_PARTY_SUCCESS,
  props<{ thirdParty: ThirdParty[] }>(),
);
