import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { unitOfTime } from 'moment';
import { Constants } from '../../../core/utils/app.constants';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

export function toTimezone(date: Date, timezone: string): Date {
  return moment.tz(date, timezone).toDate();
}

export function removeTimepart(date: Date, timezone: string): Date {
  return moment.tz(date, timezone).startOf('day').toDate();
}

// all starting with 1, not 0!
export function createDate(year: number, month: number, day: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .date(day)
    .startOf('day')
    .toDate();
}

// all starting with 1, not 0!
export function createEndOfMonthDate(year: number, month: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .endOf('month')
    .startOf('day')
    .toDate();
}

// all starting with 1, not 0!
export function createStartOfMonthDate(year: number, month: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .startOf('month')
    .startOf('day')
    .toDate();
}

export function toJavaScriptDateIgnoringTimezone(date: Date, timezone: string): Date {
  const m = moment(date).tz(timezone);
  if (isNullOrUndefined(m)) {
    return m.toDate();
  }
  return new Date(m.year(), m.month(), m.date());
}

export function createStartOfMonth(dt: Date, timezone: string): Date {
  return moment.tz(dt, timezone).startOf('month').startOf('day').toDate();
}

export function createEndOfMonth(dt: Date, timezone: string): Date {
  return moment.tz(dt, timezone).endOf('month').startOf('day').toDate();
}

export function isStartOfMonth(dt: Date, timezone: string): boolean {
  return dt.getTime() === createStartOfMonth(dt, timezone).getTime();
}

export function isEndOfMonth(dt: Date, timezone: string): boolean {
  return dt.getTime() === createEndOfMonth(dt, timezone).getTime();
}

export function ncsFormatDateShort(value: Date, lang: string, timezone: string): string {
  if (isNullOrUndefined(value)) return '';
  const ngPipe = new DatePipe(lang);
  return value ? ngPipe.transform(value, Constants.getDateFormatShort(), timezone) : '';
}

export function ncsFormatDateTimeShort(value: Date, lang: string, timezone: string): string {
  const ngPipe = new DatePipe(lang);
  const displayFormat: string = Constants.getNcsFormatDateTimeShort();
  return value ? ngPipe.transform(value, displayFormat, timezone) : '';
}

export function convertDate(dt: string | number | Date): any {
  if (isNullOrUndefined(dt)) {
    return dt;
  }
  if (typeof dt === 'string') {
    /* Convert ISO 8601 formatted string into Date */
    return moment(dt).toDate();
  }
  if (typeof dt === 'number') {
    /* Convert UTC/epoch timestamp into Date */
    return new Date(dt);
  }
  if (dt instanceof Date) {
    /* Convert Date into ISO 8601 formatted string */
    return dt.toISOString();
  }
  /* Other types are unsupported */
  throw new Error('Expected string, number or Date.');
}

export function add(a: Date, amount: number, unit: unitOfTime.DurationConstructor, timezone: string): Date {
  return moment.tz(a, timezone).add(amount, unit).toDate();
}

export function addMonths(a: Date, amount: number, timezone: string): Date {
  return moment.tz(a, timezone).add(amount, 'months').toDate();
}

export function dateDiffInMonth(a: Date, b: Date, timezone: string): number {
  if (a && b) {
    return moment.tz(a, timezone).diff(b, 'months');
  }
  return 0;
}

export function dateDiffInDays(a: Date, b: Date, timezone: string): number {
  if (a && b) {
    return moment.tz(a, timezone).diff(b, 'days');
  }
  return 0;
}

/**
 * Substract a month of a date.
 * Does take care of correct month, BUT NOT THE DAY ATM, e.g. 30.February
 * @param {Date} date
 * @returns {Date}
 */
export function substractMonth(date: Date, timezone: string): Date {
  if (!date) return undefined;
  return moment.tz(date, timezone).subtract(1, 'months').toDate();
}

/* Return a period represented as:
 * a) startDate - endDate
 * or if the period covers a whole month and 'abbreviateWholeMonths=true' the abbreviated form
 * b) e.g. 10.2017 instead of 01.10.2017 - 31.10-2017
 * */
export function getPeriod(
  start: Date,
  end: Date,
  language: string,
  timezone: string,
  pattern: string = 'shortDate',
  abbreviateWholeMonths: boolean = false,
): string {
  if (!start && !end) {
    return '';
  }

  const ngPipe = new DatePipe(language);

  // check if we have a whole month
  if (
    abbreviateWholeMonths &&
    start &&
    end &&
    moment.tz(start, timezone).startOf('month').day() == moment.tz(start, timezone).day() &&
    moment.tz(end, timezone).endOf('month').day() == moment.tz(end, timezone).day() &&
    moment.tz(start, timezone).get('month') === moment.tz(end, timezone).get('month')
  ) {
    const abbrev = ngPipe.transform(start, 'MMM yyyy');
    return abbrev;
  }

  // return period as we do not have a whole month
  let formatedStart = ' ';
  if (start) {
    formatedStart = ngPipe.transform(start, pattern);
  }
  let formatedEnd = ' ';
  if (end) {
    formatedEnd = ngPipe.transform(end, pattern);
  }
  return `${formatedStart} - ${formatedEnd}`;
}

export function isAtLeastSameMonth(date1: Date, date2: Date, timezone: string): boolean {
  if (date1 && date2) {
    const moment1: Moment = moment.tz(date1, timezone);
    const moment2: Moment = moment.tz(date2, timezone);
    return moment1.year() > moment2.year() || (moment1.year() == moment2.year() && moment1.month() >= moment2.month());
  }

  return false;
}
