import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../../authorization/services/authorization.service';
import { TimeService } from '../../utils/time-service';
import { QueryService } from '../../services/query.service';
import { isNullOrUndefined, nullsafe, toPathSpec } from '../../utils/object-utils';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { Constants } from '../../../core/utils/app.constants';
import { Permission, User } from '../../../core/domain/models';

@Injectable()
export class UserProfileService extends AbstractCeisService {
  protected webStorage: Storage;
  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
    private queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.webStorage = window.localStorage;
    this.actionUrl = configuration.SERVER_WITH_AUTH_API_URL;
  }

  public async loadUser(username?: string): Promise<User> {
    const res = await this.get(
      this.actionUrl + (username ? `user/username/${username}` : 'user/currentuser'),
      true,
      () => this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  public async getUserById(userId: number): Promise<User> {
    const res = await this.get(`${this.actionUrl}user/${userId}`, true, () => this.authService.renewToken());
    return this.queryService.fromServer(res);
  }

  public async saveUserProfile(toUserProfile: User, resolve: any): Promise<User> {
    toUserProfile = this.queryService.toServer(toUserProfile);
    const currentUsername = this.authService.getLoggedOnUser();
    const newUserProfile = await this.putWithParams<User>(
      `${this.actionUrl}user/${currentUsername}`,
      { _include: toPathSpec(resolve) },
      toUserProfile,
      true,
      () => this.authService.renewToken(),
    );
    this.webStorage.setItem(this.configuration.UI_LANGUAGE, newUserProfile?.language?.language);
    return newUserProfile;
  }

  public async saveUser(user: User, resolve: any): Promise<User> {
    user = this.queryService.toServer(user);
    const basePromise =
      user.id >= 0
        ? await this.putWithParams(`${this.actionUrl}user/`, { _include: toPathSpec(resolve) }, user, true, () =>
            this.authService.renewToken(),
          )
        : await this.postWithParams(`${this.actionUrl}user/`, { _include: toPathSpec(resolve) }, user, true, () =>
            this.authService.renewToken(),
          );
    const res = await basePromise;
    return this.queryService.fromServer(res);
  }

  public loadUsersByTenant(tenantName: string, withPermissions?: Permission[]): Promise<User[]> {
    return this.getWithParams(
      `${this.actionUrl}/user/`,
      {
        tenant: tenantName,
        permissions: withPermissions,
      },
      true,
      () => this.authService.renewToken(),
    ).then((res: any[]) => {
      return nullsafe(res);
    });
  }

  public searchUsers(name: string, withPermissions?: Permission[]): Promise<User[]> {
    return this.getWithParams(
      `${this.actionUrl}/users/_search`,
      {
        q: name,
        withPermissions: withPermissions,
      },
      true,
      () => this.authService.renewToken(),
    ).then((res: any[]) => {
      return nullsafe(res).map(u => this.queryService.fromServer(u));
    });
  }

  public async getUserByEmail(email: string): Promise<User> {
    if (!isNullOrUndefined(email) && email != '') {
      const resp = await this.get(`${this.actionUrl}/user/email/?email=${email}`, true, () => this.renewToken());
      return this.queryService.fromServer(resp);
    }
  }

  public async fetchDirectoryUsers(): Promise<any[]> {
    await Promise.resolve();
    return [
      {
        displayName: 'NCS - Alfredo Hernandez',
        name: 'Alfredo',
        jobTitle: 'Programador',
        mail: 'alfredo.hernandez@nkg.coffee',
        preferredLanguage: 'es-MX',
        surname: 'Hernandez',
        username: 'alfredo.hernandez@nkg.coffee',
        ad_id: '97f5f6ac-25ac-48a1-bcd7-569d094b4c52',
        phone: null,
        department: 'IT',
        employeeId: 'jasdhfa+4564as56dfasdf',
        givenName: 'NCS - Alfredo Hernandez',
        userPrincipalName: 'alfredo.hernandez@nkg.coffee',
        mobilePhone: '9221843651',
      },
    ];
  }
}
