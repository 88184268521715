import { Component, Input } from '@angular/core';
import { NcsBaseBasicComponent } from '../base-basic/base-basic.component';
import { MonthCompletion } from '../date-input/date-input.component';
import { Constants } from '../../../../core/utils/app.constants';

/**
 * Base field input date that must use of the already created ncsi-date-input and must to handle security for this
 * component....
 * It must to extend on base-field-component ...
 *
 *  TO DO:
 *    - Handle security  *** Roles and privileges are not defined yet
 *    - Multilingual label *** implemented...
 *    - Help link (?) not defined yet
 *    - multilingual tooltip -- *** is already implemented on ceis-date-input
 *
 * The security feature for this component is not implemented yet, that's because roles and permissions are not defined
 * and, therefore, the base field component is not created yet.
 */

@Component({
  selector: 'ncs-date-basic',
  templateUrl: './date-basic.component.html',
})
export class DateBasicComponent extends NcsBaseBasicComponent {
  @Input() title: string;
  @Input() ngStyleLabel: any = { width: '100%' };
  @Input() showIcon: boolean = true;
  @Input() showTime: boolean = false;
  @Input() inline: boolean = false;
  @Input() monthCompletion: MonthCompletion = 'none';
  @Input() minDate: Date;
  @Input() focusable: boolean = true;
  @Input() maxDate: Date;
  @Input() tooltipDate: string;
  @Input() inputStyleClass: string = '';
  @Input() formatType: string = 'date';
  @Input() touchUI: boolean = false;
  @Input() dateFormat: string = Constants.getDateFormat();
  @Input() dateFormatDisplay: string = Constants.getDateFormatShort();
}
