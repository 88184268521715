import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PrimeIcons, SelectItem } from 'primeng/api';
import { PaginationMetadata } from '../../../../core/domain/models';
import { isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { NcsBaseBasicComponent } from '../../../basic-shared-module/components/base-basic/base-basic.component';
import { ColumnsType, GridSelectionMode } from '../../../models/enums';
import { ColDefMap, LookupParams } from '../../models/enums';

@Component({
  selector: 'ncs-lookupSearch-basic',
  templateUrl: './lookup-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * [colDefArray]="colDefArray" [btnMagnifier]="true" [editable]="editMode" [fieldDisplay]="'abbreviation'" [suggestions]="tenantSuggestion" [value]="selectedDefaultTenant"
 * (onCompleteMethod)="tenantFiltered($event)" (onblur)="onItemFieldChange($event, 'defaultTenant')"
 * To displayed grid it's necessary colDefArray, btnMagnifier(true), fieldDisplay and onCompleteMethod (for suggestions).
 * colDefArray is data fields properties of a data object, and it's necessary to define keyCode isoCode. For example,
 * colDefArray['name','abbreviation'], so this keyCode should be as this: common.labels.name, common.labels.abbreviation
 * The fieldDisplay is property to displayed of all data content objects. The suggestion is SelectedItem type
 * the markForCheck sometimes not working and not update the autocomplete input content. Should be used from a root component
 *
 */
export class NcslookupSearch extends NcsBaseBasicComponent implements OnChanges {
  /** onCompleteMethod Output is used for getting suggestions for p-autocomplete component of primeNg
     and should be defined it and apply appropriate logic */
  @Output() onCompleteMethod: EventEmitter<LookupParams> = new EventEmitter();
  /** getting input filter value and used for new searches on server */
  @Output() onSetQuerySearch: EventEmitter<LookupParams> = new EventEmitter();
  /**  Activate grid displayed. For display grid is necessary to define array col properties */
  @Input() btnMagnifier: boolean = false;
  /** Define an autocomplete selection type. False value means single selection */
  @Input() multiple: boolean = false;
  @Input() fieldReadOnly: string = 'label'; // should ever be label
  /** Array values supply for onCompleteMethod.Should be defined for a root component */
  @Input() suggestions: SelectItem[];
  /** Setting title property of grid(NkgGenericGrid) */
  @Input() titleGrid: string;
  /** Setting NkgGenericGrid input value */
  @Input() targetValue: string = '';
  @Input() gridColumnType: ColumnsType = ColumnsType.COL_DEF;
  /** Renders columns of a text type only. Consider that the elements must be set to I18 for the translations themselves. */
  @Input() colDefArray: string[];
  /** Define columns for AgGrid */
  @Input() colDefinition: ColDefMap[];
  /** Replace button search icon by label name */
  @Input() searchNameBtn: string;
  /** Allows displaying only the grid details */
  @Input() onlyGrid: boolean = false;
  /** Parameters for REST petitions to backend */
  @Input() paginationResponse: PaginationMetadata;
  /** Allows dropdown features on select input */
  @Input() enableDropDown: boolean = false; // Used for display all data options
  /** Enable or Disable clear button for remove any selected item */
  @Input() showClearBtn: boolean = false;

  @Input() btnIcon = PrimeIcons.SEARCH;

  @Output() onChangePagination: EventEmitter<number> = new EventEmitter(); // when interacting with paginationRequestParams emit us values with this Output
  @Output() onSelected: EventEmitter<any> = new EventEmitter(); // get Selections rows of grid or auto-complete
  @Output() onRemoved: EventEmitter<any> = new EventEmitter(); // get current Selections rows of grid or get removed items from auto-complete
  @Output() onClickedButton: EventEmitter<any> = new EventEmitter();
  // properties used for local logic
  displayGrid: boolean = false;
  internalSelection: SelectItem[];
  lastInternalSelection: SelectItem[];
  lastTargetValue: string = '';
  wasSelected: boolean = false;
  pagination: PaginationMetadata;
  fields: any;
  gridSelectionMode = GridSelectionMode;
  placeholder: string;

  // TODO: consider remove parent component. Don't used
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      // WARN: Master case: when select any item, this value preserve (value) need it setting to undefined
      if (isNullOrUndefined(this.value)) {
        this.internalSelection = this.value = undefined;
        return;
      }
      if (this.value) {
        // set to undefined when value is changed
        this.suggestions = [];
        // single select mode, value should not be object arrays
        const internal = [].concat(this.value);
        // Line commented below is functional for convert object arrays to object
        // Handle internalSelection as Object and not as object array.
        // Change template and onBlur
        // internal = internal.map((model) => this.modelToSelectItem(model));
        this.internalSelection = internal.map(model => ({
          label: model[this.fieldReadOnly],
          value: model,
        }));
      }
      if (this.internalSelection != this.lastInternalSelection && this.btnMagnifier) {
        this.targetValue = '';
      }
      // WARN: Force detection changes on multi-option
      if (this.multiple)
        setTimeout(() => {
          this.cd.markForCheck();
        });
    }
  }

  /**
   * fire up when the value of p-autocomplete is changed
   * @param event
   */
  public onChangeModel(event: any): void {
    let extractValue: any;
    if (this.multiple && event) {
      this.internalSelection = event;
      extractValue = (event || []).map((evt: SelectItem) => evt.value);
      this.wasSelected = false;
    } else if (event) {
      this.internalSelection = [event];
      extractValue = event.value;
    }
    this.onChanges(extractValue);
  }

  // for multi and single selection features, the validation it's complicated
  // so, correct validations are doing it through wasSelected variable
  // CAUTION: to validate output event on component root emitter through onSelected
  public onBlurEvent(event: any): void {
    // CAUTION: event target work only for single selection
    if (!this.multiple && this.wasSelected) {
      const { value } = event.target;
      if (value && this.internalSelection && this.internalSelection.length > 0) {
        const expectedValue = this.internalSelection ? this.internalSelection[0].label : '';
        // go back to the last set when we don't match selection
        if (value !== expectedValue) {
          this.internalSelection = undefined;
          this.onSelected.emit(undefined);
          this.wasSelected = false;
          event.target.value = ''; // clear invalid selection
        }
      } else {
        // kill existing selection for empty
        this.internalSelection = undefined;
        this.onSelected.emit(undefined);
      }
      this.wasSelected = false;
    }
    this.onBlur(event);
  }

  /** Emit unSelection item (multi-selection only) */
  public onUnSelection(event: any): void {
    this.onRemoved.emit(event.value);
  }

  /**
   * Emmit event when any item from p-autocomplete is selected
   * @param event
   */
  public onSelectionEvent(event: any): void {
    this.wasSelected = true;
    // this event is emitted when any item is selected.
    // selected validation work for single select and not work for multiple selections
    // for multiple selection use internalSelection
    const itemSelection = this.multiple ? (this.internalSelection || []).map(item => item.value) : event.value;
    // validate valid property from onSelected event into a final component
    this.onSelected.emit(itemSelection);
  }

  /**
   * get selections from lookup's grid and setting internal selection value of p-autocomplete
   * @param event
   */
  public getResultSelections(event: any): void {
    if (!this.multiple) {
      this.internalSelection = [].concat({
        value: event,
        label: event[this.fieldReadOnly],
      });
    } else {
      this.internalSelection = <SelectItem[]>event.map((row: any) => {
        return { value: row, label: row[this.fieldReadOnly] };
      });
    }

    this.onSelected.emit(event);
    this.wasSelected = true;
    // force valid selection when any row is selected
    this.onBlurEvent({ target: { value: this.internalSelection[0].label } });
  }

  /**
   * emit the page changes from grid
   * the paginationRequestParams component is into NkgGenericGrid(by agGrid)
   * @param {LookupParams} query
   */
  public onSearch(query: LookupParams): void {
    this.lastTargetValue = query && query.query;
    const { query: queryParams, page } = query;
    this.onCompleteMethod.emit({ query: queryParams, page: page || 0 });
  }

  // stop keyboard ESCAPE event everywhere
  // into masters data, keyboard escape event cause exit edit mode
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.displayGrid) {
      event.stopPropagation();
    }
  }

  /**
   * Clean event and retrieve value removed/cleaned
   * Only working with single select mode
   */
  public onClearSelection(): void {
    if (!this.multiple && nullsafe(this.internalSelection).length) {
      this.onRemoved.emit(this.internalSelection[0].value);
    }
  }

  public clickedButton(event: any): void {
    this.displayGrid = true;
    this.onClickedButton.emit(event);
  }
}
