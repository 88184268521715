import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { handleLabelClick } from '../basic-shared-module/utils/component.util';
import { hasOwnProperty, isNullOrUndefined, nullsafe } from '../../modules/utils/object-utils';
import { NcsBaseBasicComponent } from '../basic-shared-module/components/base-basic/base-basic.component';

/**
 * Ncs Select Button implementation. Wraps the prime ng select button component.
 */
@Component({
  selector: 'ncs-select-button',
  templateUrl: './select-button.component.html',
  styles: [
    `
      p-selectButton {
        margin-left: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcsSelectButtonComponent extends NcsBaseBasicComponent implements OnChanges {
  // the selectable options
  @Input() options: SelectItem[];
  // additional styles
  @Input() labelStyle: any;
  // additional style classes
  // selection type
  @Input() multiple: boolean = false;
  // emitted when the selection changes, contains the selected sampleThirdPartyTypes value

  selection: any;

  handleLabelClick = handleLabelClick;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.firstChange) {
      /**
       * make sure that the intial value of this component is visible from the start.
       *
       * That is currently not the case in some screens where this component is used, which is probably
       * a bug about the manual triggering of change detection in that screens. But fixing it here, is
       * the fastest and simplest approach to make it work.
       */
      setTimeout(() => {
        this.cd.markForCheck();
      }, 0);
    }

    if (changes.value || changes.options) {
      /**
       * match currently selected value to the matching value from the options list by
       *  - id comparison in case of AbstractEntities
       *  - simple === comparison in all other cases
       *
       * necessary to ensure that a selected entity is considered to be selected even though the
       * selected value and the selectable options are loaded via different backend calls (which
       * results in being different object instances).
       */
      const matchingSelectItem = !isNullOrUndefined(this.value)
        ? nullsafe(this.options).find((o: SelectItem) => {
            if (
              hasOwnProperty(o.value, '__type') ||
              hasOwnProperty(Object.getPrototypeOf(o.value), '__type' /* when retrieve data with pagination params */)
            ) {
              return o.value.id === this.value.id;
            }
            return o.value === this.value;
          })
        : undefined;
      this.selection = matchingSelectItem ? matchingSelectItem.value : undefined;
    }
  }
}
