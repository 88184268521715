import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { AuthorizationService } from '../modules/authorization/services/authorization.service';
import { TimeService } from '../modules/utils/time-service';
import { UserProfileService } from '../modules/user-profile/services/user-profile.service';
import { FileUploadService } from '../shared/upload/file-upload.service';
import { QueryService } from '../modules/services/query.service';
import { AppTopbarComponent } from './components/top-bar/app.topbar.component';
import { GridModule } from '../shared/grid/grid.module';
import { BackendPendingInterceptor } from './service/backend-pending.interceptor';

import { AppMetaDataService } from '../modules/authorization/services/app-meta-data.service';
import { VersionInfoDialogComponent } from './components/version-info-dialog/version-info-dialog.component';

import { ViewCustomizationOverlayService } from '../shared/grid/services/customize-table-overlay.service';
import { NgrxModule } from './ngrx/ngrx-manage.module';
import { RoleService } from '../modules/authorization/services/role.service';
import { EmailService } from '../modules/authorization/services/email.service';
import { SharedBasicModule } from '../shared/basic-shared-module/shared-basic.module';
import { CuppingProcessService } from '../modules/master/modules/cupping-session/modules/sample-analysis/modules/cupping-process/services/cupping-process.service';
import { AppSubMenuComponent } from './components/menu/app-menu.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { Constants } from './utils/app.constants';
import { LogService } from './service/log.service';
import { TenantService } from '../modules/authorization/services/tenant.service';
import { NotificationService } from '../modules/services/notification.service';

/**
 * This module defines common infrastructure for the application like the flux store and action creators, backend-services and
 * common components always visible in the application like the topbar and menu.
 *
 * This module is responsible to load other modules "forRoot" and to provide global application specific services, so that
 * necessary global configurations and providers are registered only once in the top-level injector of this application.
 *
 * Because of this, this module MAY ONLY be imported into the AppModule!
 */
@NgModule({
  imports: [
    AgGridModule.withComponents([]),
    GridModule.forRoot(),
    HttpClientModule,
    NgrxModule,
    NgOptimizedImage,
    SharedBasicModule,
    DialogModule,
    TranslateModule,
    TooltipModule,
    MenuModule,
    ToastModule,
    ButtonModule,
    NgClass,
    DatePipe,
    NgIf,
    NgForOf,
  ],
  declarations: [
    // declares all top-level components like top bar, menu, ...
    AppSubMenuComponent,
    AppTopbarComponent,
    NotificationPanelComponent,
    VersionInfoDialogComponent,
  ],
  exports: [AppSubMenuComponent, AppTopbarComponent, NotificationPanelComponent, VersionInfoDialogComponent],
  providers: [
    // provides all singleton services, etc. that we only want to be initialized once on top level
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: 'wndw', useValue: window },
    Constants,
    // singleton services used in this app
    LogService,
    AuthorizationService,
    ConfirmationService,
    TimeService,
    UserProfileService,
    TenantService,
    RoleService,
    AppMetaDataService,
    FileUploadService,
    NotificationService,
    MessageService,
    QueryService,
    CuppingProcessService,
    ViewCustomizationOverlayService,
    EmailService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendPendingInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
