<ncs-table-crud
  [cols]="tableTranslateColumns"
  [items]="languageTranslations"
  [editMode]="editable"
  [addEnable]="false"
  [editEnable]="editable"
  [removeEnable]="false"
  [showOnModal]="false"
  [selectedItem]="currentChildItem"
  [dialogFormStyle]="{ 'max-width': '400px' }"
  (onRowClick)="onSetChildItem($event)"
  (onSave)="onSave()"
  (onCancel)="onCancel()">
  <div dialog-form="form">
    <div class="col-12">
      <ncs-text-basic
        [maxCharacterLength]="100"
        [label]="'common.labels.translation'"
        [editable]="editable"
        [value]="currentChildItem?.translation || ''"
        [validationResult]="validationResult?.fieldResults?.translation"
        (onchange)="onItemFieldChange($event, 'translation')"
        (onblur)="onItemFieldBlur('translation')">
      </ncs-text-basic>
    </div>
  </div>
</ncs-table-crud>
