<ng-container *ngIf="label; then onlyButton; else buttonIcon"></ng-container>
<ng-template #onlyButton>
  <button
    [attr.tabindex]="tabindex"
    [ngClass]="inputClass"
    [label]="label | translate"
    [disabled]="disabled"
    pButton
    type="button"
    [icon]="icon"
    (click)="onClick($event)"
    [ngStyle]="inputStyle">
    <ng-content></ng-content>
    <ng-content select="[content-force]"></ng-content>
  </button>
</ng-template>
<ng-template #buttonIcon>
  <button
    pButton
    type="button"
    class="btn-icon-only"
    [disabled]="disabled"
    [ngClass]="inputClass"
    pTooltip="{{ label | translate }}"
    tooltipPosition="top"
    [icon]="icon"
    [ngStyle]="inputStyle"
    (click)="onClick($event)">
    <ng-content></ng-content>
    <ng-content select="[content-force]"></ng-content>
  </button>
</ng-template>
