import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from '../../core/service/app.abstract.service';
import { Constants } from '../../core/utils/app.constants';
import { FileInfo } from '../../core/domain/models';
import { AuthorizationService } from '../../modules/authorization/services/authorization.service';
import { TimeService } from '../../modules/utils/time-service';

export interface FileUploadStatus {
  progress: number;
  success: boolean;
  fileInfo: FileInfo; // with success - see file-info.ts
}

@Injectable()
export class FileUploadService extends AbstractCeisService {
  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_QC_API_URL}files`;
  }

  public async tokenForFile(id: any): Promise<string> {
    const res = await this.getWithParams<string>(
      `${this.actionUrl}/token/${id}`,
      {},
      true,
      () => this.authService.renewToken(),
      'text/plain',
      'text',
    );
    return <string>res;
  }

  public async uploadFile(bytes: any, mimeType: string, name: string, type: string, fileKey?: any): Promise<any> {
    // TODO: actually intercept upload status (easy w/ angular 4.3)
    const res = await this.postWithParams(
      this.actionUrl,
      {
        name,
        type,
        fileKey,
      },
      bytes,
      true,
      () => this.authService.renewToken(),
      mimeType,
    );
    const fi: FileInfo = res;
    return {
      progress: 1,
      success: true,
      fileInfo: { ...fi, createdAt: typeof fi.createdAt == 'number' ? new Date(fi.createdAt) : fi.createdAt },
    };
  }
}
