import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { Constants } from '../../../core/utils/app.constants';
import { AuthorizationService } from './authorization.service';
import { TimeService } from '../../utils/time-service';
import { PaginatedResponse, PaginationRequestParams, Permissions, Role } from '../../../core/domain/models';
import { nullsafe } from '../../utils/object-utils';

@Injectable()
export class RoleService extends AbstractCeisService {
  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = configuration.SERVER_WITH_AUTH_API_URL;
  }

  public async loadRoles(): Promise<Role[]> {
    const res: Role[] = await this.getWithParams(
      `${this.actionUrl}administration/roles`,
      { deleted: false },
      true,
      () => this.authService.renewToken(),
    );
    return nullsafe(res);
  }

  public async loadPermissions(): Promise<Permissions[]> {
    const paginationFinal = new PaginationRequestParams();
    paginationFinal.size = 200;
    const res = await this.getWithParams<PaginatedResponse>(`${this.actionUrl}permissions`, paginationFinal, true, () =>
      this.authService.renewToken(),
    );
    return nullsafe(res.items) as Permissions[];
  }
}
