import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldValidationResult } from '../../../../modules/master/services/master-validation';
import { DisabledType } from '../../../../core/domain/models';

@Component({
  selector: 'ncs-checkbox',
  templateUrl: './ncs-checkbox.component.html',
})
export class NcsCheckboxComponent {
  @Input() value: boolean = false;
  @Input() label: string = '';
  @Input() labelIsOnTop: boolean = true; // show label on top or behind?
  @Input() validationResult: FieldValidationResult;
  @Input() disabledType: DisabledType = DisabledType.NO;
  @Input() tabindex: number = 0;
  @Input() allowMultiselect: boolean = false;
  @Input() readOnly: boolean = false;

  @Output() onCheckedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly disabledTypeEnum = DisabledType;

  // Event emit {checked: boolean, originalEvent: PointerEvent}.
  // WARN: Event type depende on primeNg version
  checkedChanged(event: { checked: boolean }): void {
    this.onCheckedChanged.emit(event.checked);
  }
}
