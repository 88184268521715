import { createReducer, on } from '@ngrx/store';
import { CoffeeSpecie, QualityType } from '../../domain/models';
import * as QualityActionTypes from '../actions/quality.action';

export interface QualityState {
  qualityType: QualityType[];
  coffeeSpecies: CoffeeSpecie[];
}

export const initialQualityState: QualityState = {
  qualityType: null,
  coffeeSpecies: [],
};

export const qualityReducer = createReducer(
  initialQualityState,
  on(
    QualityActionTypes.fetchQualityTypeSuccess,
    (state: QualityState, { qualityTypes }): QualityState => ({
      ...state,
      qualityType: qualityTypes,
    }),
  ),
  on(
    QualityActionTypes.fetchCoffeeSpeciesSuccess,
    (state: QualityState, { coffeeSpecies }): QualityState => ({
      ...state,
      coffeeSpecies: coffeeSpecies,
    }),
  ),
);

export const getQualityType = (state: QualityState): QualityType[] => state.qualityType;
export const getCoffeeSpecies = (state: QualityState): CoffeeSpecie[] => state.coffeeSpecies;
