import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { isNotEmpty } from '../../../../modules/utils/string-utils';
import { isNullOrUndefined } from '../../../../modules/utils/object-utils';

@Component({
  template: ` <ncs-text-basic [value]="value" (onchange)="onChange($event)"> </ncs-text-basic> `,
})
/** *
 *  Custom Text filter
 *  @see https://www.ag-grid.com/documentation/angular/component-filter/
 *  Use ncs textBasic component
 */
export class CustomTextFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  value: string = '';
  private isLocalFilter: boolean = false;

  agInit(params: IFilterParams): void {
    this.params = params;
    const { context } = params;
    this.valueGetter = params.valueGetter;
    // WARN: context setting when create GridOptions as context params
    if (!isNullOrUndefined(context)) {
      this.isLocalFilter = context.isFilterLocal();
    }
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    // Disable double filter check because filtering could be applied on server
    // Otherwise, will be applying again current filter on new rowData filtered from server
    return this.isLocalFilter
      ? this.value
          .toLowerCase()
          .split(' ')
          .every(filterWord => {
            if (isNotEmpty(this.valueGetter(params.node))) {
              return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
            }
            return false;
          })
      : true;
  }

  getModel(): any {
    if (isNotEmpty(this.value))
      return {
        value: this.value,
        filterType: 'customTextFilter',
        type: 'contains' /* Static default value */,
      };
    return null;
  }

  isFilterActive(): boolean {
    return isNotEmpty(this.value);
  }

  setModel(model: any): void {
    this.value = model ? model.value : '';
  }

  onChange(value): void {
    if (value !== this.value) {
      this.value = value;
      // used to apply filters to backend and update rowData (server data)
      this.params.filterModifiedCallback();
      // AgGrid need to know when filter changed and apply new filters (local)
      this.params.filterChangedCallback();
    }
  }
}
