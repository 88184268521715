import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/ngrx/reducers';
import { fetchCuppingSessionStatus } from '../../../core/ngrx/actions';

export const TranslateAvailableGuard: () => Promise<Observable<any>> = async () => {
  const translationService = inject(TranslateService);
  // Use ANY_KEY in the translations, the service will either load or directly resolve.
  return translationService.get('ANY_KEY');
};
export const CuppingSessionStatusGuard = (): void => {
  const store = inject(Store<fromRoot.State>);
  store.dispatch(fetchCuppingSessionStatus({}));
};
