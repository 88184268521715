import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';

export function setToastNotification(param: ToastNotification): Message {
  return {
    key: 'app-notification-toast',
    severity: param.severity,
    summary: param.translateService.instant(param.summary),
    sticky: param.sticky,
    life: param.life,
  };
}

export interface ToastNotification extends Message {
  translateService?: TranslateService;
}

export enum ToastSeverityEnum {
  SUCCESS = 'success',
  WARNING = 'warn',
  INFO = 'info',
  ERROR = 'error',
}

export type NotificationHandler = {
  [P in keyof Message]: Message[P];
};
