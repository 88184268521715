import { DTO, Entity, Internal, Serialized, VIEW } from './decorators';

/**
 * Sequence for client-side ids. Will start with -1 and decrement by 1 each time.
 */
let nextClientId: number = -1;

/**
 * Generate a new client-side id.
 */
export function newId() {
  return nextClientId--;
}

/**
 * Extended by all custom Ref classes.
 */
export abstract class Ref {
  __type?: string;
  id?: number;
  __ref?: boolean = true;
}

export abstract class AbstractData {
  /**
   * Object.assign(this, source), but well typed, so the source must be a Partial of the call receiver (i.e. 'this').
   *
   * @param {Partial<this>} source an object which is compatible with 'this'
   * @returns {this}
   */
  assign(source: Partial<this>): this {
    return Object.assign(this, source);
  }
}

@DTO
export class ExclusionInfo {
  @Serialized field: string;
  @Serialized description: string;
  @Serialized reason: string;
}

export abstract class AbstractEntity extends AbstractData {
  @Serialized id?: number;
  @Internal __type?: string;
  @Internal records?: string;
  @Serialized({ elementType: ExclusionInfo })
  __filteredProperties?: ExclusionInfo[];
  @Serialized readonly createdAt?: Date;
  @Serialized readonly createdBy?: string;
  @Serialized readonly lastUpdated?: string;
  @Serialized lang: string;
  @Serialized deleted?: boolean = false;

  /**
   * Create a new entity with a client-generated id.
   */
  constructor();
  /**
   * Create a new entity with the given id.
   *
   * @param {number} id the id of the entity to create
   */
  constructor(id: number);
  /**
   * Create a new entity with the same id as the given entity.
   *
   * @param {AbstractEntity} entity the entity whose id to use for this entity
   */
  constructor(entity: AbstractEntity);
  /**
   * Constructor implementation.
   *
   * @param {number | AbstractEntity} idOrEntity either undefined, or an id or an entity with an id
   */
  constructor(idOrEntity?: number | AbstractEntity) {
    super();
    /*
     * If an id was given as argument, use that. This is the case for entities received from the server, as those
     * will ALWAYS have an id greater than 0. See the createNew() call in query.service.ts.
     *
     * Client-generated instances will never be given an explicit id, and for those we generate a new id using
     * newId(), which just decrements a global sequence.
     */
    if (typeof idOrEntity === 'number') {
      this.id = idOrEntity;
    } else if (idOrEntity !== undefined) {
      this.id = idOrEntity.id;
    } else {
      this.id = newId();
    }
  }

  equalsById(other: AbstractEntity) {
    if (!other) return false;
    return this.id === other.id;
  }
}

export abstract class AbstractVersionedEntity extends AbstractEntity {
  @Serialized readonly version?: number;
}

export class ItemTranslation<T extends AbstractEntity> {
  item: T;
  language: Language;
}

export enum Permission {
  COFFEE_TASTING = 'COFFEE_TASTING',
  AUTH_SERVICE = 'AUTH_SERVICE',
  VIEW_ROLE = 'VIEW_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  VIEW_SAMPLE_TYPE = 'VIEW_SAMPLE_TYPE',
  EDIT_SAMPLE_TYPE = 'EDIT_SAMPLE_TYPE',
  EDIT_LANGUAGE = 'EDIT_LANGUAGE',
  VIEW_LANGUAGE = 'VIEW_LANGUAGE',
  EDIT_COUNTRY = 'EDIT_COUNTRY',
  VIEW_COUNTRY = 'VIEW_COUNTRY',
  EDIT_REGION = 'EDIT_REGION',
  VIEW_REGION = 'VIEW_REGION',
  EDIT_STATE = 'EDIT_STATE',
  VIEW_STATE = 'VIEW_STATE',
  EDIT_COFFEE_SPECIE = 'EDIT_COFFEE_SPECIE',
  VIEW_COFFEE_SPECIE = 'COFFEE_COFFEE_SPECIE',
  EDIT_COFFEE_VARIETY = 'EDIT_COFFEE_VARIETY',
  VIEW_COFFEE_VARIETY = 'VIEW_COFFEE_VARIETY',
  VIEW_USER = 'VIEW_USER',
  EDIT_USER = 'EDIT_USER',
  VIEW_TENANT = 'VIEW_TENANT',
  EDIT_TENANT = 'EDIT_TENANT',
  VIEW_AGENCY = 'VIEW_AGENCY',
  EDIT_AGENCY = 'EDIT_AGENCY',
  VIEW_WAREHOUSE = 'VIEW_WAREHOUSE',
  EDIT_WAREHOUSE = 'EDIT_WAREHOUSE',
  EDIT_HARVEST_METHOD = 'EDIT_HARVEST_METHOD',
  VIEW_HARVEST_METHOD = 'VIEW_HARVEST_METHOD',
  EDIT_SOIL_TYPE = 'EDIT_SOIL_TYPE',
  VIEW_SOIL_TYPE = 'VIEW_SOIL_TYPE',
  EDIT_SAMPLE_STATUS = 'EDIT_SAMPLE_STATUS',
  EDIT_COURIER_COMPANY = 'EDIT_COURIER_COMPANY',
  EDIT_CONTACT_TYPE = 'EDIT_CONTACT_TYPE',
  VIEW_SAMPLE_STATUS = 'VIEW_SAMPLE_STATUS',
  VIEW_COURIER_COMPANY = 'VIEW_COURIER_COMPANY',
  VIEW_CONTACT_TYPE = 'VIEW_CONTACT_TYPE',
  EDIT_THIRD_PARTY_TYPE = 'EDIT_THIRD_PARTY_TYPE',
  VIEW_THIRD_PARTY_TYPE = 'VIEW_THIRD_PARTY_TYPE',
  VIEW_DRYING_PROCESS = 'VIEW_DRYING_PROCESS',
  EDIT_DRYING_PROCESS = 'EDIT_DRYING_PROCESS',
  VIEW_MILLING_PROCESS = 'VIEW_MILLING_PROCESS',
  EDIT_MILLING_PROCESS = 'EDIT_MILLING_PROCESS',
  VIEW_STAGE_PROCESSING = 'VIEW_STAGE_PROCESSING',
  EDIT_STAGE_PROCESSING = 'EDIT_STAGE_PROCESSING',
  VIEW_CUP_EVALUATOR = 'VIEW_CUP_EVALUATOR',
  EDIT_CUP_EVALUATOR = 'EDIT_CUP_EVALUATOR',
  VIEW_COFFEE_PROGRAM = 'VIEW_COFFEE_PROGRAM',
  EDIT_COFFEE_PROGRAM = 'EDIT_COFFEE_PROGRAM',
  VIEW_METRIC_TYPE = 'VIEW_METRIC_TYPE',
  EDIT_METRIC_TYPE = 'EDIT_METRIC_TYPE',
  VIEW_METRIC_TYPE_RESULTS = 'VIEW_METRIC_TYPE_RESULTS',
  EDIT_METRIC_TYPE_RESULTS = 'EDIT_METRIC_TYPE_RESULTS',
  VIEW_METRIC_GROUP = 'VIEW_METRIC_GROUP',
  EDIT_METRIC_GROUP = 'EDIT_METRIC_GROUP',
  VIEW_METRIC_DEFINITION = 'VIEW_METRIC_DEFINITION',
  EDIT_METRIC_DEFINITION = 'EDIT_METRIC_DEFINITION',
  VIEW_STANDARD_DEFINITION = 'VIEW_STANDARD_DEFINITION',
  EDIT_STANDARD_DEFINITION = 'EDIT_STANDARD_DEFINITION',
  VIEW_QUALITY = 'VIEW_QUALITY',
  EDIT_QUALITY = 'EDIT_QUALITY',
  VIEW_FACT_SHEET = 'VIEW_FACT_SHEET',
  EDIT_FACT_SHEET = 'EDIT_FACT_SHEET',
  VIEW_TASTING_NOTE = 'VIEW_TASTING_NOTE',
  EDIT_TASTING_NOTE = 'EDIT_TASTING_NOTE',
  VIEW_QUALITY_TYPE = 'VIEW_QUALITY_TYPE',
  EDIT_QUALITY_TYPE = 'EDIT_QUALITY_TYPE',
  VIEW_ITEM_DEFINITION = 'VIEW_ITEM_DEFINITION',
  EDIT_ITEM_DEFINITION = 'EDIT_ITEM_DEFINITION',
  VIEW_CHECK_LIST_DEFINITION = 'VIEW_CHECK_LIST_DEFINITION',
  EDIT_CHECK_LIST_DEFINITION = 'EDIT_CHECK_LIST_DEFINITION',
  VIEW_THIRD_PARTY = 'VIEW_QUALITY',
  EDIT_THIRD_PARTY = 'EDIT_QUALITY',
  VIEW_ADDRESS_TYPE = 'VIEW_ADDRESS_TYPE',
  EDIT_ADDRESS_TYPE = 'EDIT_ADDRESS_TYPE',
  VIEW_SAMPLE = 'VIEW_SAMPLE',
  EDIT_SAMPLE = 'EDIT_SAMPLE',
  VIEW_PACKAGE_TYPE = 'VIEW_PACKAGE_TYPE',
  EDIT_PACKAGE_TYPE = 'EDIT_PACKAGE_TYPE',
  VIEW_COFFEE_CERTIFICATION = 'VIEW_COFFEE_CERTIFICATION',
  EDIT_COFFEE_CERTIFICATION = 'EDIT_COFFEE_CERTIFICATION',
  VIEW_LABORATORY = 'VIEW_LABORATORY',
  EDIT_LABORATORY = 'EDIT_LABORATORY',
  VIEW_CUPPING_SESSION = 'VIEW_CUPPING_SESSION',
  EDIT_CUPPING_SESSION = 'EDIT_CUPPING_SESSION',
  VIEW_CUPPING_SESSION_STATUS = 'VIEW_CUPPING_SESSION_STATUS',
  EDIT_CUPPING_SESSION_STATUS = 'EDIT_CUPPING_SESSION_STATUS',
  VIEW_SAMPLE_DISPATCH_STATUS = 'VIEW_SAMPLE_DISPATCH_STATUS',
  EDIT_SAMPLE_DISPATCH_STATUS = 'EDIT_SAMPLE_DISPATCH_STATUS',
  VIEW_CUPPING_PROCESS = 'VIEW_CUPPING_PROCESS',
  EDIT_CUPPING_PROCESS = 'EDIT_CUPPING_PROCESS',
  VIEW_CUPPING_PROCESS_SEARCH = 'VIEW_CUPPING_PROCESS_SEARCH',
  EDIT_CUPPING_PROCESS_SEARCH = 'EDIT_CUPPING_PROCESS_SEARCH',
  EDIT_CUP_EVALUATOR_CUPPING_PROCESS = 'EDIT_CUP_EVALUATOR_CUPPING_PROCESS',
  VIEW_ALL_CUPPING_PROCESS = 'VIEW_ALL_CUPPING_PROCESS',
  EDIT_ALL_CUPPING_PROCESS = 'EDIT_ALL_CUPPING_PROCESS',
  VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION = 'VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION',
  EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION = 'EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION',
  LOCK_DISPATCH = 'LOCK_DISPATCH',
  UNLOCK_DISPATCH = 'UNLOCK_DISPATCH',
  VIEW_SAMPLE_FILE = 'VIEW_SAMPLE_FILE',
  EDIT_SAMPLE_FILE = 'EDIT_SAMPLE_FILE',
  VIEW_STORAGE = 'VIEW_STORAGE',
  EDIT_STORAGE = 'EDIT_STORAGE',
  VIEW_STORAGE_TYPE = 'VIEW_STORAGE_TYPE',
  EDIT_STORAGE_TYPE = 'EDIT_STORAGE_TYPE',
}

export enum ProtectionDomain {
  ADMIN_SCREEN,
  MASTER_DATA_SCREEN,
  ROLE_SCREEN,
  SAMPLE_TYPE_SCREEN,
  LANGUAGE_SCREEN,
  COUNTRY_SCREEN,
  REGION_SCREEN,
  STATE_SCREEN,
  COFFEE_SPECIE_SCREEN,
  COFFEE_VARIETY_SCREEN,
  USER_SCREEN,
  TENANT_SCREEN,
  AGENCY_SCREEN,
  WAREHOUSE_SCREEN,
  HARVEST_METHOD_SCREEN,
  SOIL_TYPE_SCREEN,
  SAMPLE_STATUS_SCREEN,
  COURIER_COMPANY_SCREEN,
  CONTACT_TYPE_SCREEN,
  STAGE_PROCESSING_SCREEN,
  MILLING_PROCESS_SCREEN,
  DRYING_PROCESS_SCREEN,
  THIRD_PARTY_SCREEN,
  THIRD_PARTY_TYPE_SCREEN,
  CUP_EVALUATOR_SCREEN,
  COFFEE_PROGRAM_SCREEN,
  METRIC_TYPE_SCREEN,
  METRIC_TYPE_RESULTS_SCREEN,
  METRIC_GROUP_SCREEN,
  METRIC_DEFINITION_SCREEN,
  STANDARD_DEFINITION_SCREEN,
  QUALITY_SCREEN,
  FACT_SHEETS_SCREEN,
  FACT_SHEET_SCREEN,
  TASTING_NOTE_SCREEN,
  QUALITY_TYPE_SCREEN,
  ITEM_DEFINITION_SCREEN,
  CHECK_LIST_DEFINITION_SCREEN,
  ADDRESS_TYPE_SCREEN,
  SAMPLE_SCREEN,
  PACKAGE_TYPE_SCREEN,
  COFFEE_CERTIFICATION_SCREEN,
  LABORATORY_SCREEN,
  CUPPING_SESSION_SCREEN,
  CUPPING_SESSION_STATUS_SCREEN,
  SAMPLE_DISPATCH_STATUS_SCREEN,
  CUPPING_PROCESS_SCREEN,
  CUPPING_PROCESS_SEARCH_SCREEN,
  SAMPLE_APPROVAL_INTERNAL_CONDITION_SCREEN,
  CUP_ILLUSTRATION_SCREEN,
  SAMPLE_FILE_SCREEN,
  STORAGE_SCREEN,
  STORAGE_TYPE_SCREEN,
}

export const permissionTable: Map<ProtectionDomain, Permission[]> = new Map<ProtectionDomain, Permission[]>()
  .set(ProtectionDomain.ADMIN_SCREEN, [
    Permission.VIEW_USER,
    Permission.EDIT_USER,
    Permission.VIEW_ROLE,
    Permission.EDIT_ROLE,
  ])
  .set(ProtectionDomain.MASTER_DATA_SCREEN, [Permission.COFFEE_TASTING])
  .set(ProtectionDomain.METRIC_DEFINITION_SCREEN, [Permission.COFFEE_TASTING])
  .set(ProtectionDomain.ROLE_SCREEN, [Permission.VIEW_ROLE, Permission.EDIT_ROLE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.TENANT_SCREEN, [Permission.VIEW_TENANT, Permission.EDIT_TENANT, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.AGENCY_SCREEN, [Permission.VIEW_AGENCY, Permission.EDIT_AGENCY, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.WAREHOUSE_SCREEN, [
    Permission.VIEW_WAREHOUSE,
    Permission.EDIT_WAREHOUSE,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.STAGE_PROCESSING_SCREEN, [
    Permission.VIEW_STAGE_PROCESSING,
    Permission.EDIT_STAGE_PROCESSING,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.MILLING_PROCESS_SCREEN, [
    Permission.VIEW_MILLING_PROCESS,
    Permission.EDIT_MILLING_PROCESS,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.DRYING_PROCESS_SCREEN, [
    Permission.VIEW_DRYING_PROCESS,
    Permission.VIEW_DRYING_PROCESS,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.CUP_EVALUATOR_SCREEN, [
    Permission.VIEW_CUP_EVALUATOR,
    Permission.EDIT_CUP_EVALUATOR,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.COFFEE_PROGRAM_SCREEN, [
    Permission.VIEW_COFFEE_PROGRAM,
    Permission.EDIT_COFFEE_PROGRAM,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.SAMPLE_TYPE_SCREEN, [
    Permission.VIEW_SAMPLE_TYPE,
    Permission.EDIT_SAMPLE_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.USER_SCREEN, [Permission.VIEW_USER, Permission.EDIT_USER, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.LANGUAGE_SCREEN, [Permission.VIEW_LANGUAGE, Permission.EDIT_LANGUAGE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.COUNTRY_SCREEN, [Permission.VIEW_COUNTRY, Permission.EDIT_COUNTRY, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.REGION_SCREEN, [Permission.VIEW_REGION, Permission.EDIT_REGION, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.STATE_SCREEN, [Permission.VIEW_STATE, Permission.EDIT_STATE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.COFFEE_SPECIE_SCREEN, [
    Permission.VIEW_COFFEE_SPECIE,
    Permission.EDIT_COFFEE_SPECIE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COFFEE_VARIETY_SCREEN, [
    Permission.VIEW_COFFEE_VARIETY,
    Permission.EDIT_COFFEE_VARIETY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.THIRD_PARTY_SCREEN, [
    Permission.VIEW_THIRD_PARTY,
    Permission.EDIT_THIRD_PARTY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.THIRD_PARTY_TYPE_SCREEN, [
    Permission.VIEW_THIRD_PARTY_TYPE,
    Permission.EDIT_THIRD_PARTY_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.USER_SCREEN, [Permission.VIEW_USER, Permission.EDIT_USER, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.HARVEST_METHOD_SCREEN, [
    Permission.VIEW_HARVEST_METHOD,
    Permission.EDIT_HARVEST_METHOD,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SOIL_TYPE_SCREEN, [
    Permission.VIEW_SOIL_TYPE,
    Permission.EDIT_SOIL_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_STATUS_SCREEN, [
    Permission.VIEW_SAMPLE_STATUS,
    Permission.EDIT_SAMPLE_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CONTACT_TYPE_SCREEN, [
    Permission.VIEW_CONTACT_TYPE,
    Permission.EDIT_CONTACT_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COURIER_COMPANY_SCREEN, [
    Permission.VIEW_COURIER_COMPANY,
    Permission.EDIT_COURIER_COMPANY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_GROUP_SCREEN, [
    Permission.VIEW_METRIC_GROUP,
    Permission.EDIT_METRIC_GROUP,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_TYPE_RESULTS_SCREEN, [
    Permission.VIEW_METRIC_TYPE_RESULTS,
    Permission.EDIT_METRIC_TYPE_RESULTS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_TYPE_SCREEN, [
    Permission.VIEW_METRIC_TYPE,
    Permission.EDIT_METRIC_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_DEFINITION_SCREEN, [
    Permission.VIEW_METRIC_DEFINITION,
    Permission.EDIT_METRIC_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.STANDARD_DEFINITION_SCREEN, [
    Permission.VIEW_STANDARD_DEFINITION,
    Permission.EDIT_STANDARD_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_SCREEN, [Permission.VIEW_QUALITY, Permission.EDIT_QUALITY, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.FACT_SHEET_SCREEN, [
    Permission.VIEW_FACT_SHEET,
    Permission.EDIT_FACT_SHEET,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_TYPE_SCREEN, [
    Permission.VIEW_QUALITY_TYPE,
    Permission.EDIT_QUALITY_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.ITEM_DEFINITION_SCREEN, [
    Permission.VIEW_ITEM_DEFINITION,
    Permission.EDIT_ITEM_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CHECK_LIST_DEFINITION_SCREEN, [
    Permission.VIEW_CHECK_LIST_DEFINITION,
    Permission.EDIT_CHECK_LIST_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.ADDRESS_TYPE_SCREEN, [
    Permission.VIEW_ADDRESS_TYPE,
    Permission.EDIT_ADDRESS_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_SCREEN, [Permission.VIEW_SAMPLE, Permission.EDIT_SAMPLE, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.PACKAGE_TYPE_SCREEN, [
    Permission.VIEW_PACKAGE_TYPE,
    Permission.EDIT_PACKAGE_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COFFEE_CERTIFICATION_SCREEN, [
    Permission.VIEW_COFFEE_CERTIFICATION,
    Permission.EDIT_COFFEE_CERTIFICATION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.LABORATORY_SCREEN, [
    Permission.VIEW_LABORATORY,
    Permission.EDIT_LABORATORY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_SESSION_SCREEN, [
    Permission.VIEW_CUPPING_SESSION,
    Permission.EDIT_CUPPING_SESSION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_SESSION_STATUS_SCREEN, [
    Permission.VIEW_CUPPING_SESSION_STATUS,
    Permission.EDIT_CUPPING_SESSION_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_DISPATCH_STATUS_SCREEN, [
    Permission.VIEW_SAMPLE_DISPATCH_STATUS,
    Permission.EDIT_SAMPLE_DISPATCH_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_APPROVAL_INTERNAL_CONDITION_SCREEN, [
    Permission.VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION,
    Permission.EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_PROCESS_SCREEN, [
    Permission.EDIT_CUP_EVALUATOR_CUPPING_PROCESS,
    Permission.VIEW_CUPPING_PROCESS,
    Permission.EDIT_CUPPING_PROCESS,
    Permission.VIEW_ALL_CUPPING_PROCESS,
    Permission.EDIT_ALL_CUPPING_PROCESS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_PROCESS_SEARCH_SCREEN, [
    Permission.VIEW_CUPPING_PROCESS_SEARCH,
    Permission.EDIT_CUPPING_PROCESS_SEARCH,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUP_ILLUSTRATION_SCREEN, [
    Permission.VIEW_ALL_CUPPING_PROCESS,
    Permission.EDIT_ALL_CUPPING_PROCESS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_FILE_SCREEN, [
    Permission.VIEW_SAMPLE_FILE,
    Permission.EDIT_SAMPLE_FILE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.TASTING_NOTE_SCREEN, [
    Permission.VIEW_TASTING_NOTE,
    Permission.EDIT_TASTING_NOTE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.STORAGE_SCREEN, [Permission.VIEW_STORAGE, Permission.EDIT_STORAGE, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.STORAGE_TYPE_SCREEN, [
    Permission.VIEW_STORAGE_TYPE,
    Permission.EDIT_STORAGE_TYPE,
    Permission.COFFEE_TASTING,
  ]);

/**
 * Enum to distinguish between the tree states:
 * - Input disabled and grayed out
 * - Input not disabled and fully visible
 * - 'Hard autofill': Input is autofilled and autofill is not supposed to be changable => disabled but NOT grayed out
 *      OR input's value is not manually setable but will NOT be ignored
 * - 'Soft autofill': Value is autofilled, but changable
 */
export enum DisabledType {
  YES,
  NO,
  HARD_AUTOFILL,
  SOFT_AUTOFILL,
}

@Entity('administration', 'Country', 'auth')
export class Country extends AbstractVersionedEntity {
  @Serialized country: string;
  @Serialized alphaCode2: string;
  @Serialized alphaCode3: string;
  @Serialized numericCode: number;
  @Serialized originCountry: boolean;
  @Serialized icoNumber: number;
  @Serialized comtrasReference: string;
}

@Entity('administration', 'Agency', 'auth')
export class Agency extends AbstractVersionedEntity {
  @Serialized agency: string;
  @Serialized code: string;
  @Serialized contactFirstName?: string;
  @Serialized contactPrefix?: string;
  @Serialized contactLastName?: string;
  @Serialized contactEmail?: string;
  @Serialized contactPhoneNumber?: string;
  @Serialized addressLine1?: string;
  @Serialized addressLine2?: string;
  @Serialized city?: string;
  @Serialized state?: string;
  @Serialized zipCode?: string;
  @Serialized deactivated?: boolean;
  @Serialized tenantId: number;
}

@Entity('mail', 'EmailAlert', 'qc')
export class EmailAdministration extends AbstractEntity {
  @Serialized code: string;
  @Serialized tenant: string;
  @Serialized sendTo: string;
  @Serialized cc: string;
  @Serialized bcc: string;
  @Serialized subject: string;
  @Serialized body: string;
}

@DTO('email', 'ContactInfo', 'auth')
export class ContactInfo {
  @Serialized email: string;
  @Serialized name: string;
}

@DTO('email', 'Body', 'auth')
export class Body {
  @Serialized content: string;
  @Serialized html: boolean;
}

@DTO('email', 'Email', 'auth')
export class Email {
  @Serialized({ elementType: ContactInfo }) to: ContactInfo[] = [];
  @Serialized({ elementType: ContactInfo }) cc?: ContactInfo[] = [];
  @Serialized({ elementType: ContactInfo }) bcc?: ContactInfo[] = [];
  @Serialized subject: string;
  @Serialized body: Body;
}

@Entity('administration', 'ComtrasInstance', 'auth')
export class ComtrasInstance extends AbstractVersionedEntity {
  @Serialized code: string;
  @Serialized name: string;
}

export enum ComtrasStatusEnum {
  AVAILABLE,
  UNAVAILABLE,
}

@DTO('comtras', 'ComtrasStatus', 'qc')
export class ComtrasStatus extends AbstractVersionedEntity {
  @Serialized status: ComtrasStatusEnum;
}

@Entity('administration', 'Tenant', 'auth')
export class Tenant extends AbstractVersionedEntity {
  @Serialized uuid?: string;
  @Serialized name?: string;
  @Serialized abbreviation?: string;
  @Serialized acronym?: string;
  @Serialized comtrasReference?: string;
  @Serialized tagetikCode?: string;
  @Serialized deactivated?: boolean;
  @Serialized codeGroupCompany?: string;
  @Serialized country: Country;
  @Serialized comtrasInstance: ComtrasInstance;
  @Serialized({ elementType: Agency }) agencies?: Agency[] = [];
}

@Entity('email', 'EmailConnection', 'auth')
export class EmailConnection extends AbstractVersionedEntity {
  @Serialized host: string;
  @Serialized port: number;
  @Serialized username: string;
  @Serialized email: string;
  @Serialized password: string;
  @Serialized tls: boolean;
  @Serialized tenant: Tenant;
}

@Entity('administration', 'Warehouse', 'auth')
export class Warehouse extends AbstractVersionedEntity {
  @Serialized warehouse: string;
  @Serialized deactivated?: boolean;
  @Serialized tenant: Tenant;
}

@Entity('authorization', 'Permission', 'auth')
export class Permissions extends AbstractEntity {
  @Serialized name?: string;
  @Serialized category?: string;
  @Serialized description?: string;
}

@Entity('authorization', 'Role', 'auth')
export class Role extends AbstractEntity {
  @Serialized name?: string;
  @Serialized code?: string;
  @Serialized({ elementType: Permissions }) permissions?: Permissions[] = [];
}

@Entity('administration', 'Language', 'auth')
export class Language extends AbstractVersionedEntity {
  @Serialized isoCode: string;
  @Serialized language: string;
  @Serialized country: Country;
}

@Entity('administration', 'State', 'auth')
export class State extends AbstractVersionedEntity {
  @Serialized state?: string;
  @Serialized codeState?: string;
  @Serialized country: Country;
  @Internal __type: 'auth.ncs.administration.entity.State';
}

@Entity('administration', 'Region', 'auth')
export class Region extends AbstractVersionedEntity {
  @Serialized region?: string;
  @Serialized description?: string;
  @Serialized country: Country;
  @Serialized state: State;
}

@Entity('authentication', 'NcsUser', 'auth')
export class User extends AbstractEntity {
  @Serialized username?: string;
  @Serialized password?: string;
  @Serialized displayName?: string;
  @Serialized firstName?: string;
  @Serialized lastName?: string;
  @Serialized phoneNumber?: string;
  @Serialized email?: string;
  @Serialized jobTitle?: string;
  @Serialized department?: string;
  @Internal dn?: string;
  @Serialized admin?: boolean;
  @Serialized({ elementType: String }) permissions?: Permission[] = [];
  @Internal tenant?: Tenant;
  @Serialized defaultTenant: Tenant;
  @Serialized defaultAgency: Agency;
  @Serialized({ elementType: Tenant }) tenants?: Tenant[] = [];
  @Serialized({ elementType: Agency }) agencies?: Agency[] = [];
  @Serialized({ elementType: Role }) roles?: Role[] = [];
  @Serialized dashboard: string;
  @Serialized language: Language;
  @Serialized deactivated?: boolean;
  @Internal cupEvaluatorId?: number; // used when any cupping is created and needs set its cup evaluator id

  hasPermissions(permissions: Permission[]): boolean {
    if (!permissions || permissions.length === 0) return true;
    return permissions.some(allowing => !!this.permissions.find(p => p === allowing));
  }

  canAccessProtectedDomain(domain: ProtectionDomain): boolean {
    const permissions: Permission[] = permissionTable.get(domain);
    return this.hasPermissions(permissions);
  }
}

@DTO('msgraph', 'DirectoryUser', 'auth')
export class DirectoryUser {
  displayName: string;
  name: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  preferredLanguage: string;
  surname: string;
  ad_id: string;
  username: string;
  department: string;
  employeeId: string;
  givenName: string;
  userPrincipalName: string;
}

@Entity('user', 'GridView', 'auth')
export class GridView extends AbstractEntity {
  @Serialized tableId: string;
  @Serialized value: string;
  @Serialized title: string;
  @Serialized isDefault: boolean;
  @Serialized user?: User;
  @Internal isSaved: boolean = true;
}

export class View {
  constructor(
    public title: string,
    public uri: string,
    public previousView?: View,
  ) {}
}

export class PaginationRequestParams {
  q: string = '';
  _s: string = '';
  page: number = 0;
  /**
   * WARN: IF need all sampleThirdPartyTypes set to empty size property
   */
  size: number = 20;
  /** Properties used by master search and should be match with each master column definition */
  columns?: string;
  sort: string = 'id,DESC';
  split?: boolean;
  deleted: boolean = false;
  /** Entities included last server response */
  _include: string = '*';
}

@DTO
export class PaginationMetadata {
  @Serialized page: number;
  @Serialized size: number;
  @Serialized totalItems: number;
  @Serialized numberItems: number;
  @Serialized totalPages: number;
}

@DTO
export class PaginatedResponse {
  @Serialized __pagination: PaginationMetadata;
  @Serialized({ elementType: AbstractEntity }) items?: any[];
}

@Entity('file', 'FileInfo')
export class FileInfo extends AbstractVersionedEntity {
  @Serialized fileKey: number; // version-independent identifier
  @Serialized createdByReadable?: string; // readable version of createdBy
  @Serialized size?: number;
  @Serialized name?: string;
  @Serialized type?: string;
}

@Entity
export class Address extends AbstractEntity {
  @Serialized specName?: string;
  @Serialized addressValue?: string;
  @Serialized addressValueAdd?: string; // (2nd line)
  @Serialized zipCode?: string;
  @Serialized city?: Location;
  @Serialized website?: string;
  @Serialized main?: boolean;
  @Serialized vatId?: string;
}

@Entity
export class CustomerProfile extends AbstractVersionedEntity {
  @Serialized type?: string;
  @Serialized category?: string;
  @Serialized abbreviation?: string;
  @Serialized keyAccount?: User;
  @Serialized contractReturnMandatory?: boolean;
  @Serialized contractReturnDays?: number;
}

@DTO('process', 'TaskListItem')
export class Task {
  @Serialized taskId: string;
  @Serialized taskDefinitionId: string;
  @Serialized processInstanceId?: string;
  @Serialized taskAssignee: string;
  @Serialized taskDueDate: Date;
  @Serialized description: string;
  @Serialized functionalKey?: string; // any 'fachlicher' key
}

/**
 * Generic application notification.
 *
 * This is used to display information about any user-relevant events occurring in the system.
 */
@Entity('notification', 'AppNotification')
export class AppNotification extends AbstractEntity {
  /**
   * The type of the notification, if someone wants to select/switch on those.
   */
  @Serialized type: string;

  /**
   * WARN or ERROR or INFO.
   */
  @Serialized level: string;

  /**
   * The date/time at which the notification was created on the server due to
   * an action someone performend (such as modifying a trade).
   */
  @Serialized createdAt: Date;

  /**
   * This is the I18N contractKey of the message, not the _actual_ message!
   * You need to use the isoCode contractKey 'notification.${message}' to get the actual parameterized message
   * and interpolate with param0, param1, param2 and/or param3, based on your message.
   */
  @Serialized message: string;

  /**
   * Generic parameters, described in the respective NotificationType enum for each notification type.
   * You need to supply those as parameters when translating the #message via the TranslateService.
   */
  @Serialized param0?: string;
  @Serialized param1?: string;
  @Serialized param2?: string;
  @Serialized param3?: string;
  @Serialized param4?: string;
  @Serialized param5?: string;

  /**
   * A unique notification identifier.
   */
  @Serialized uuid?: string;
}

export class AppVersionInfo {
  application: string;
  backend: string;
  frontend: string;
  edgeServer: string;
  database: string;
  deployTimestamp: Date;
}

export class AppTimezoneInfo {
  timezone: string;
}

@Entity('menubuilder', 'OptionMenu', 'auth')
export class OptionMenu {
  @Serialized icon: string;
  @Serialized label: string;
  @Serialized routerLink: string;
  @Serialized permission: string;
  @Serialized position: number;
  @Serialized({ elementType: OptionMenu }) items?: OptionMenu[];
  @Serialized originalLabel?: string;
}

@Entity('masterdata', 'QcMdSampleType', 'qc')
export class QcSampleType extends AbstractVersionedEntity {
  @Serialized description: string;
  @Serialized prefix: string;
}

@Entity('masterdata', 'ThirdPartyType', 'qc')
export class ThirdPartyType extends AbstractVersionedEntity {
  @Serialized thirdPartyType: string;
  @Serialized codeThirdPartyType: string;
}

@Entity('masterdata', 'ThirdPartyThirdPartyType', 'qc')
export class ThirdPartyThirdPartyType extends AbstractVersionedEntity {
  @Serialized account: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized comtrasReference: string;
}

@Entity('masterdata', 'AddressType', 'qc')
export class AddressType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('masterdata', 'CoffeeSpecie', 'qc')
export class CoffeeSpecie extends AbstractVersionedEntity {
  @Serialized coffeeSpecie: string;
  @Serialized codeCoffeeSpecie: string;
}

@Entity('masterdata', 'CoffeeVariety', 'qc')
export class CoffeeVariety extends AbstractVersionedEntity {
  @Serialized coffeeVariety: string;
  @Serialized codeCoffeeVariety: string;
  @Serialized coffeeSpecie: CoffeeSpecie;
}

@Entity('masterdata', 'StageProcessing', 'qc')
export class StageProcessing extends AbstractVersionedEntity {
  @Serialized stageProcess: string;
  @Serialized codeStageProcess: string;
}

@Entity('masterdata', 'MillingProcess', 'qc')
export class MillingProcess extends AbstractVersionedEntity {
  @Serialized millingProcess: string;
  @Serialized codeMillingProcess: string;
}

@Entity('masterdata', 'DryingProcess', 'qc')
export class DryingProcess extends AbstractVersionedEntity {
  @Serialized dryingProcess: string;
  @Serialized codeDryingProcess: string;
  @Serialized stageProcessing: StageProcessing;
}

@Entity('cupping', 'CupEvaluatorTenant', 'qc')
export class CupEvaluatorTenant extends AbstractEntity {
  @Serialized idTenant: number;
  @Serialized abbreviation: string;
}

@Entity('cupping', 'CupEvaluatorUser', 'qc')
export class CupEvaluatorUser extends AbstractEntity {
  @Serialized idUser: number;
  @Serialized userEmail: string;
}

@Entity('masterdata', 'CoffeeProgram', 'qc')
export class CoffeeProgram extends AbstractVersionedEntity {
  @Serialized coffeeProgram: string;
  @Serialized codeCoffeeProgram: string;
  @Serialized sustainability?: boolean;
  @Serialized certification?: boolean;
  @Serialized username: string;
  @Serialized tenant: string;
}

@Entity('masterdata', 'HarvestMethod', 'qc')
export class HarvestMethod extends AbstractVersionedEntity {
  @Serialized harvestMethod: string;
  @Serialized codeHarvestMethod: string;
}

@Entity('masterdata', 'SoilType', 'qc')
export class SoilType extends AbstractVersionedEntity {
  @Serialized soilType: string;
  @Serialized codeSoilType: string;
}

@Entity('masterdata', 'ContactType', 'qc')
export class ContactType extends AbstractVersionedEntity {
  @Serialized contactType: string;
  @Serialized codeContactType: string;
}

@Entity('masterdata', 'CourierCompany', 'qc')
export class CourierCompany extends AbstractVersionedEntity {
  @Serialized courierCompany: string;
  @Serialized codeCourierCompany: string;
}

@Entity('sample', 'ApprovalStatus', 'qc')
export class ApprovalStatus extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('sample', 'SampleStatus', 'qc')
export class SampleStatus extends AbstractVersionedEntity {
  @Serialized sampleStatusName: string;
  @Serialized sampleStatusCode: string;
}

@Entity('masterdata', 'MetricsDefinitionGroup', 'qc')
export class MetricsDefinitionGroup extends AbstractVersionedEntity {
  @Serialized groupName: string;
  @Serialized groupCode: string;
}

@Entity('masterdata', 'MetricsDefinitionType', 'qc')
export class MetricsDefinitionType extends AbstractVersionedEntity {
  @Serialized typeName: string;
  @Serialized typeCode: string;
}

@Entity('masterdata', 'MetricsDefinitionTypeResult', 'qc')
export class MetricsDefinitionTypeResult extends AbstractVersionedEntity {
  @Serialized typeResultName: string;
  @Serialized typeResultCode: string;
  @Serialized typeResultScore: boolean;
  @Serialized typeResultNumeric: boolean;
  @Serialized typeResultShowList: boolean;
}

@Entity('masterdata', 'MetricsDefinitionOptionImage', 'qc')
export class MetricsDefinitionOptionImage extends AbstractVersionedEntity {
  @Serialized path: string;
  @Serialized base64: string;
  @Serialized metricsDefinitionOptionId: number;
}

@Entity('masterdata', 'MetricsDefinitionOption', 'qc')
export class MetricsDefinitionOption extends AbstractVersionedEntity {
  @Serialized optionName: string;
  @Serialized optionCode: string;
  @Serialized optionDescription: string;
  @Serialized optionCheck: boolean;
  @Serialized optionNumeric: boolean;
  @Serialized optionNumericMax?: number;
  @Serialized optionNumericMin?: number;
  @Serialized optionNumericMinLabel?: string;
  @Serialized optionNumericMaxLabel?: string;
  @Serialized optionDate: boolean;
  @Serialized optionVarchar: boolean;
  @Serialized metricsDefinitionGroup: MetricsDefinitionGroup;
  @Serialized metricsDefinition?: number;
  @Serialized optionColorValue?: string;
  @Serialized({
    elementType: MetricsDefinitionOptionImage,
    composite: true,
  })
  metricsDefinitionOptionImages?: MetricsDefinitionOptionImage[] = [];
}

@Entity('masterdata', 'MetricsDefinition', 'qc')
export class MetricDefinition extends AbstractVersionedEntity {
  @Serialized code: string;
  @Serialized name: string;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
  @Serialized metricsDefinitionTypeResult: MetricsDefinitionTypeResult;
  @Serialized optionEquivalence: boolean;
  @Serialized optionShowList: boolean;
  @Serialized optionMulSelect: boolean;
  @Serialized({
    elementType: MetricsDefinitionOption,
    composite: true,
  })
  metricsDefinitionOptions: MetricsDefinitionOption[] = [];
}

@Entity('masterdata', 'StandardDefinitionOptionScoreDescription', 'qc')
export class StandardDefinitionOptionScoreDescription extends AbstractEntity {
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized scoreValue: number;
  @Serialized scoreDescription: string;
  @Serialized optionalDescription: string;
}

@Entity('masterdata', 'StandardsDefinitionEquivalences', 'qc')
export class StandardsDefinitionEquivalences extends AbstractVersionedEntity {
  @Serialized metricsDefinitionOption: MetricsDefinitionOption;
  @Serialized equivalence: number;
  @Serialized required: boolean;
  @Serialized position: number;
  @Internal parentId: number;
}

@Entity('masterdata', 'StandardDefinitionOption', 'qc')
export class StandardDefinitionOption extends AbstractVersionedEntity {
  @Serialized optionAddText: boolean;
  @Serialized optionGraph: boolean;
  @Serialized onlyOption: boolean;
  @Serialized optionValMin: number;
  @Serialized optionValMax: number;
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized({
    elementType: StandardsDefinitionEquivalences,
    composite: true,
  })
  standardsDefinitionEquivalences: StandardsDefinitionEquivalences[] = [];
  @Serialized({
    elementType: StandardDefinitionOptionScoreDescription,
    composite: true,
  })
  standardDefinitionOptionScoreDescriptions: StandardDefinitionOptionScoreDescription[] = [];
  @Serialized position: number;
}

@Entity('masterdata', 'StandardsDefinitionTenants', 'qc')
export class StandardsDefinitionTenants extends AbstractVersionedEntity {
  @Serialized tenantId: number;
  @Serialized tenantAbbreviation: string;
}

/* @Entity('masterdata', 'StandardDefinitionOptionPositions', 'qc')
export class StandardDefinitionOptionPosition extends AbstractEntity {
    @Serialized({
        elementType: StandardDefinitionOption,
        composite: true
    }) standardDefinitionOptions: StandardDefinitionOption;
    @Serialized position: number;
    @Serialized step: number;
    @Serialized minValue: number;
    @Serialized maxValue: number;
} */

@Entity('cupping', 'CupIllustrationImage', 'qc')
export class CupIllustrationImage extends AbstractEntity {
  @Serialized path: string;
  @Serialized base64: string;
  @Serialized cupNumber: number;
}

@Entity('cupping', 'CupIllustration', 'qc')
export class CupIllustration extends AbstractEntity {
  @Serialized cupItem: number;
  @Serialized cupTenants: string;
  @Serialized usedByAll: boolean;
  @Serialized commentary: string;
  @Serialized({
    elementType: CupIllustrationImage,
    composite: true,
  })
  cupIllustrationImages: CupIllustrationImage[] = [];
}

@Entity('masterdata', 'StandardDefinition', 'qc')
export class StandardDefinition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized scoreFrom: number;
  @Serialized scoreTo: number;
  @Serialized international?: boolean;
  @Serialized usedByAll?: boolean;
  @Serialized step: number;
  @Serialized totalCups: number;
  @Serialized doAverage: boolean;
  @Serialized({
    elementType: StandardDefinitionOption,
    composite: true,
  })
  standardDefinitionOptions: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardDefinitionOption,
  })
  standardDefinitionOptionsPhysical: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardDefinitionOption,
  })
  standardDefinitionOptionsSensorial: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardsDefinitionTenants,
    composite: true,
  })
  standardUsedBy: StandardsDefinitionTenants[] = [];
  /* @Serialized({
        elementType: StandardsDefinitionEquivalences,
        composite: true
    }) standardsDefinitionEquivalences: StandardsDefinitionEquivalences[] = []; */
  /* @Serialized({
        elementType: StandardDefinitionOptionScoreDescription,
        composite: true
    }) standardDefinitionOptionScoreDescriptions: StandardDefinitionOptionScoreDescription[] = []; */

  @Serialized cupIllustration: CupIllustration;
}

@Entity('sample', 'SampleTypeTenant', 'qc')
export class SampleTypeTenant extends AbstractVersionedEntity {
  @Serialized sampleType: number;
  @Serialized tenantId: number;
  @Serialized tenant: string;
}

@Entity('sample', 'SampleType', 'qc')
export class SampleType extends AbstractVersionedEntity {
  @Serialized sampleType: string;
  @Serialized codeSampleType: string;
  @Serialized referenceType: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized requireShipping?: boolean;
  @Serialized toSplit?: boolean;
  @Serialized requireApproval?: boolean;
  @Serialized openOffer?: boolean;
  @Serialized({
    elementType: SampleTypeTenant,
    composite: true,
  })
  sampleTypeTenants: SampleTypeTenant[];
}

@Entity('masterdata', 'QualityStageProcessing', 'qc')
export class QualityStageProcessing extends AbstractVersionedEntity {
  @Serialized uuid: string;
  @Serialized stageProcessing: StageProcessing;
  @Serialized comtrasReference: string;
}

@Entity('masterdata', 'QualityType', 'qc')
export class QualityType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('masterdata', 'CoffeeCertification', 'qc')
export class CoffeeCertification extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized organic: boolean;
  @Serialized transactionCertificate: boolean;
}

@Entity('masterdata', 'Quality', 'qc')
export class Quality extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized countryId: number;
  @Serialized country: string;
  @Serialized tenant?: string;
  @Serialized coffeeSpecie: CoffeeSpecie;
  @Serialized millingProcess: MillingProcess;
  @Serialized qualityType: QualityType;
  @Serialized({
    elementType: QualityStageProcessing,
    composite: true,
  })
  qualityStageProcessing: QualityStageProcessing[] = [];
  @Serialized({ elementType: CoffeeCertification })
  coffeeCertifications: CoffeeCertification[] = [];
}

@Entity('masterdata', 'ItemDefinition', 'qc')
export class ItemDefinition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized description: string;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
}

@Entity('masterdata', 'CheckListDefinition', 'qc')
export class CheckListDefinition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized description: string;
  @Serialized({ elementType: ItemDefinition })
  itemDefinitions: ItemDefinition[] = [];
}

@Entity('masterdata', 'ThirdPartyAddress', 'qc')
export class ThirdPartyAddress extends AbstractEntity {
  @Serialized fullName: string;
  @Serialized countryId: number;
  @Serialized city: string;
  @Serialized state: string;
  @Serialized zipCode: string;
  @Serialized streetAddress: string;
  @Serialized phoneNumber?: string;
  @Serialized addressType: AddressType;
}

@Entity('masterdata', 'ThirdPartyContact', 'qc')
export class ThirdPartyContact extends AbstractVersionedEntity {
  @Serialized firstName: string;
  @Serialized lastName: string;
  @Serialized email?: string;
  @Serialized phoneNumber?: string;
  @Serialized contactType: ContactType;
}

@Entity('masterdata', 'ThirdParty', 'qc')
export class ThirdParty extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized taxId?: string;
  @Serialized tenant?: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized countryId: number;
  @Serialized nkgGroup?: boolean;
  @Serialized uuid?: string;
  @Serialized approvalPassword: string;
  @Serialized defaultThirdPartyContact?: ThirdPartyContact;
  @Serialized defaultThirdPartyAddress?: ThirdPartyAddress;
  @Serialized({ elementType: ThirdPartyContact, composite: true })
  thirdPartyContacts?: ThirdPartyContact[] = [];
  @Serialized({ elementType: ThirdPartyAddress, composite: true })
  thirdPartyAddresses?: ThirdPartyAddress[] = [];
  @Serialized({
    elementType: ThirdPartyThirdPartyType,
    composite: true,
  })
  thirdPartyThirdPartyTypes?: ThirdPartyThirdPartyType[] = [];
}

@Entity('sample', 'BagType', 'qc')
export class BagType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('sample', 'PackageType', 'qc')
export class PackageType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized comtrasReference: string;
  @Serialized kgPackage: number;
  @Serialized tara: number;
  @Serialized({ elementType: BagType }) bagTypes?: BagType[] = [];
}

@Entity('sample', 'SampleThirdPartyType', 'qc')
export class SampleThirdPartyType extends AbstractVersionedEntity {
  @Serialized reference: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized thirdParty: ThirdParty;
  @Serialized ourSalesReference: string;
  @Serialized bags: number;
}

@Entity('sample', 'DispatchAddress', 'qc')
export class DispatchAddress extends AbstractEntity {
  @Serialized fullName: string;
  @Serialized countryId: number;
  @Serialized city: string;
  @Serialized state: string;
  @Serialized zipCode: string;
  @Serialized streetAddress: string;
  @Serialized phoneNumber?: string;
  @Serialized addressType: AddressType;
}

@Entity('sample', 'SampleDispatchStatus', 'qc')
export class SampleDispatchStatus extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('masterdata', 'ReferenceType', 'qc')
export class ReferenceType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('sample', 'SampleQualityFinal', 'qc')
export class SampleQualityFinal extends AbstractEntity {
  @Serialized weight: number;
  @Serialized valueResult: number;
  @Serialized quality: Quality;
}

@Entity('cupping', 'CuppingProcessQualityAllocation', 'qc')
export class CuppingProcessQualityAllocation extends AbstractEntity {
  @Serialized weight: number;
  @Serialized valueResult: number;
  @Serialized quality: Quality;
}

@Entity('sample', 'SampleApprovalInternalCondition', 'qc')
export class SampleApprovalInternalCondition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized sampleStatus: SampleStatus;
}

@Entity('sample', 'Sample', 'qc')
export class Sample extends AbstractVersionedEntity {
  @Serialized sample: string;
  @Serialized tenant?: string;
  @Serialized sampleSource: Sample;
  @Serialized date: Date;
  @Serialized sampleReference: string;
  @Serialized referenceType: ReferenceType;
  @Serialized ourReference: string;
  @Serialized contractReference: string;
  @Serialized agencyId: number;
  @Serialized quantityPackage: number;
  @Serialized quantity: number;
  @Serialized sampleBalance: number;
  @Serialized description: string;
  @Serialized qualityDescription: string;
  @Serialized countryId: number;
  @Serialized location: string;
  @Serialized packageType: PackageType;
  @Serialized sampleType: SampleType;
  @Serialized thirdParty: ThirdParty;
  @Serialized quality: Quality;
  @Serialized qualityFinal: Quality;
  @Serialized stageProcessing: StageProcessing;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized sensoryCuppingProcessId: number;
  @Serialized physicalCuppingProcessId: number;
  @Serialized sampleStatus: SampleStatus;
  @Serialized sampleStatusCommentary: string;
  @Serialized cropSample: string;
  @Serialized monthShipment: Date;
  @Serialized({
    elementType: SampleThirdPartyType,
    composite: true,
  })
  sampleThirdPartyTypes?: SampleThirdPartyType[] = [];
  @Serialized({ elementType: SampleQualityFinal, composite: true })
  sampleQualityFinals?: SampleQualityFinal[] = [];
  @Serialized approvalInternalSampleStatus: SampleStatus;
  @Serialized sampleApprovalInternalCondition: SampleApprovalInternalCondition;
  @Serialized approvalInternalThirdParty: ThirdParty;
  @Serialized approvalInternalThirdPartyContact: ThirdPartyContact;
  @Serialized approvalInternalContactName: string;
  @Serialized approvalInternalContactEmail: string;
  @Serialized approvalInternalComment: string;
  @Internal isValid: boolean = true;
  @Serialized storageTypeId: number;
  @Serialized storageId: number;
}

@Entity('sample', 'SampleFile', 'qc')
export class SampleFile extends AbstractEntity {
  @Serialized sample: Sample;
  @Serialized category: string;
  @Serialized commentary: string;
  @Serialized path: string;
  @Serialized base64: string;
  @Serialized fileType: string;
  @Internal isChecked?: boolean;
}

@Entity('sample', 'SampleApprovalInternalLog', 'qc')
export class SampleApprovalInternalLog extends AbstractVersionedEntity {
  @Serialized sample: string;
  @Serialized sampleStatus: SampleStatus;
  @Serialized sampleApprovalInternalCondition: SampleApprovalInternalCondition;
  @Serialized thirdParty: ThirdParty;
  @Serialized thirdPartyContact: ThirdPartyContact;
  @Serialized comment: string;
  @Serialized contactName: string;
  @Serialized contactEmail: string;
}

@Entity('sample', 'Dispatch', 'qc')
export class Dispatch extends AbstractEntity {
  @Serialized dispatchAwb: string;
  @Serialized dispatchSender: string;
  @Serialized dispatchReceiver: string;
  @Serialized dispatchDateReceived: Date;
  @Serialized dispatchDateSend: Date;
  @Serialized courierCompany: CourierCompany;
  @Serialized dispatchStatus: SampleDispatchStatus;
  @Serialized thirdParty: ThirdParty;
  @Serialized dispatchStatusCommentary: string;
  @Serialized approvalStatusComment: string;
  @Serialized dispatchMail: string;
  @Serialized dispatchEmailReceptor: string;
  @Serialized sample: Sample;
  @Serialized approvalStatus: ApprovalStatus;
  @Serialized({ composite: true }) destination: DispatchAddress;
  @Serialized quantity: number;
  @Serialized sampleWeight: number;
  @Serialized tenant?: string;
  @Serialized thirdPartyReference: string;
  @Serialized thirdPartyOurReference: string;
  @Serialized sampleType: SampleType; // Should be named like sampleTypeFinal
  @Serialized locked: boolean;
  @Serialized dispatchReference: string;
  @Serialized qualityDescription: string;
  @Serialized shipmentLabelType: ShipmentLabelTypeEnum;
  @Serialized shipment: Date;
  @Internal thirdPartyMailUsed: boolean;
  @Internal sampleThirdPartyTypeId?: number;
}

@Entity('cupping', 'Laboratory', 'qc')
export class Laboratory extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('cupping', 'CuppingSessionStatus', 'qc')
export class CuppingSessionStatus extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('cupping', 'CupEvaluator', 'qc')
export class CupEvaluator extends AbstractVersionedEntity {
  @Serialized cupEvaluator: string;
  @Serialized codeCupEvaluator: string;
  @Serialized certified?: boolean;
  @Serialized tenant: string;
  @Serialized guest: boolean;
  @Serialized assistant: boolean;
  @Serialized companyInfo: string;
  @Serialized cupEvaluatorUser: CupEvaluatorUser;
  @Serialized thirdParty: ThirdParty;
  @Serialized({ elementType: CupEvaluatorTenant, composite: true })
  cupEvaluatorTenants?: CupEvaluatorTenant[] = [];
}

@DTO('cupping', 'GuestEvaluatorDto', 'qc')
export class GuestEvaluatorDto {
  @Internal __type?: string;
  @Serialized name: string;
  @Serialized lastName: string;
  @Serialized code: string;
  @Serialized email: string;
  @Serialized username: string;
  @Serialized password: string;
  @Serialized companyInfo: string;
  @Serialized({ elementType: CupEvaluator, composite: true })
  cupEvaluators?: CupEvaluator[] = [];
}

export enum ComtrasContractType {
  SALE,
  PURCHASE,
}

export enum QrCodeType {
  SAMPLE = 'sp',
  DISPATCH = 'dp',
}

export class UnmappedPropComtras extends AbstractEntity {
  @Serialized property: string;
  @Serialized value: string;
}

@DTO('comtras', 'ComtrasContractData', 'qc')
export class ComtrasContractData {
  @Internal __type?: string;
  @Serialized contractType: ComtrasContractType;
  @Serialized ourReference: string;
  @Serialized bagQuantity: number;
  @Serialized packageType: PackageType;
  @Serialized monthShipment: Date;
  @Serialized referenceType: ReferenceType;
  @Serialized thirdParty: ThirdParty;
  @Serialized contractReference: string;
  @Serialized qualityStageProcessing: QualityStageProcessing;
  @Serialized cropSample: string;
  @Serialized additionalQualityInfo: string;
  @Serialized unmappedProps?: any;
  @Serialized({
    elementType: SampleThirdPartyType,
    composite: true,
  })
  sampleThirdPartyTypes?: SampleThirdPartyType[] = [];
}

@Entity('cupping', 'CuppingSessionCupEvaluator', 'qc')
export class CuppingSessionCupEvaluator extends AbstractVersionedEntity {
  @Serialized valid: boolean = false;
  @Serialized cupEvaluator: CupEvaluator;
}

@Entity('cupping', 'CuppingProcessMetricDefect', 'qc')
export class CuppingProcessMetricDefect extends AbstractEntity {
  @Serialized cupNumber: number;
  @Serialized cupDefect: number;
  @Serialized valueResult: number; // For option-per-cup
  @Internal minValue: number;
  @Internal maxValue: number;
  @Internal warningMessage: any;
}

@Entity('cupping', 'CuppingProcessMetricOption', 'qc')
export class CuppingProcessMetricOption extends AbstractVersionedEntity {
  // @Serialized cuppingProcessMetrics: CuppingProcessMetric;
  @Serialized standardsDefinitionEquivalences: StandardsDefinitionEquivalences;
  @Serialized value: number; // {valueResult} / {equivalence} (Defects)
  @Serialized equivalence?: number; // physical type
  @Serialized valueResult: number; //  general standard option
  @Serialized dateResult?: Date;
  @Serialized stringResult?: string;
  @Serialized comment: string;
  @Internal step?: number;
  @Internal index?: number;
  @Internal selected?: boolean;
}

@Entity('cupping', 'CuppingProcessMetric', 'qc')
export class CuppingProcessMetric extends AbstractVersionedEntity {
  @Serialized standardDefinitionOption: StandardDefinitionOption;
  @Serialized value: number; // score value
  @Serialized valueResult: number; //  general standard option
  @Serialized comment?: string;
  @Serialized cupsTotal?: number;
  @Serialized cupsCheck?: number; // TODO: Doesn't it matter sorting?
  @Serialized cupsValue?: number;
  @Serialized percentSampleWeight?: number;
  @Serialized percentValue?: number;
  @Serialized position: number;
  @Serialized minValue: number;
  @Serialized maxValue: number;
  @Serialized step: number;
  @Internal scoreDescriptions?: StandardDefinitionOptionScoreDescription[] = [];
  @Serialized({
    elementType: CuppingProcessMetricDefect,
    composite: true,
  })
  cuppingProcessMetricDefects?: CuppingProcessMetricDefect[] = [];
  @Serialized({
    elementType: CuppingProcessMetricOption,
    composite: true,
  })
  cuppingProcessMetricOptions?: CuppingProcessMetricOption[] = [];
  // Used for all unsaved options
  @Internal
  additionalCuppingProcessMetricOptions?: CuppingProcessMetricOption[] = [];
}

@Entity('cupping', 'CuppingProcess', 'qc')
export class CuppingProcess extends AbstractVersionedEntity {
  @Serialized cuppingSessionId: number;
  @Serialized session: string;
  @Serialized cuppingDate: Date;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized sample: Sample;
  @Serialized laboratory: Laboratory;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
  @Serialized cupEvaluator: CupEvaluator;
  @Serialized sampleWeight: number;
  @Serialized totalCups: number;
  @Serialized comment: string;
  @Serialized value: number; // score final
  @Serialized closed: boolean;
  @Serialized toAverage: boolean = false;
  @Serialized averageResult: boolean;
  @Serialized quality: Quality;
  @Serialized stageProcessing: StageProcessing;
  @Serialized applied: boolean;
  @Serialized({ elementType: CuppingProcessMetric, composite: true })
  cuppingProcessMetrics?: CuppingProcessMetric[];
  @Serialized() cupIllustration: CupIllustration;
  @Serialized({ elementType: CuppingProcess, composite: true })
  cuppingProcesses?: CuppingProcess[];
  @Serialized({ elementType: CuppingProcessQualityAllocation, composite: true })
  cuppingProcessQualityAllocations?: CuppingProcessQualityAllocation[] = [];
  @Internal isReady: boolean = false;
}

@Entity('sample', 'SampleQualityLog', 'qc')
export class SampleQualityLog extends AbstractEntity {
  @Serialized sample: Sample;
  @Serialized cuppingProcess: CuppingProcess;
  @Serialized quality: Quality;
}

@Entity('cupping', 'CuppingSessionSample', 'qc')
export class CuppingSessionSample extends AbstractEntity {
  @Serialized position: number;
  @Serialized sample: Sample;
  @Internal isAvailableToAverage: boolean = false;
}

@Entity('cupping', 'CuppingSession', 'qc')
export class CuppingSession extends AbstractVersionedEntity {
  @Serialized idSession: string;
  @Serialized session: string;
  @Serialized blind: boolean = false;
  @Serialized doAverage: boolean = false;
  @Serialized cuppingDate: Date;
  @Serialized status: CuppingSessionStatus;
  @Serialized laboratory: Laboratory;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized({ elementType: MetricsDefinitionType })
  metricsDefinitionTypes?: MetricsDefinitionType[] = [];
  @Serialized({
    elementType: CuppingSessionSample,
    composite: true,
  })
  cuppingSessionSamples: CuppingSessionSample[] = [];
  @Serialized({
    elementType: CuppingSessionCupEvaluator,
    composite: true,
  })
  cuppingSessionCupEvaluators?: CuppingSessionCupEvaluator[] = [];
  @Serialized({ elementType: CuppingProcess, composite: true })
  cuppingProcesses?: CuppingProcess[] = [];
  @Serialized sampleWeight: number;
  @Serialized totalCups: number;
  @Serialized cupIllustration: CupIllustration;
  @Internal useAllCupEvaluators: boolean = false;
}

@DTO('sample', 'DispatchApprovalCredentials', 'qc')
export class DispatchApprovalCredentials {
  @Serialized session: string;
  @Serialized thirdPartySecret: string;
  @Serialized approved: boolean;
  @Serialized comment: string;
  @Serialized sample: string;
}

// TODO: moved to Coffee-Tasting
@Entity('user', 'CuppingProcessDefaultOption', 'auth')
export class CuppingProcessDefaultOption extends AbstractEntity {
  @Serialized laboratoryId: number;
  @Serialized standardDefinitionId: number;
  @Serialized metricsDefinitionTypeId: number;
  @Serialized sampleWeight: number;
  @Serialized packageTypeId: number;
  @Serialized totalCups: number;
  @Serialized user: User;
  @Internal standardDefinition?: StandardDefinition; // used when needs an entire standard object
}

@DTO('mail', 'EmailConfirmation', 'qc')
export class EmailConfirmation {
  @Serialized dateSent: Date;
  @Serialized successful: boolean;
}

@Entity('sample', 'SampleApprovalSession', 'qc')
export class SampleApprovalSession extends AbstractEntity {
  @Serialized sample: Sample;
  @Serialized thirdParty: ThirdParty;
  @Serialized token: string;
}

@Entity('sample', 'ApprovalStatusLog', 'qc')
export class ApprovalStatusLog extends AbstractEntity {
  @Serialized dispatch: Dispatch;
  @Serialized approvalStatus: ApprovalStatus;
  @Serialized approvalSession: SampleApprovalSession;
}

@Entity('masterdata', 'FactSheetScreen', 'qc')
export class FactSheetScreen extends AbstractEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized percent: number;
}

@VIEW('masterdata', 'ScreenSize', 'qc')
export class ScreenSize {
  @Serialized id: number;
  @Serialized code: string;
  @Serialized name: string;
}

@Entity('masterdata', 'TastingNote', 'qc')
export class TastingNote extends AbstractVersionedEntity {
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized metricsDefinitionOption: MetricsDefinitionOption;
  @Serialized tenant: string;
}

@Entity('masterdata', 'FactSheetTastingNote', 'qc')
export class FactSheetTastingNote extends AbstractVersionedEntity {
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized metricsDefinitionOption: MetricsDefinitionOption;
  @Serialized value: number;
}

@Entity('masterdata', 'FactSheet', 'qc')
export class FactSheet extends AbstractEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized language: string;
  @Serialized quality: Quality;
  @Serialized sample: Sample;
  @Serialized sampleFileLandscape: SampleFile;
  @Serialized sampleFileMap: SampleFile;
  @Serialized countryId: number;
  @Serialized body: string;
  @Serialized location: string;
  @Serialized altitude: string;
  @Serialized topography: string;
  @Serialized website: string;
  @Serialized soilType: SoilType;
  @Serialized particularities: string;
  @Serialized coffeeVariety: CoffeeVariety;
  @Serialized harvestMethod: HarvestMethod;
  @Serialized harvestPeriodStart: string;
  @Serialized harvestPeriodEnd: string;
  @Serialized millingProcess: MillingProcess;
  @Serialized dryingProcess: DryingProcess;
  @Serialized availability: string;
  @Serialized tenant: string;
  @Serialized({ elementType: FactSheetScreen, composite: true })
  factSheetScreens?: FactSheetScreen[] = [];
  @Serialized({ elementType: PackageType }) packageTypes?: PackageType[] = [];
  @Serialized({ elementType: CoffeeCertification })
  coffeeCertifications: CoffeeCertification[] = [];
  @Serialized({
    elementType: FactSheetTastingNote,
    composite: true,
  })
  factSheetTastingNotes?: FactSheetTastingNote[] = [];
}

export class EmailAlert {
  code: string;
  to: string;
  id: number;
}

export enum DashboardType {
  DEFAULT = 'DEFAULT_DASHBOARD',
  MAIN = 'MAIN_DASHBOARD',
}

export class TaskItem {
  name: string;
}

export class Dashboard {
  name: string;
  type: DashboardType;
  taskItem: TaskItem;
}

export class MasterGridColumn {
  name: string;
  label: string;
  type: MasterGridColumnType = MasterGridColumnType.TEXT;
  isDefault: boolean = false;
  isPrimary: boolean = false;
}

export enum MasterGridColumnType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
}

export interface Authentication {
  username: string;
  password: string;
}

export interface CarouselCuppingProcess {
  label: string;
  sample: Sample;
  cuppingProcess: CuppingProcess;
}

export class EmailFile {
  blob: Blob;
  name: string;
}

export enum CuppingProcessType {
  DASHBOARD = 1,
  CUPPING_SESSION = 2,
  CUPPING_PROCESS = 3,
  SAMPLE = 4,
}

export class ItemPath {
  path: string;
  operator: string;
  value: any;
}

export class SearchField {
  entityParent: Function;
  labelParent: string;
  paginationRequest: PaginationRequestParams;
  fieldDisplay: string[];
  pathParent: string;
  valueParent: string;
  pathChild: string;
  valueChild: string;
}

export class AuthCredentials {
  username?: string;
  password?: string;
  msalToken?: string;
  redirect?: any;
}
@DTO('cupping', 'SampleSearch', 'qc')
export class SampleSearch {
  // Sample
  @Serialized sample: Sample;
  @Serialized sampleType: SampleType;
  @Serialized ourReference: string;
  @Serialized sampleReference: string;
  @Serialized thirdParty: ThirdParty;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized country: Country;
  @Serialized quality: Quality;
  @Serialized millingProcess: MillingProcess;
  @Serialized coffeeSpecie: CoffeeSpecie;
  @Serialized sampleDateOperator: string;
  @Serialized sampleFirstDate: Date;
  @Serialized sampleLastDate: Date;
}

@DTO('cupping', 'CuppingProcessSearch', 'qc')
export class CuppingProcessSearch extends SampleSearch {
  // Sample analysis
  @Serialized session: string;
  @Serialized cupEvaluator: CupEvaluator;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
  @Serialized typeCuppingProcess: string;
  @Serialized scoreOperator: string;
  @Serialized score: number;
  @Serialized lastScore: number;
  @Serialized cuppingProcessDateOperator: string;
  @Serialized cuppingProcessFirstDate: Date;
  @Serialized cuppingProcessLastDate: Date;

  // Metric definition
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized metricsDefinitionOperator: string;
  @Serialized metricsDefinitionMinValue: number;
  @Serialized metricsDefinitionMaxValue: number;

  // Metric option
  @Serialized metricsDefinitionOption: MetricsDefinitionOption;
  @Serialized metricsDefinitionOptionOperator: string;
  @Serialized metricsDefinitionOptionMinValue: number;
  @Serialized metricsDefinitionOptionMaxValue: number;
}

@Entity('sample', 'StorageType', 'qc')
export class StorageType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
}

@Entity('sample', 'SampleStorage', 'qc')
export class SampleStorage extends AbstractVersionedEntity {
  @Serialized sample: Sample;
  @Serialized released: boolean;
  @Serialized folio: number;
}

@Entity('sample', 'Storage', 'qc')
export class Storage extends AbstractVersionedEntity {
  @Serialized code: string;
  @Serialized laboratory: Laboratory;
  @Serialized storageType: StorageType;
  @Serialized rack: string;
  @Serialized section: string;
  @Serialized capacity: number;
  @Serialized stock: number = 0;
  @Serialized available: number = 0;
  @Serialized startCounter: number;
  @Serialized endCounter: number;
  @Serialized currentCounter: number;
  @Serialized metaReleased: string;
  @Serialized({ elementType: SampleStorage }) sampleStorages?: SampleStorage[] = [];
}

@Entity('sample', 'view.StorageView', 'qc')
export class StorageView extends Storage {}

export enum ShipmentLabelTypeEnum {
  SHIPMENT = 'SHIPMENT',
  DELIVERY = 'DELIVERY',
}
