import { createAction, props } from '@ngrx/store';
import {
  CupEvaluator,
  CuppingProcess,
  CuppingSession,
  Laboratory,
  MetricsDefinitionType,
  PaginationRequestParams,
  Sample,
  StageProcessing,
  StandardDefinition,
} from '../../domain/models';

export enum CuppingProcessAction {
  RESET_CUPPING_PROCESS = '[CuppingProcess] Reset CuppingProcess',
  FETCH_CUPPING_PROCESS = '[CuppingProcess] Fetch CuppingProcess',
  SELECT_CUPPING_PROCESS = '[CuppingProcess] Select CuppingProcess',
  FETCH_CUPPING_PROCESS_SUCCESS = '[CuppingProcess] Fetch CuppingProcess Success',
  GET_CUPPING_SESSION = '[CuppingProcess] Get CuppingSession',
  GET_CUPPING_SESSION_SUCCESS = '[CuppingProcess] Get CuppingSession Success',
  GET_CUPPING_PROCESS = '[CuppingProcess] Get CuppingProcess',
  GET_CUPPING_PROCESS_SUCCESS = '[CuppingProcess] Get CuppingProcess Success',
  GET_CUP_EVALUATOR = '[CuppingProcess] Get CupEvaluator',
  GET_CUP_EVALUATOR_SUCCESS = '[CuppingProcess] Get CupEvaluator Success',
  GET_LABORATORY = '[CuppingProcess] Get Laboratory',
  GET_LABORATORY_SUCCESS = '[CuppingProcess] Get Laboratory Success',
  GET_SAMPLE = '[CuppingProcess] Get Sample',
  GET_SAMPLE_SUCCESS = '[CuppingProcess] Get Sample Success',
  GET_STANDARD_DEFINITION = '[CuppingProcess] Get StandardDefinition',
  GET_STANDARD_DEFINITION_SUCCESS = '[CuppingProcess] Get StandardDefinition Success',
  GET_METRICS_DEFINITION_TYPE = '[CuppingProcess] Get metricsDefinitionType',
  GET_METRICS_DEFINITION_TYPE_SUCCESS = '[CuppingProcess] Get metricsDefinitionType Success',
  FETCH_LABORATORIES = '[CuppingProcess] Fetch Laboratories ',
  FETCH_LABORATORIES_SUCCESS = '[CuppingProcess] Fetch Laboratories Success',
  FETCH_CUP_EVALUATORS = '[CuppingProcess] Fetch Cup evaluators ',
  FETCH_CUP_EVALUATORS_SUCCESS = '[CuppingProcess] Fetch Cup evaluators Success',
  FETCH_STAGE_PROCESS = '[CuppingProcess] Request Stage Process',
  FETCH_STAGE_PROCESS_SUCCESS = '[CuppingProcess] Request Stage Process Success',
}

export const resetCuppingProcessState = createAction(CuppingProcessAction.RESET_CUPPING_PROCESS);

export const fetchCuppingProcessBySessionAndEvaluator = createAction(
  CuppingProcessAction.FETCH_CUPPING_PROCESS,
  props<{
    cuppingSessionId: number;
    cuppingEvaluatorId: number;
    sampleId?: number;
    paginationRequest?: PaginationRequestParams;
  }>(),
);

export const fetchCuppingProcessSuccess = createAction(
  CuppingProcessAction.FETCH_CUPPING_PROCESS_SUCCESS,
  props<{ cuppingProcesses: CuppingProcess[] }>(),
);

export const fetchLaboratories = createAction(
  CuppingProcessAction.FETCH_LABORATORIES,
  props<{ paginationRequest?: PaginationRequestParams }>(),
);

export const fetchLaboratoriesSuccess = createAction(
  CuppingProcessAction.FETCH_LABORATORIES_SUCCESS,
  props<{ laboratories: Laboratory[] }>(),
);

export const selectCuppingProcess = createAction(
  CuppingProcessAction.SELECT_CUPPING_PROCESS,
  props<{ cuppingProcess: CuppingProcess }>(),
);

export const getCuppingProcess = createAction(
  CuppingProcessAction.GET_CUPPING_PROCESS,
  props<{ cuppingProcessId: number; paginationRequest?: PaginationRequestParams }>(),
);

export const getCuppingProcessSuccess = createAction(
  CuppingProcessAction.GET_CUPPING_PROCESS_SUCCESS,
  props<{ cuppingProcess: CuppingProcess }>(),
);

export const getCuppingSessionById = createAction(
  CuppingProcessAction.GET_CUPPING_SESSION,
  props<{ cuppingSessionId: number; paginationRequest?: PaginationRequestParams }>(),
);

export const getCuppingSessionByIdSuccess = createAction(
  CuppingProcessAction.GET_CUPPING_SESSION_SUCCESS,
  props<{ cuppingSession: CuppingSession }>(),
);

export const getCupEvaluatorById = createAction(
  CuppingProcessAction.GET_CUP_EVALUATOR,
  props<{ cupEvaluatorId: number }>(),
);

export const getCupEvaluatorByIdSuccess = createAction(
  CuppingProcessAction.GET_CUP_EVALUATOR_SUCCESS,
  props<{ cupEvaluator: CupEvaluator }>(),
);

export const getLaboratoryById = createAction(CuppingProcessAction.GET_LABORATORY, props<{ laboratoryId: number }>());

export const getLaboratoryByIdSuccess = createAction(
  CuppingProcessAction.GET_LABORATORY_SUCCESS,
  props<{ laboratory: Laboratory }>(),
);

export const getSampleById = createAction(
  CuppingProcessAction.GET_SAMPLE,
  props<{ sampleId: number; paginationRequest?: PaginationRequestParams }>(),
);

export const getSampleByIdSuccess = createAction(CuppingProcessAction.GET_SAMPLE_SUCCESS, props<{ sample: Sample }>());

export const getStandardDefinitionById = createAction(
  CuppingProcessAction.GET_STANDARD_DEFINITION,
  props<{ standardDefinitionId: number; paginationRequest?: PaginationRequestParams }>(),
);

export const getStandardDefinitionByIdSuccess = createAction(
  CuppingProcessAction.GET_STANDARD_DEFINITION_SUCCESS,
  props<{ standardDefinition: StandardDefinition }>(),
);

export const getMetricDefinitionTypeById = createAction(
  CuppingProcessAction.GET_METRICS_DEFINITION_TYPE,
  props<{ metricsDefinitionTypeId: number }>(),
);

export const getMetricDefinitionTypeByIdSuccess = createAction(
  CuppingProcessAction.GET_METRICS_DEFINITION_TYPE_SUCCESS,
  props<{ metricsDefinitionType: MetricsDefinitionType }>(),
);

export const fetchCupEvaluators = createAction(
  CuppingProcessAction.FETCH_CUP_EVALUATORS,
  props<{ userDefaultTenantId: number; paginationRequest?: PaginationRequestParams }>(),
);

export const fetCupEvaluatorsSuccess = createAction(
  CuppingProcessAction.FETCH_CUP_EVALUATORS_SUCCESS,
  props<{ cupEvaluators: CupEvaluator[] }>(),
);

export const fetchStageProcess = createAction(
  CuppingProcessAction.FETCH_STAGE_PROCESS,
  props<{ paginationRequest?: PaginationRequestParams }>(),
);

export const fetchStageProcessSuccess = createAction(
  CuppingProcessAction.FETCH_STAGE_PROCESS_SUCCESS,
  props<{ stageProcess: StageProcessing[] }>(),
);
