import { Injectable } from '@angular/core';
import { clone as _clone, isUndefined } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../authorization/services/authorization.service';
import { TimeService } from '../utils/time-service';
import 'reflect-metadata';
import { isNullOrUndefined, nullsafe, ObjectPath, toPathSpec } from '../utils/object-utils';
import {
  createNew,
  getNameToConstructorEntries,
  isKnownType,
  JsonPropertyAccess,
  PropertyInfo,
  schemaForPrototypeChain,
  TYPE_KEY,
} from '../../core/domain/decorators';
import { AbstractCeisService } from '../../core/service/app.abstract.service';
import { convertDate } from '../../shared/basic-shared-module/utils/date-utils';
import { AbstractEntity, EmailAlert, PaginationRequestParams } from '../../core/domain/models';
import { Constants } from '../../core/utils/app.constants';

interface PropertyTypeInfo {
  type: TypeInfo;
  isSerialized?: boolean;
  composite?: boolean;
  default?: any;
  access?: JsonPropertyAccess;
}

interface TypeInfo {
  isKnownClass?: boolean;
  unresolved?: boolean;
  isPrimitive?: boolean;
  isArray?: boolean;
  elementType?: TypeInfo;
  properties?: Map<PropertyKey, PropertyTypeInfo>;
  ctor?: Function;
}

@Injectable({
  providedIn: 'root',
})
export class QueryService extends AbstractCeisService {
  private readonly getUrlMenus: string;
  private schemas: Map<string, TypeInfo>;
  private readonly getUrlLang: string;
  private readonly getUrlTranslations: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    private authService: AuthorizationService,
  ) {
    super(http, configuration, timeService);
    this.getUrlMenus = `${configuration.SERVER_WITH_AUTH_API_URL}rest/menus`;
    this.getUrlLang = configuration.LANG_QUERY_URL;
    this.getUrlTranslations = configuration.TRANSLATIONS_URL;
  }

  public getUrlForEntity(entityName: string, serviceUrl?: string): string {
    if (isNullOrUndefined(entityName)) {
      return null;
    }
    const entityPrefix = entityName.split('.');

    const entityUrl =
      serviceUrl ||
      entityPrefix[entityPrefix.length - 1]
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();
    if (entityPrefix[0] === 'auth') {
      return `${this.configuration.SERVER_WITH_AUTH_API_URL}${entityUrl}`;
    }
    if (entityPrefix[0] === 'qc') {
      return `${this.configuration.SERVER_WITH_QC_API_URL}${entityUrl}`;
    }
  }

  /**
   * Return whether the given Function is a constructor for a primitive type.
   *
   * @param {Function} type the constructor function
   * @returns {boolean} true iff the type is a constructor for a primitive type; false otherwise
   */
  private static isPrimitiveType(type: Function): boolean {
    return type === String || type === Boolean || type === Number;
  }

  /**
   * Build the PropertyTypeInfo for a property with the given 'type' constructor function and PropertyInfo information.
   *
   * @param {Function} type the constructor function of the property's type
   * @param {Map<Function, TypeInfo>} ctorToTypeInfo maps all already built TypeInfo objects by their constructor functions
   * @param {PropertyInfo} pi a possible PropertyInfo holding more information about the property
   * @returns {PropertyTypeInfo} the built PropertyTypeInfo
   */
  private static getPropertyTypeInfo(
    type: Function,
    ctorToTypeInfo: Map<Function, TypeInfo>,
    pi?: PropertyInfo,
  ): PropertyTypeInfo {
    const ti: TypeInfo = this.getTypeInfo(type, ctorToTypeInfo, pi);
    return {
      type: ti,
      isSerialized: pi !== undefined && pi.serialized,
      composite: pi !== undefined && pi.composite,
      default: pi !== undefined && pi.default,
      access: pi && pi.access,
    };
  }

  /**
   * Build the TypeInfo for the given 'type' constructor function.
   *
   * @param {Function} type the constructor function to build the TypeInfo object for
   * @param {Map<Function, TypeInfo>} ctorToTypeInfo maps all already built TypeInfo objects by their constructor functions
   * @param {PropertyInfo} pi a possible PropertyInfo used as a decorator argument
   * @returns {TypeInfo} the built TypeInfo
   */

  private static getTypeInfo(type: Function, ctorToTypeInfo: Map<Function, TypeInfo>, pi?: PropertyInfo): TypeInfo {
    if (ctorToTypeInfo.has(type)) {
      return ctorToTypeInfo.get(type);
    }
    const ti: TypeInfo = {};
    ti.ctor = type;
    ti.isPrimitive = QueryService.isPrimitiveType(type);
    if (!ti.isPrimitive) {
      ti.isArray = type === Array;
      if (pi !== undefined) {
        if (ti.isArray) {
          /* Get the element type, which MUST have been declared and also processed before. */
          ti.elementType = QueryService.getTypeInfo(<Function>pi.elementType, ctorToTypeInfo, undefined);
        }
      }
      ti.isKnownClass = isKnownType(type);
      ti.unresolved = ti.isKnownClass;
    }
    return ti;
  }

  /**
   * Will be called to build the map from type name to TypeInfo.
   *
   * @returns {Map<string, TypeInfo>} the map from type name to TypeInfo
   */
  private static buildSchema(): Map<string, TypeInfo> {
    const map: Map<string, TypeInfo> = new Map();
    const ctorToTypeInfo: Map<Function, TypeInfo> = new Map();
    const unresolvedTypeInfos: PropertyTypeInfo[] = [];
    for (const entry of getNameToConstructorEntries()) {
      const key = entry[0];
      const value = entry[1];
      const properties: Map<PropertyKey, PropertyTypeInfo> = new Map();
      const clazz: TypeInfo = {
        properties,
        isKnownClass: true,
        ctor: value,
      };
      map.set(key, clazz);
      ctorToTypeInfo.set(value, clazz);
      const schema = schemaForPrototypeChain(value.prototype);
      for (const prop in schema) {
        const type = Reflect.getMetadata('design:type', value.prototype, prop);
        const ncsProperty: PropertyInfo = schema[prop];
        if (ncsProperty !== undefined && !ncsProperty.serialized) {
          /* This property is an internal property and not meant for serialization */
          continue;
        }
        const cp: PropertyTypeInfo = QueryService.getPropertyTypeInfo(type, ctorToTypeInfo, ncsProperty);
        properties.set(prop, cp);
        if (cp.type.unresolved) {
          unresolvedTypeInfos.push(cp);
        }
      }
    }
    for (const pt of unresolvedTypeInfos) {
      const type = ctorToTypeInfo.get(pt.type.ctor);
      if (pt.type !== type) {
        pt.type = type;
      } else {
        throw new Error(`Failed to resolve TypeInfo for ${pt.type.ctor}.`);
      }
    }
    return map;
  }

  /**
   * Get the TypeInfo for the given type name.
   *
   * @param {string} type the type name
   * @returns {TypeInfo} the TypeInfo
   */
  private getSchemaOf(type: string): TypeInfo {
    if (this.schemas === undefined) {
      this.schemas = QueryService.buildSchema();
    }
    return this.schemas.get(type);
  }

  /**
   * Convert the JSON payload object received from a server response to the internal data
   * structure based on the static type information of the known types.
   *
   * @returns {any} the returned client-side object
   */
  public fromServer(json: any, type?: TypeInfo): any {
    if (isNullOrUndefined(json)) return json;
    if (Array.isArray(json) && isNullOrUndefined(type)) return json.map(v => this.fromServer(v));
    /* Check whether we have a type property */
    const typeName = json[TYPE_KEY];
    if (typeName === undefined) {
      if (type === undefined) {
        throw new Error(
          `JSON object [${JSON.stringify(json)}] does not define ${TYPE_KEY} property. Cannot map from server.`,
        );
      }
    } else {
      type = this.getSchemaOf(typeName);
      if (type === undefined) {
        throw new Error(`Type [${typeName}] is not known. Cannot map from server.`);
      }
    }
    if (type.ctor === Date) {
      if (typeof json === 'string' || typeof json === 'number') {
        /* It is a ISO 8601 formatted date value. Parse it. */
        return convertDate(<string>json);
      }
      throw new Error(
        `Expected Date value [${JSON.stringify(
          json,
        )}] to be of type String or Number, but was [${typeof json}]. Cannot map from server.`,
      );
    } else if (type.isPrimitive) {
      /* It is a primitive value, return as is. */
      return json;
    } else if (type.isKnownClass) {
      /* It is an instance of a known class. Map properties. */
      return this.fromServerClass(json, type);
    } else if (type.isArray) {
      /* It is an array. Use the elementType. */
      return json.map(e => this.fromServer(e, type.elementType));
    } else {
      throw new Error(
        `Cannot handle JSON ${JSON.stringify(
          json,
        )} of type ${type}. Probably @Entity or @DTO decorator/annotation missing. Cannot map to server.`,
      );
    }
  }

  private fromServerClass(json: any, type: TypeInfo): any {
    if (typeof json === 'number') {
      // Unresolved referenced entity, just return a new object with the id
      return createNew(type.ctor, json);
    }
    const result = createNew(type.ctor, json.id);

    for (const e of type.properties.entries()) {
      const propertyName = e[0];
      const propertyType = e[1];
      if (!propertyType.isSerialized || propertyType.access === JsonPropertyAccess.READ_ONLY) {
        /* We do not want to deserialize this property */
        continue;
      }
      if (!(propertyName in json)) {
        /* The JSON does not contain that property. Ignore */
        continue;
      }
      const actualValue = json[propertyName];
      if (propertyType.type.isKnownClass && typeof actualValue === 'number') {
        /* It is an ID without a type */
        result[propertyName] = createNew(propertyType.type.ctor, actualValue);
      } else {
        result[propertyName] = this.fromServer(actualValue, propertyType.type);
      }
    }
    return result;
  }

  /**
   * Convert the client-side JavaScript object 'value' into a JSON payload structure to be sent to the server
   * based on the static type information of the known types.
   *
   * @param value the JavaScript object to be converted to a suitable object to create a JSON object from
   * @param composite whether this value is part of a composite and should not be serialized as a reference
   * @param {TypeInfo} type the optional TypeInfo of the value. If this is omitted, 'value' must be an object with
   *                   a type property whose value is the type name
   * @param root whether this is the root object (i.e. the first call to toServer())
   * @returns {any} the object to be sent to the server as JSON
   */
  public toServer(value: any, composite: boolean = true, type?: TypeInfo, root: boolean = true): any {
    if (isNullOrUndefined(value)) return value;
    if (Array.isArray(value) && isNullOrUndefined(type)) return value.map(v => this.toServer(v, composite, type, root));
    /* Check whether we have a type property */
    const typeName = value[TYPE_KEY];
    if (typeName === undefined) {
      if (type === undefined) {
        throw new Error(
          `Object [${JSON.stringify(value)}] does not define ${TYPE_KEY} property. Cannot map to server.`,
        );
      }
    } else {
      type = this.getSchemaOf(typeName);
      if (type === undefined) {
        throw new Error(`Type [${typeName}] is not known. Cannot map to server.`);
      }
    }
    if (isUndefined(type)) {
      throw new Error(`Type [${typeName}] is not a known type. Cannot map to server.`);
    }
    if (type.ctor === Date) {
      /* It is a Date value. Format ISO 8601 string. */
      return convertDate(value);
    }
    if (type.isPrimitive) {
      /* It is a primitive value. Return as is. */
      return value;
    }
    if (type.isKnownClass) {
      /* It is an instance of a known class. Map properties. */
      return this.toServerClass(value, composite, type, true);
    }
    if (type.isArray) {
      /* It is an array. Use the elementType. */
      return value.map(e => this.toServer(e, composite, type.elementType, root));
    }
    throw new Error(
      `Cannot handle value ${JSON.stringify(
        value,
      )} of type ${type}. Probably @Entity or @DTO decorator/annotation missing. Cannot map to server.`,
    );
  }

  private static toRef(value: any, type: TypeInfo): any {
    if (type.ctor === value.constructor) {
      /* Static type is exactly equal to runtime type. Serialize only id. */
      return value.id;
    }
    /* Serialize full Ref (id, type, __ref) */
    // return {[TYPE_KEY]: value[TYPE_KEY], id: value.id, __ref: true}; [QC-345]
    return value.id;
  }

  private toServerClass(value: any, composite: boolean, type: TypeInfo, root: boolean): any {
    if (typeof value === 'number') {
      // Unresolved reference -> keep as it is
      return value;
    }
    // QC-115: Disable because don't typing nested sampleThirdPartyTypes
    if (!composite && !isNullOrUndefined(value.id) && value.id > 0) {
      /* Serialize as reference */
      return QueryService.toRef(value, type);
    }
    const result: any = {};
    /* Include the __type property if this is the root object or the class is unexpected */
    // QC-115
    if (root || value.constructor !== type.ctor) {
      result[TYPE_KEY] = value[TYPE_KEY];
    }
    result[TYPE_KEY] = value[TYPE_KEY];
    /* Iterate over all static properties */
    for (const e of type.properties.entries()) {
      const propertyName = e[0];
      const propertyType = e[1];
      if (!propertyType.isSerialized || propertyType.access === JsonPropertyAccess.WRITE_ONLY) {
        /* We do not want to serialize this property */
        continue;
      }
      if (!(propertyName in value)) {
        continue; // the property has not been assigned and we do not care about its value
      }
      let actualValue = value[propertyName];
      /* Check that the value is set to undefined */
      if (isNullOrUndefined(actualValue)) {
        if (!isNullOrUndefined(propertyType.default)) {
          /* This property has a default value. Set it. */
          actualValue = propertyType.default;
        } else {
          /*
           * Undefined values are not transferred to backend ({field: undefined} |json) => {}.
           * To delete a value it must be set to 'null' due to versioning.
           */
          actualValue = null;
        }
      }
      const { composite } = propertyType;
      result[propertyName] = this.toServer(actualValue, !!composite, propertyType.type, false);
    }
    return result;
  }

  public async obtainMenus(): Promise<any[]> {
    const res = await this.get(`${this.getUrlMenus}/obtainMenus`, true, () => this.authService.renewToken());
    return this.fromServer(res || []);
  }

  /**
   * This backend result is object's array, this first value is the total records and second value is a set records for search
   * @param entityType
   * @param searchParams
   * @param serviceUrl
   * @param fields
   * @param include
   * @param currentId
   * @param useTranslations
   * @returns {Promise<any[]>}
   */
  public async querySearch<T>(
    entityType: Function,
    searchParams?: PaginationRequestParams,
    serviceUrl?: string,
    fields?: ObjectPath,
    include?: ObjectPath,
    currentId?: number,
    useTranslations?: boolean,
  ): Promise<T> {
    const typeInfo: TypeInfo = this.getSchemaOf(entityType.prototype[TYPE_KEY]);
    if (isNullOrUndefined(typeInfo)) {
      throw new Error(`Type [${entityType.prototype[TYPE_KEY]}] is not a known type. Cannot map to server.`);
    }
    let urlTranslations = '';
    let langParam = '';
    if (useTranslations) {
      urlTranslations = this.getUrlTranslations;
    } else {
      const isoCode: string = this.getLanguage();
      langParam = isNullOrUndefined(isoCode) ? '' : this.getUrlLang + isoCode;
    }
    const currentIdParam = currentId ? `${currentId}/` : '';

    if (!isNullOrUndefined(searchParams)) {
      const newColumns = !isNullOrUndefined(fields) ? toPathSpec(fields) : searchParams.columns;
      const newInclude = !isNullOrUndefined(include) ? toPathSpec(include) : searchParams._include;
      searchParams = Object.assign(_clone(searchParams), {
        columns: newColumns,
        _include: newInclude,
      });
    }
    // PaginatedResponse or T
    let resp = await this.getWithParams<any>(
      `${this.getUrlForEntity(entityType.prototype[TYPE_KEY], serviceUrl)}/${currentIdParam}${urlTranslations}${langParam}`,
      searchParams,
      true,
      () => this.renewToken(),
    );
    if (!isNullOrUndefined(resp) && nullsafe(resp?.items || []).length) {
      resp = Object.assign(resp, { items: this.fromServer(resp?.items) });
    }
    return <T>resp;
  }

  public getEntityById<T>(
    entityType: Function,
    id?: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<T> {
    if (!paginationRequestParams) {
      paginationRequestParams = new PaginationRequestParams();
      paginationRequestParams._include = '*';
      paginationRequestParams.size = null;
    }

    return this.querySearch<T>(entityType, paginationRequestParams, null, null, null, id, false);
  }

  public async saveEntity<T extends AbstractEntity>(
    entity: T,
    entityTypeFunction: Function,
    params?: any,
    isoCode?: string,
  ): Promise<T> {
    if (isNullOrUndefined(entity)) {
      return null;
    }
    const langParam = isNullOrUndefined(isoCode) ? '' : this.getUrlLang + isoCode;

    let resp: Promise<T>;
    let entityObject = this.toServer(entity);

    if (isoCode) {
      entityObject = this.setLanguage(entityObject, isoCode);
    } else {
      entityObject.lang = this.getLanguage();
    }

    if (entity.id > 0) {
      resp = this.putWithParams(
        `${this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY])}/${langParam}`,
        params,
        entityObject,
        true,
        () => this.authService.renewToken(),
      );
    } else {
      resp = this.postWithParams(
        `${this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY])}/${langParam}`,
        params,
        entityObject,
        true,
        () => this.authService.renewToken(),
      );
    }

    const res = await resp;
    return <T>res;
  }

  public async emailAlert<T>(emailAlert: EmailAlert, entityTypeFunction: Function, include?): Promise<any> {
    if (isNullOrUndefined(emailAlert)) {
      return null;
    }
    const entityUrl = this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY]);
    const url = `${entityUrl}/${emailAlert.id}/notify/${emailAlert.code}`;
    include = { language: this.getLanguage(), ...include };
    const resp = await this.postWithParams<T>(url, include, { _include: toPathSpec({}) }, true, () =>
      this.authService.renewToken(),
    );
    return <T>resp;
  }

  private setLanguage<T extends AbstractEntity>(entity: T, lang: string): T {
    Object.keys(entity).forEach(key => {
      const value = entity[key];
      if (Array.isArray(value) || typeof value == 'object') delete entity[key];
    });
    entity.lang = lang;
    return entity;
  }
}
