import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { Permission, ProtectionDomain, User } from '../../../core/domain/models';
import { AuthStoreService } from '../../../core/ngrx/store/auth.store';
import { isNullOrUndefined } from '../../utils/object-utils';

export const AuthorizationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let currentUser: User = null;
  const authService = inject(AuthorizationService);
  const authStore = inject(AuthStoreService);
  const router = inject(Router);

  window.setInterval(() => authService.loginLogoutChecker(), 1000 * 10);
  window.setInterval(() => authService.isSessionChecker(), 1000 * 10);
  authStore.selectCurrentUser().subscribe(user => (currentUser = user));

  if (authService.isLoggedIn()) {
    return checkPermissions(currentUser, route.data.restrictedTo);
  }
  const navExtras = state?.url ? { redirect: state.url } : {};
  router.navigate(['/login', navExtras]);
  return Promise.resolve(false);
};

export async function checkPermissions(
  currentUser: User,
  restrictedTo: ProtectionDomain | Permission[],
): Promise<boolean> {
  const authStore = inject(AuthStoreService);
  if (isNullOrUndefined(restrictedTo) || (restrictedTo instanceof Array && restrictedTo.length === 0)) {
    // no restriction
    return Promise.resolve(true);
  }

  // make sure that the current user is loaded prior to this check
  const resolveUser: Promise<boolean> = !currentUser ? authStore.requestCurrentUser() : Promise.resolve(true);
  const res = await resolveUser;
  // else user must have at least one matching permission
  const user: User = currentUser;
  if (!user) {
    // no permissions
    return Promise.resolve(res);
  }
  if (restrictedTo instanceof Array) {
    return user.hasPermissions(restrictedTo);
  }
  return user.canAccessProtectedDomain(restrictedTo);
}
