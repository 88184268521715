import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../../../core/ngrx/reducers';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { fetchCurrentUser } from '../../../core/ngrx/actions';

export const CurrentUserGuard: CanActivateFn = () => {
  const store = inject(Store<fromRoot.State>);
  return store.pipe(
    select(fromRoot.getCurrentUser),
    map(user => {
      // for first load, need token for do any petition to server
      const token = !!localStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN);
      if (token && !user) {
        store.dispatch(fetchCurrentUser());
      }
      return true;
    }),
    take(1),
  );
};
