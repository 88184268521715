import { Injectable, NgZone } from '@angular/core';
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  IsColumnFunc,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { DateRendererComponent } from '../renderer/date-renderer.component';
import { NumberRendererComponent } from '../renderer/number-renderer.component';
import { ActionRendererComponent } from '../renderer/action-renderer.component';
import { NumberInlineCellEditorComponent } from '../number-inline-cell-editor/number-inline-cell-editor.component';
import { TextCellEditorComponent } from '../text-inline-cell-editor/text-inline-cell-editor.component';
import { DateCellEditorComponent } from '../date-inline-cell-editor/date-inline-cell-editor.component';
import { TextTooltipCellRendererComponent } from '../tooltip/TextTooltipCellRendererComponent';
import { BooleanInlineCellEditorComponent } from '../boolean-inline-cell-editor/boolean-inline-cell-editor.component';
import { Constants } from '../../../core/utils/app.constants';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { RowAction, TooltipAttribute } from '../models/enums';

enum ColType {
  DEFAULT,
  ACTION,
  DATE,
  NUMBER,
  TEXT,
  CHECKBOX,
}

export enum AgGridColType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  NCS_FILTER = 'customTextFilter',
}

export class ColDefBuilder {
  colDef: ColDef;
  type: ColType;

  constructor(private translate: TranslateService) {
    this.colDef = {};
  }

  /**
   * no header, colId = action, fixed width of 40px
   *
   * possible actions and corresponding icons can be set via actionType[] in cellRendererParams
   * enable/disable actions by providing a boolean array via valueGetter
   * if no valueGetter is defined, all actions are enabled
   */
  getActionColDef(actions: RowAction[], valueGetter?: ((params: ValueGetterParams) => any) | string): ColDefBuilder {
    this.type = ColType.ACTION;
    Object.assign(this.colDef, {
      headerName: '',
      colId: 'action',
      minWidth: 42,
      maxWidth: 42,
      filter: false,
      sortable: false,
      pinned: 'left',
      menuTabs: [],
      valueGetter: valueGetter,
      cellRendererFramework: ActionRendererComponent,
      cellRendererParams: {
        actions: actions,
      },
      lockPosition: true,
    });
    return this;
  }

  getDefaultColDef(
    headerKey: string,
    valueGetter?: ((params: ValueGetterParams) => any) | string,
    maxWidth?: number,
  ): ColDefBuilder {
    if (!isNullOrUndefined(this.type)) throw Error('base column def already selected');

    this.type = ColType.DEFAULT;
    this.colDef = {
      colId: headerKey,
      headerName: headerKey && headerKey.length > 0 ? this.translate.instant(headerKey) : '',
      valueGetter: valueGetter,
      maxWidth: maxWidth,
      sortable: true,
      resizable: true,
    };
    return this;
  }

  getDateColDef(headerKey: string, valueGetter?: ((params: ValueGetterParams) => any) | string): ColDefBuilder {
    this.getDefaultColDef(headerKey, valueGetter);
    this.type = ColType.DATE;

    Object.assign(this.colDef, {
      cellRendererFramework: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: (dateFilter, cellValue) => {
          if (cellValue) {
            const day = cellValue.getDate();
            const month = cellValue.getMonth();
            const year = cellValue.getFullYear();

            const cellDateMatch = new Date(Number(year), Number(month), Number(day));

            if (!isNullOrUndefined(dateFilter)) {
              if (dateFilter.getTime() == cellDateMatch.getTime()) {
                return 0;
              }
              if (cellDateMatch < dateFilter) {
                return -1;
              }
              if (cellDateMatch > dateFilter) {
                return 1;
              }
            }
          }
          return -1;
        },
        browserDatePicker: true,
        suppressAndOrCondition: true,
        minValidYear: 2020,
      },
    });
    return this;
  }

  getDateAndTimeColDef(headerKey: string, valueGetter?: ((params: ValueGetterParams) => any) | string): ColDefBuilder {
    return this.getDateColDef(headerKey, valueGetter).addAdditionalParams({
      maxWidth: 120,
      cellRendererParams: {
        pattern: Constants.getDateFormatShortTime(),
      },
    });
  }

  /**
   * To adjust the number format, add cellRendererParams with a pattern property as additionalParams.
   */
  getNumberColDef(headerKey: string, valueGetter?: ((params: ValueGetterParams) => any) | string): ColDefBuilder {
    this.getDefaultColDef(headerKey, valueGetter);
    this.type = ColType.NUMBER;

    Object.assign(this.colDef, {
      cellRendererFramework: NumberRendererComponent,
      filter: 'customNumberFilter',
      // Custom Filter don't use filterParams.
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
      },
    });
    return this;
  }

  getQuantityColDef(headerKey: string, valueGetter?: ((params: ValueGetterParams) => any) | string): ColDefBuilder {
    this.getNumberColDef(headerKey, valueGetter);

    this.cellRendererParams({ pattern: '1.3' });

    return this;
  }

  getTextColDef(
    headerKey: string,
    valueGetter?: ((params: ValueGetterParams) => any) | string,
    customFilter?: string,
  ): ColDefBuilder {
    this.getDefaultColDef(headerKey, valueGetter);
    this.type = ColType.TEXT;

    Object.assign(this.colDef, {
      cellRendererFramework: TextTooltipCellRendererComponent,
      // filter: 'agTextColumnFilter',
      filter: customFilter || 'customTextFilter',
      /* WARN: FilterParams not working if uses CustomFilter */
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ['contains'],
      },
    });
    return this;
  }

  colId(colId: string): ColDefBuilder {
    Object.assign(this.colDef, {
      colId: colId,
    });
    return this;
  }

  editable(
    valueSetter: ((params: ValueSetterParams) => boolean) | string,
    cellEditorParams?: any,
    condition?: IsColumnFunc,
  ): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');

    Object.assign(this.colDef, {
      headerClass: 'ncs-inline-editing-column',
      valueSetter: valueSetter,
      editable: condition || true,
      cellEditorFramework: this.getCellEditorFramework(),
      cellEditorParams: cellEditorParams,
    });
    return this;
  }

  pattern(pattern: string): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    if (this.type !== ColType.NUMBER && this.type !== ColType.DATE)
      throw Error(`no pattern supported for column type ${this.type}`);
    if (pattern) {
      this.cellRendererParams({ pattern: pattern });
    }
    return this;
  }

  styleFunction(styleFunction: (params: ICellRendererParams) => any): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    if (this.type !== ColType.NUMBER) throw Error(`no style function supported for column type ${this.type}`);
    this.cellRendererParams({ styleFunction: styleFunction });
    return this;
  }

  addTooltip(
    tooltipFunction: (params: ICellRendererParams) => TooltipAttribute = (params): any => ({
      messages: params.getValue(),
      severity: 'none',
    }),
  ): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    if (this.type !== ColType.NUMBER && this.type !== ColType.TEXT)
      throw Error(`no tooltip supported for column type ${this.type}`);
    this.cellRendererParams({ tooltipFunction: tooltipFunction });
    return this;
  }

  highlightChanges(changeFunction: Function | string): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    const rules = { ...(this.colDef.cellClassRules || {}), 'change-in-cell': changeFunction };
    Object.assign(this.colDef, {
      cellClassRules: rules,
    });
    return this;
  }

  /* Disabled in favor of the grid views management
   sorted(sort: string): ColDefBuilder {
    Object.assign(this.colDef, {
      sort: sort,
    });
    return this;
  }
  */

  hidden(hidden = true): ColDefBuilder {
    Object.assign(this.colDef, {
      hide: hidden,
    });
    return this;
  }

  pinned(): ColDefBuilder {
    Object.assign(this.colDef, {
      pinned: 'left',
    });
    return this;
  }

  cellRendererParams(params: any): ColDefBuilder {
    const cellRendererParams = {
      ...(this.colDef.cellRendererParams || {}),
      ...params,
    };
    Object.assign(this.colDef, {
      cellRendererParams: cellRendererParams,
    });
    return this;
  }

  addAdditionalParams(params: Partial<ColDef>): ColDefBuilder {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    Object.assign(this.colDef, params);
    if (params.cellRenderer) {
      this.colDef.cellRendererFramework = undefined;
    }
    return this;
  }

  build(): ColDef {
    return this.colDef;
  }

  private getCellEditorFramework(): any {
    if (isNullOrUndefined(this.type)) throw Error('no base column def selected');
    if (this.type === ColType.NUMBER) return NumberInlineCellEditorComponent;
    if (this.type === ColType.TEXT) return TextCellEditorComponent;
    if (this.type === ColType.DATE) return DateCellEditorComponent;
    if (this.type === ColType.CHECKBOX) return BooleanInlineCellEditorComponent;

    throw Error(`no cell editor supported for column type ${this.type}`);
  }
}

@Injectable()
export class NkgGridFactory {
  constructor(
    private ngZone: NgZone,
    private translate: TranslateService,
  ) {}

  private resizeOptions: GridOptions[] = [];

  getColDefBuilder(): ColDefBuilder {
    return new ColDefBuilder(this.translate);
  }

  getDefaultGridOptions(sizeToFit: boolean = true, isAnother?: boolean, context?: any): GridOptions {
    const gridOptions = <GridOptions>{
      context: context,
      stopEditingWhenGridLosesFocus: true,
      animateRows: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      // suppressScrollOnNewData: true,
      // suppressLoadingOverlay: true,
      // suppressMoveWhenRowDragging: true,
      // suppressNoRowsOverlay: true,
      rowDragManaged: true,
      navigateToNextCell: params => {
        return this.keyboardNavigation(params, gridOptions);
      },
      tabToNextCell: params => {
        return this.tabNavigation(params, gridOptions);
      },
      // disable some enterprise features for now
      // suppressContextMenu: true,
      defaultColDef: {
        menuTabs: ['filterMenuTab'],
        suppressToolPanel: true,
      },
    };

    // Set i18n for all labels on grid. If label key is not on languages json files, then return ag-grid default value
    gridOptions.localeTextFunc = (k, v): string => {
      const langKey = `grid.labels.${k}`;
      const translatedLabel = this.translate.instant(langKey);
      return translatedLabel && translatedLabel !== langKey ? translatedLabel : v;
    };

    // remember last or several
    if (!isAnother) {
      this.resizeOptions = [];
    }
    this.resizeOptions.push(gridOptions);

    window.onresize = (): void => {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.resizeOptions.forEach(go => {
            if (go.api && sizeToFit) {
              go.api.sizeColumnsToFit();
            } else if (go.columnApi) {
              go.columnApi.autoSizeAllColumns();
            }
          });
        }, 500);
      });
    };

    return gridOptions;
  }

  private keyboardNavigation(params, gridOptions: GridOptions): any {
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    let previousCell = params.previousCellDef;
    const suggestedNextCell = params.nextCellDef;

    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellDef;
        // set selected cell on current cell + 1
        gridOptions.api.forEachNode(node => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellDef;
        // set selected cell on current cell - 1
        gridOptions.api.forEachNode(node => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        throw new Error('this will never happen, navigation is always on of the 4 keys above');
    }
  }

  private tabNavigation(params, gridOptions: GridOptions): any {
    const nextCell = params.nextCellDef;
    gridOptions.api.forEachNode(node => {
      if (nextCell && nextCell.rowIndex === node.rowIndex) {
        node.setSelected(true);
      }
    });
    return nextCell;
  }
}

/** Customizable ENUM that match with AgGrid Filter values
 * @see https://www.ag-grid.com/documentation/javascript/filter-provided-simple/
 */
export class FilterGridOptionsEnum {
  public static readonly EQUALS = new FilterGridOptionsEnum('equals', '==');
  public static readonly CONTAINS = new FilterGridOptionsEnum('contains', '==');
  public static readonly LESS_THAN = new FilterGridOptionsEnum('lessThan', '<');
  public static readonly GREATER_THAN = new FilterGridOptionsEnum('greaterThan', '>');
  public static readonly GREATER_THAN_OR_EQUAL = new FilterGridOptionsEnum('greaterThanOrEqual', '>=');
  public static readonly LESS_THAN_OR_EQUAL = new FilterGridOptionsEnum('lessThanOrEqual', '<=');

  public readonly filter: string;
  public readonly value: string;

  constructor(filter?: string, value?: string) {
    this.filter = filter;
    this.value = value;
  }

  public getFilter(): string {
    return this.filter;
  }

  public getValue(): string {
    return this.value;
  }

  public getAllFilters(): FilterGridOptionsEnum[] {
    return [
      FilterGridOptionsEnum.EQUALS,
      FilterGridOptionsEnum.CONTAINS,
      FilterGridOptionsEnum.LESS_THAN,
      FilterGridOptionsEnum.GREATER_THAN,
      FilterGridOptionsEnum.GREATER_THAN_OR_EQUAL,
      FilterGridOptionsEnum.LESS_THAN_OR_EQUAL,
    ];
  }
}
