<ncs-form-edit
  (ctrlS)="clickOnSave()"
  (esc)="clickOnCancel()"
  [editMode]="editMode"
  [permissions]="permissionAvailable"
  [showToolbar]="!hideToolbar">
  <div base-form="form" class="col-12" style="margin: auto">
    <!-- Left top bar section [MASTER_SEARCH] -->
    <div
      class="grid card pt-0 pb-0 flex align-items-center"
      [ngClass]="{
        'master-top-mb': !!innerWidth,
        'only-list-mode': onlyListMode
      }"
      style="position: relative">
      <div
        *ngIf="innerWidth"
        class="col-12 xl:col-1 lg:col-1 md:col-1 sm:col-1 master-button-more flex align-items-center"
        [title]="'common.labels.more' | translate">
        <i class="material-icons md-dark" (click)="swapTopBar()">swap_horiz</i>
      </div>
      <div
        class="grid master-top-bar-left grid-nogutter"
        [ngClass]="{
          'xl:col-7 lg:col-6 md:col-6 sm:col-10': !innerWidth,
          'xl:col-6 lg:col-6 md:col-10 sm:col-10 ': innerWidth && panelLeft,
          disabled: innerWidth,
          'master-top-bar-deactivated': currentViewMode === formViewMode.DETAILS_SCREEN || (innerWidth && panelRight)
        }">
        <div class="col-12 xl:col-7 lg:col-8 md:col-8 sm:col-8">
          <div class="col-12 master-grid-filter" style="margin-bottom: 0">
            <label class="master-grid-filter-wrapper" style="width: 100%">
              <span class="nkg-searchbox">
                <i class="material-icons">search</i>
              </span>
              <input
                #masterFormFilter
                pInputText
                placeholder="{{ 'common.placeholder.search' | translate }}"
                [ngStyle]="{ width: '100%' }"
                [disabled]="editMode || currentViewMode === formViewMode.DETAILS_SCREEN"
                [value]="paginationRequestParams?.q" />
            </label>
          </div>
        </div>
        <div
          class="col-12 xl:col-5 lg:col-4 md:col-4 sm:col-4 flex justify-content-start align-items-center flex-wrap"
          [ngClass]="{
            'master-top-bar-deactivated': innerWidth && panelRight
          }">
          <ng-content
            *ngIf="!(editMode || currentViewMode === formViewMode.DETAILS_SCREEN)"
            select="[button-top]"></ng-content>
          <ncs-checkbox
            class="flex justify-content-start flex-wrap"
            style="color: #757575; font-weight: bold"
            [disabledType]="
              editMode || currentViewMode === formViewMode.DETAILS_SCREEN ? disableCheckBox.YES : disableCheckBox.NO
            "
            [label]="'masterdata.labels.show_retired_records'"
            [labelIsOnTop]="false"
            [value]="paginationRequestParams?.deleted"
            (onCheckedChanged)="clickOnRetired($event)">
          </ncs-checkbox>
        </div>
      </div>

      <!-- Right top bar [MASTER_GRID_VIEWS] -->
      <div
        class="grid col-12 master-top-bar-right grid-nogutter"
        [ngClass]="{
          'xl:col-11 lg:col-11 md:col-11 sm:col-11': innerWidth && panelRight,
          'xl:col-5 lg:col-6 md:col-6': !innerWidth,
          'master-top-bar-deactivated': innerWidth && panelLeft
        }">
        <div class="col-12 xl:col-8 lg:col-8 md:col-8 sm:col-8">
          <grid-views
            *ngIf="currentViewMode === formViewMode.LIST_SCREEN"
            [gridOptions]="gridOptions"
            [gridViewNameReference]="gridViewNameReference"
            [disabledColumns]="disabledColumns"
            [defaultVisibility]="defaultVisibility"></grid-views>
        </div>
      </div>
      <div
        [ngClass]="{
          'xpand-enabled': onlyListMode && currentViewMode == formViewMode.DETAILS_SCREEN,
          'xpand-disabled': !(onlyListMode && currentViewMode == formViewMode.DETAILS_SCREEN)
        }"
        class="master-topbar-buttons">
        <ncs-button-basic
          *ngIf="!onlyListMode"
          [icon]="'pi pi pi-list'"
          [inputClass]="'nkg-primary-btn-icon master-top-bar-item'"
          [inputStyle]="{
            width: '30px',
            height: '30px',
            verticalAlign: 'middle',
            marginLeft: '3px'
          }"
          [disabled]="currentViewMode == formViewMode.SPLIT_SCREEN"
          (onClicked)="setSplitScreenMode()">
        </ncs-button-basic>
        <ncs-button-basic
          [ngClass]="{ 'btn-no-visible': currentViewMode === formViewMode.LIST_SCREEN || editMode }"
          [style.position]="currentViewMode === formViewMode.DETAILS_SCREEN ? 'absolute' : null"
          [style.left]="currentViewMode === formViewMode.DETAILS_SCREEN ? '1em' : null"
          [icon]="onlyListMode ? 'pi pi-arrow-left' : 'pi pi-window-maximize'"
          [title]="onlyListMode ? ('topbar.labels.back_to' | translate) : ''"
          [inputClass]="'nkg-primary-btn-icon master-top-bar-item back-to-list-mode-btn'"
          [inputStyle]="{
            width: '30px',
            height: '30px',
            verticalAlign: 'middle',
            marginLeft: '3px'
          }"
          [disabled]="currentViewMode === formViewMode.LIST_SCREEN || editMode"
          (onClicked)="backTo()">
        </ncs-button-basic>
      </div>
    </div>
    <ng-content select="[search=criteria]"></ng-content>
    <div class="grid" [ngClass]="{ card: currentViewMode == formViewMode.LIST_SCREEN }">
      <div
        *ngIf="currentViewMode !== formViewMode.DETAILS_SCREEN"
        class="col-12"
        [ngClass]="{
          'card xl:col-3 lg:col-3 md:col-4 sm:col-12 master-split-mode': currentViewMode === formViewMode.SPLIT_SCREEN
        }">
        <nkg-generic-grid
          [agGridStyle]="{ height: '600px' }"
          [gridViewMasterReference]="gridViewNameReference"
          [toExpand]="currentViewMode == formViewMode.LIST_SCREEN"
          [gridOptions]="gridOptions"
          [colDef]="colDef"
          [loading]="loadingData"
          [selectedItem]="gridSelectedRow"
          [paginationResponse]="paginationResponse"
          [sizeToFit]="sizeToFit"
          [contextMenuItems]="contextMenuItems"
          [wrappedInCard]="false"
          (rowClicked)="onRowClicked($event)"
          (rowDoubleClicked)="onRowDoubleClicked($event)"
          (onChangePage)="onChangePage($event)"
          (onFilterSortChanges)="onChangeGridColumnFilter()">
        </nkg-generic-grid>
      </div>
      <div
        *ngIf="currentViewMode !== formViewMode.LIST_SCREEN"
        class="col-12"
        [ngClass]="{
          'card master-item-info': currentViewMode == formViewMode.DETAILS_SCREEN,
          'xl:col-9 lg:col-9 md:col-8 sm:col-12 master-split-mode master-item-selection-wrapper':
            currentViewMode === formViewMode.SPLIT_SCREEN
        }">
        <div [ngClass]="{ card: currentViewMode === formViewMode.SPLIT_SCREEN }" style="padding: 0">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
  <span base-form="button-left" *restrictTo="permissionAvailable">
    <ncs-button-basic
      [label]="'common.labels.new_item'"
      inputClass="nkg-primary-btn"
      (onClicked)="clickOnNew()"></ncs-button-basic>
  </span>
  <span base-form="button-right">
    <ng-container *restrictTo="permissionAvailable">
      <ncs-button-basic
        *ngIf="currentViewMode !== formViewMode.LIST_SCREEN && gridSelectedRow && !editMode"
        [label]="'common.buttons.edit'"
        inputClass="nkg-primary-btn"
        (onClicked)="clickEdit()">
      </ncs-button-basic>
    </ng-container>
  </span>
</ncs-form-edit>
<p-confirmDialog
  header="{{ 'masterdata.dialog.saveUserConfirmation.title' | translate }}"
  [closable]="false"
  [closeOnEscape]="false"
  icon="fa fa-question-circle"></p-confirmDialog>
<div>
  <p-confirmDialog
    key="saveConfirmationDialog"
    header="{{ 'masterdata.dialog.saveUserConfirmation.title' | translate }}"
    [closable]="false"
    [closeOnEscape]="false"
    icon="fa fa-question-circle"></p-confirmDialog>
  <p-confirmDialog
    key="discardChangesConfirmationDialog"
    header="{{ 'masterdata.dialog.discardChangesConfirmation.title' | translate }}"
    [closable]="false"
    [closeOnEscape]="false"
    icon="fa fa-question-circle"></p-confirmDialog>
  <p-confirmDialog
    key="notification"
    header="{{ 'masterdata.dialog.notification' | translate }}"
    [closable]="false"
    [closeOnEscape]="false"
    [rejectVisible]="false"
    icon="fa fa-question-circle"></p-confirmDialog>
  <p-confirmDialog
    key="removeConfirmationDialog"
    header="{{ 'masterdata.dialog.notification' | translate }}"
    [closable]="false"
    [closeOnEscape]="false"
    icon="fa fa-question-circle"></p-confirmDialog>
</div>

<p-toast position="center" key="versioning_error_notification" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
      <h3>{{ message.summary }}</h3>
      <p>{{ message.detail }}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="ui-g-6">
        <button
          pButton
          [label]="'notification.confirm' | translate"
          (click)="onConfirm('versioning_error_notification')"
          class="ui-button-success"></button>
      </div>
      <div class="ui-g-6">
        <button
          pButton
          [label]="'notification.cancel' | translate"
          (click)="onReject('versioning_error_notification')"
          class="ui-button-cancel"></button>
      </div>
    </div>
  </ng-template>
</p-toast>
