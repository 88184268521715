import { ColDef } from 'ag-grid-community';

export enum FormMasterViewMode {
  SPLIT_SCREEN = 'SPLIT_SCREEN_MODE',
  LIST_SCREEN = 'LIST_SCREEN_MODE',
  DETAILS_SCREEN = 'DETAILS_SCREEN_MODE',
}

export interface BaseMasterGridCol {
  // Enable displaying on split_screen: (true | false)
  primaryColumn?: boolean;
  colDef: ColDef;
  default?: boolean;
}
