import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { LicenseManager } from 'ag-grid-enterprise/main';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

LicenseManager.setLicenseKey(
  'Neumann_Gruppe_GmbH_NKG_Coffee_System_(NCS)_6Devs8_March_2019__MTU1MjAwMzIwMDAwMA==01cd972099838f76b120c86854f126d8',
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
