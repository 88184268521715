import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import {
  ApprovalStatusLog,
  CoffeeCertification,
  CuppingProcess,
  PackageType,
  PaginatedResponse,
  PaginationRequestParams,
  ReferenceType,
  Sample,
  SampleApprovalInternalLog,
  SampleStatus,
  SampleType,
} from '../../domain/models';
import {
  fetchCoffeeCertifications,
  fetchCoffeeCertificationsSuccess,
  fetchPackageType,
  fetchPackageTypeSuccess,
  fetchReferenceType,
  fetchReferenceTypeSuccess,
  fetchSample,
  fetchSampleStatus,
  fetchSampleStatusSuccess,
  fetchSampleSuccess,
  fetchSampleType,
  fetchSampleTypesForTenant,
  fetchSampleTypesForTenantSuccess,
  fetchSampleTypeSuccess,
  getCOMTRASStatusAction,
  getCOMTRASStatusActionSuccess,
  getLastApprovalStatusLog,
  getLastApprovalStatusLogSuccess,
  getSampleAnalysisAverage,
  getSampleAnalysisAverageSuccess,
  getSampleApprovalInternalLogs,
  getSampleApprovalInternalLogsSuccess,
} from '../actions';
import { QueryService } from '../../../modules/services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { SampleService } from '../../../modules/master/modules/sample/services/sample.service';

@Injectable()
export class SampleEffects {
  public constructor(
    private actions$: Actions,
    private queryService: QueryService,
    private sampleService: SampleService,
  ) {}

  public readonly fetchSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSample),
      switchMap(() => this.queryService.querySearch<Sample[]>(Sample)),
      map(samples => fetchSampleSuccess({ samples: samples })),
    ),
  );

  public readonly getLasStatusLog: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getLastApprovalStatusLog),
      switchMap(payload =>
        this.sampleService
          .getLasStatusLog(payload.dispatchId)
          .then((resp: ApprovalStatusLog) => getLastApprovalStatusLogSuccess({ statusLog: resp })),
      ),
    ),
  );

  public readonly getSampleApprovalInternalLog: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getSampleApprovalInternalLogs),
      switchMap(payload =>
        this.sampleService
          .getSampleApprovalInternalLogs(payload.sampleId)
          .then((resp: SampleApprovalInternalLog[]) =>
            getSampleApprovalInternalLogsSuccess({ sampleApprovalInternalLogs: resp }),
          ),
      ),
    ),
  );

  public readonly getCuppingProcessAverage: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getSampleAnalysisAverage),
      switchMap(payload =>
        this.sampleService
          .getCuppingProcessAverage(payload.averagesIds)
          .then((cuppingProcess: CuppingProcess) =>
            getSampleAnalysisAverageSuccess({ cuppingProcess: cuppingProcess }),
          ),
      ),
    ),
  );

  public readonly fetchSampleStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSampleStatus),
      switchMap(() => this.queryService.querySearch(SampleStatus)),
      map((statuses: SampleStatus[]) => fetchSampleStatusSuccess({ samplesStatuses: statuses })),
    ),
  );

  public readonly fetchReferenceType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchReferenceType),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService.querySearch<ReferenceType[]>(ReferenceType, action.paginationRequestParams),
      ),
      map(referenceType => fetchReferenceTypeSuccess({ referenceTypes: referenceType })),
    ),
  );

  public readonly fetchPackageType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPackageType),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch<PackageType[]>(PackageType, action.paginationRequestParams)),
      map(packageType => fetchPackageTypeSuccess({ packageType: packageType })),
    ),
  );

  public readonly fetchCoffeeCertification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCoffeeCertifications),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(CoffeeCertification, action.paginationRequestParams)),
      map((response: PaginatedResponse) => fetchCoffeeCertificationsSuccess({ coffeeCerts: response.items })),
    ),
  );

  public readonly fetchSampleType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSampleType),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'sampleType,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch<SampleType[]>(SampleType, action.paginationRequestParams)),
      map(sampleType => fetchSampleTypeSuccess({ sampleType: sampleType })),
    ),
  );

  public readonly fetchSampleTypesPerTenant$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSampleTypesForTenant),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'sampleType,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.sampleService.fetchSampleTypesForTenant(action.paginationRequestParams)),
      map(sampleType => fetchSampleTypesForTenantSuccess({ sampleTypes: sampleType })),
    ),
  );

  public readonly getComtrasStatus: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getCOMTRASStatusAction),
      switchMap(() => this.sampleService.getComtrasInstanceStatus()),
      map(status => getCOMTRASStatusActionSuccess({ status: status })),
    ),
  );
}
