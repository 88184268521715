import { createAction, props } from '@ngrx/store';
import {
  MetricDefinition,
  MetricsDefinitionGroup,
  MetricsDefinitionOption,
  MetricsDefinitionType,
  MetricsDefinitionTypeResult,
  PaginationRequestParams,
} from '../../domain/models';

export enum MetricActionType {
  FETCH_METRIC_GROUPS = '[Metrics] Request Metric Definition Groups',
  FETCH_METRIC_GROUPS_SUCCESS = '[Metrics] Request Metric Definition Groups Success',
  FETCH_METRIC_TYPES = '[Metrics] Request Metric Definition Types',
  FETCH_METRIC_TYPES_SUCCESS = '[Metrics] Request Metric Definition Types Success',
  FETCH_METRIC_TYPES_RES = '[Metrics] Request Metric Definition Types Result',
  FETCH_METRIC_TYPES_RES_SUCCESS = '[Metrics] Request Metric Definition Types Result Success',
  FETCH_METRIC_OPTIONS = '[Metrics] Request Metric Definition Options',
  FETCH_METRIC_OPTIONS_SUCCESS = '[Metrics] Request Metric Definition Options Success',
  FETCH_METRIC_DEFINITIONS = '[Metrics] Request Metric Definitions',
  FETCH_METRIC_DEFINITIONS_SUCCESS = '[Metrics] Request Metric Definitions Success',
}

export const fetchMetricsDefGroups = createAction(MetricActionType.FETCH_METRIC_GROUPS);

export const fetchMetricGroupsSuccess = createAction(
  MetricActionType.FETCH_METRIC_GROUPS_SUCCESS,
  props<{ metricDefGroups: MetricsDefinitionGroup[] }>(),
);

export const fetchMetricsDefTypes = createAction(
  MetricActionType.FETCH_METRIC_TYPES,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchMetricsDefTypesSuccess = createAction(
  MetricActionType.FETCH_METRIC_TYPES_SUCCESS,
  props<{ metricTypes: MetricsDefinitionType[] }>(),
);

export const fetchMetricsDefTypesResult = createAction(MetricActionType.FETCH_METRIC_TYPES_RES);

export const fetchMetricsDefTypesResultSuccess = createAction(
  MetricActionType.FETCH_METRIC_TYPES_RES_SUCCESS,
  props<{ metricsTypeResults: MetricsDefinitionTypeResult[] }>(),
);

export const fetchMetricsDefOptionsSuccess = createAction(
  MetricActionType.FETCH_METRIC_OPTIONS_SUCCESS,
  props<{ metricOptions: MetricsDefinitionOption[] }>(),
);

export const fetchMetricsDef = createAction(MetricActionType.FETCH_METRIC_DEFINITIONS);

export const fetchMetricsDefSuccess = createAction(
  MetricActionType.FETCH_METRIC_DEFINITIONS_SUCCESS,
  props<{ metricDefinitions: MetricDefinition[] }>(),
);
