import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Constants } from '../../../core/utils/app.constants';

@Component({
  selector: 'date-renderer',
  template: ` <div>{{ value | date: pattern }}</div> `,
})
export class DateRendererComponent implements AgRendererComponent {
  value: Date;
  pattern: string = Constants.getDateFormatShort();

  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellRendererParams) {
      if (params.colDef.cellRendererParams.pattern) {
        this.pattern = params.colDef.cellRendererParams.pattern;
      }
    }
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}
