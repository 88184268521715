import { AbstractEntity, Language } from '../../../core/domain/models';

export interface TranslationIsoCode {
  isoCode: string;
  item: any;
}

export class NcsLanguageTranslation extends AbstractEntity {
  language: Language;
  translation: string;

  constructor(language: Language, translation: string) {
    super();
    this.translation = translation;
    this.language = language;
  }
}

export enum PredefinedOptionsStyle {
  NONE = 0,
  SAVE = 1, // default PredefinedOptionStyle
  DELETE = 2,
}
