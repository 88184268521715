import { EventEmitter, Injectable, Input, Output, Directive } from '@angular/core';
import { FieldValidationResult } from '../../../../modules/master/services/master-validation';
import { extractEvent } from '../../../../modules/utils/extract-event';
import { TooltipMessage } from '../tooltip/tooltip-message.component';

@Directive()
@Injectable()
export abstract class NcsBaseBasicComponent {
  @Input() id: string;
  @Input() editable: boolean = true; // Activate no editable content [false]
  /** Label top (title) */
  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() tabindex: number = null;
  @Input() value: any;
  @Input() title: string = '';
  /** Validation */
  @Input() validationResult: FieldValidationResult;
  @Input() disabled: boolean = false;
  /** Field property (string) displayed on read-only mode */
  @Input() fieldReadOnly: string;
  /** primeNg input style */
  @Input() inputStyle: any;
  /** PrimeNg class input style */
  @Input() inputClass: any;
  /** the tab-index if you want to manually set it, 0 as default */
  @Input() tabIndex: number = 0;

  @Input() placeholder: string = '';

  /** Help info about searches only descriptive */
  @Input() tooltipInfo: string;

  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onblur: EventEmitter<any> = new EventEmitter<any>();

  tooltipMessage: TooltipMessage | FieldValidationResult;
  tooltipSeverity: string = 'error';

  public onChanges(event: any): void {
    const value = extractEvent(event);
    this.onchange.emit(value);
  }

  public onBlur(event: any): void {
    this.onblur.emit(event);
  }
}
