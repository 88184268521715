import { isNullOrUndefined } from './object-utils';

export function isNotEmpty(value: string): boolean {
  return !isNullOrUndefined(value) && value?.trim().length > 0;
}

export function isEmpty(value: string): boolean {
  return !isNotEmpty(value);
}

export function padZeros(value: string, max: number): string {
  if (isNullOrUndefined(value)) return value;

  value = value.trim();
  return value.length >= max ? value : `0${padZeros(value, max - 1)}`;
}

export function notNullOrUndefined(value: string, def: string): string {
  if (isNullOrUndefined(value)) return def;
  return value;
}

/**
 * Replace all special keywords/escape tokens such as '.' and '*' in the given string with their
 * equivalent escaped string in a regular expression, such as '\.' and '\*'.
 *
 * @param {string} str the string to replace special tokens in
 * @returns {string} the string to be used as a regular expression
 */
export function replaceStringForRegexp(str: string): string {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}
