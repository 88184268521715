import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppVersionInfo } from '../../domain/models';
import * as fromRoot from '../../ngrx/reducers';

@Component({
  selector: 'app-ncs-version-info',
  templateUrl: './version-info-dialog.component.html',
})
export class VersionInfoDialogComponent implements OnDestroy {
  @Input() visible: boolean = false;
  @Output() versionDialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  appVersionInfo: AppVersionInfo;
  private appVersionSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    @Inject('wndw') public wndw: Window,
  ) {
    this.appVersionSubscription = this.store.select('app', 'version').subscribe(versionInfo => {
      this.appVersionInfo = versionInfo;
    });
  }

  ngOnDestroy(): void {
    this.appVersionSubscription.unsubscribe();
  }

  onClose(): void {
    this.visible = false;
    this.versionDialogClose.emit();
  }
}
