import { Component, ViewChild } from '@angular/core';
import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';
import { CheckBoxData } from '../models/enums';

@Component({
  selector: 'boolean-inline-cell-editor-component',
  templateUrl: 'boolean-inline-cell-editor.component.html',
})
export class BooleanInlineCellEditorComponent implements ICellEditorComp {
  @ViewChild('booleanCellEditorDiv') input: any;

  protected editable: boolean = true;
  public value: boolean = false;
  protected label: string;

  protected data: CheckBoxData;

  init(params: ICellEditorParams): void {
    this.data = params.value;
    this.value = this.data.value;
    this.editable = this.data.editable;
    this.label = this.data.label;
  }

  agInit(params: ICellEditorParams): void {
    this.data = params.value;
    this.value = this.data.value;
    this.editable = this.data.editable;
    this.label = this.data.label;
  }

  getValue(): any {
    return this.value;
  }

  onItemFieldChange(event: any): void {
    if (event) {
      this.value = event.value;
    }
    this.value = event.value;
  }

  getGui(): HTMLElement {
    return this.input;
  }
}
