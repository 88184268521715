import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Column } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { isNullOrUndefined, nullsafe } from '../../../modules/utils/object-utils';

export interface ColumnVisibility {
  column: Column;
  visible: boolean;
}

export interface DefaultColumnVisibility {
  [colId: string]: boolean;
}

export interface DisabledGridViewColumns {
  [colId: string]: { disabled: boolean; checked: boolean };
}

@Component({
  selector: 'ncs-customize-table-overlay',
  templateUrl: './customize-table-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeTableOverlayComponent implements OnChanges {
  @Input() allColumns: Column[]; // from gridOptions
  @Input('currentColumns') columnStates: ColumnState[]; // from gridOptions[ColumnApi]
  @Input() defaultVisibility: DefaultColumnVisibility;
  @Input() visible: boolean = false;
  @Input() disabledColumns: DisabledGridViewColumns[] = [];

  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() apply: EventEmitter<ColumnVisibility[]> = new EventEmitter();

  visibleFinally: boolean = false;

  currentColVisibility: ColumnVisibility[] = [];
  availableColumns: ColumnVisibility[] = [];
  columnsChanges = false;

  constructor(
    @Inject('wndw') private wndw: Window,
    private cd: ChangeDetectorRef,
  ) {}

  // WARNING: use changeDetectionStrategy.OnPush on root component for not bucle
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      if (changes.visible.currentValue) {
        // we become true
        this.visibleFinally = false; // yes, indeed - b/c we need to layout first, so the next cycle can center us
        this.wndw.setTimeout(() => {
          this.visibleFinally = true;
          this.cd.markForCheck();
        });
      } else {
        this.visibleFinally = false; // can hide immediately
      }
    }
    if (changes.columnStates)
      if (!this.visibleFinally && (changes.columnStates.currentValue || []).length) {
        const currentCols = nullsafe(this.allColumns)
          .filter(c => !c.getColDef().pinned && c.getColDef().headerName !== '' && c.isVisible())
          .map(c => ({ column: c, visible: false }));
        this.currentColVisibility = this.onGetOrderedColumns(currentCols);
        this.availableColumns = nullsafe(this.allColumns)
          .filter(c => !c.getColDef().pinned && c.getColDef().headerName !== '' && !c.isVisible())
          .map(c => ({ column: c, visible: false }));
      }
  }

  onGetOrderedColumns(cols: ColumnVisibility[]): ColumnVisibility[] {
    // NOTE: The length of ColumnVisibility is less than ColumnState[] because the first one contains only visible columns
    // and other one is all defined columns
    return (this.columnStates || [])
      .filter(item => !item.pinned && !item.hide)
      .map(colItem => (cols || []).find((item: ColumnVisibility) => item.column.getColId() === colItem.colId));
  }

  public onSetAsDefaultView(): void {
    const allColumns = nullsafe(this.allColumns)
      .filter(c => !c.getColDef().pinned || c.getColDef().headerName !== '')
      .map(c => ({ column: c, visible: false }));
    const noDefaultColumns: ColumnVisibility[] = [];
    this.currentColVisibility = allColumns.filter(cv => {
      if (isNullOrUndefined(this.defaultVisibility[cv.column.getColId()])) noDefaultColumns.push(cv);
      return !isNullOrUndefined(this.defaultVisibility[cv.column.getColId()]);
    });
    this.availableColumns = noDefaultColumns;
    this.columnsChanges = true;
  }

  public onApplyChanges(): void {
    const newVisibilityColumns = [...this.currentColVisibility];
    // setting visible property to true because need makes a comparison with current ColumnVisibility
    if (this.columnsChanges) {
      this.currentColVisibility.map(col => (col.visible = true));
      // In addition, if user to check any checkbox item from allAvailableColumns and apply changes
      // it'll cause conflict to concat current and allColumns sampleThirdPartyTypes
      // because only currentColumns sampleThirdPartyTypes should be visible in true
      this.availableColumns.map(col => (col.visible = false));
      if (this.availableColumns.length > 0) newVisibilityColumns.push(...this.availableColumns);
    }
    // Emitting data if don´t changes too. Avoid crashed grid views logic
    this.apply.emit(newVisibilityColumns);
    this.columnsChanges = false;
  }

  public onCancel(): void {
    this.columnsChanges = false;
    this.cancel.emit();
  }

  isColumnValid = (column: ColumnVisibility): ColumnVisibility => column;
}
