import { isNumber as isNumeric } from 'lodash';
import { isNullOrUndefined } from './object-utils';

export function roundToTwo(numberToRound: number): number {
  if (isNullOrUndefined(numberToRound)) return numberToRound;
  return Number(`${Math.round(Number(`${String(numberToRound)}e+2`))}e-2`);
}

export function isNumber(numberCheck: any): boolean {
  if (!isNullOrUndefined(numberCheck)) {
    return isNumeric(numberCheck);
  }
  return false;
}
