import { Injectable } from '@angular/core';

const enUS = 'en-US';

function isUs(): boolean {
  return this.getLanguage() === enUS;
}

@Injectable()
export class Constants {
  public readonly REDIRECT_TO = 'redirect';
  public readonly API_URL: string = '/api/';
  public readonly TRANSLATIONS_URL: string = 'translations/';
  public readonly LANG_QUERY_URL: string = '?lang=';
  public readonly SERVER_WITH_QC_API_URL = `${this.API_URL}qc/`;
  public readonly SERVER_WITH_AUTH_API_URL = `${this.API_URL}auth/`;
  public readonly UI_LANGUAGE = '__ui_language__';
  public readonly DEFAULT_UI_LANGUAGE = enUS;
  public readonly SAMPLE_REJECTED_CODE = 'SASRJT';
  public readonly SAMPLE_PENDING_CODE = 'SASPDG';

  // DEFAULT DATE FORMAT
  public static getLanguage(): string {
    return window.localStorage.getItem('__ui_language__') || enUS;
  }

  public static getDateFormatShortTime(): string {
    return isUs.call(this) ? 'MM.dd.yyyy HH:MM' : 'dd.MM.yyyy HH:MM';
  }

  /** Used for templates e.g., 13.06.2023 */
  public static getDateFormatShort(): string {
    return isUs.call(this) ? 'MM.dd.yyyy' : 'dd.MM.yyyy';
  }

  /** Used for components e.g., 13.06.2023 */
  public static getDateFormat(): string {
    return isUs.call(this) ? 'mm.dd.yy' : 'dd.mm.yy';
  }

  public static getNcsFormatDateTimeShort(): string {
    return isUs.call(this) ? 'M.d.yy, H:MM' : 'd.M.yy, H:MM';
  }
}
