<div class="grid col-12">
  <div class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-12">
    <ncs-lookupSearch-basic
      [label]="'common.labels.system_id'"
      [editable]="editMode"
      [btnMagnifier]="true"
      [multiple]="true"
      [paginationResponse]="samplePagination"
      [value]="sampleSelections"
      [colDefinition]="sampleColDefinition"
      [titleGrid]="'common.labels.system_id' | translate"
      [fieldReadOnly]="'sample'"
      [validationResult]="validationResult?.fieldResults?.cuppingSessionSamples"
      [suggestions]="sampleSuggestions"
      (onblur)="onItemFieldBlur('cuppingSessionSamples')"
      (onCompleteMethod)="loadSampleSuggestions($event)"
      (onRemoved)="onRemoveSamples($event)"
      (onSelected)="onSelectSamples($event)">
    </ncs-lookupSearch-basic>
  </div>
  <div class="col-12">
    <nkg-generic-grid
      [id]="'Sample'"
      [wrappedInCard]="false"
      [agGridStyle]="{ width: '100%', height: '250px' }"
      [rowData]="cuppingSessionSamplesRowData"
      [colDef]="columnDefsSamples"
      [sizeToFit]="true"
      [gridOptions]="gridOptSamples"
      [rowId]="'Sample'"
      (onLeaveDragDrop)="refreshData()"
      (rowDragEnd)="rowEndEvent()">
    </nkg-generic-grid>
  </div>
</div>
