import { createAction, props } from '@ngrx/store';
import {
  ApprovalStatusLog,
  CoffeeCertification,
  ComtrasStatus,
  CuppingProcess,
  PackageType,
  PaginationRequestParams,
  ReferenceType,
  Sample,
  SampleApprovalInternalLog,
  SampleStatus,
  SampleType,
} from '../../domain/models';

export enum SampleAction {
  FETCH_SAMPLE = '[Sample ] Fetch Sample ',
  FETCH_SAMPLE_SUCCESS = '[Sample] Fetch Sample Success',
  GET_LAST_APPROVAL_STATUS_LOG = '[Sample] Get Last Approval Status Log',
  GET_LAST_APPROVAL_STATUS_LOG_SUCCESS = '[Dispatch] Get Last Approval Status Log Success',
  GET_SAMPLE_APPROVAL_INTERNAL_LOGS = '[Sample] Get Sample Approval Internal Logs',
  GET_SAMPLE_APPROVAL_INTERNAL_LOGS_SUCCESS = '[Sample] Get Sample Approval Internal Logs Success',
  FETCH_SAMPLE_STATUS = '[Sample] Fetch Sample Status',
  FETCH_SAMPLE_STATUS_SUCCESS = '[Sample] Fetch Sample Status Success',
  FETCH_REFERENCE_TYPE = '[Sample] Request Reference Type',
  FETCH_REFERENCE_TYPE_SUCCESS = '[Sample] Request Reference Type Success',
  FETCH_PACKAGE_TYPE = '[Sample] Request Package Type',
  FETCH_PACKAGE_TYPE_SUCCESS = '[Sample] Request Package Type Success',
  FETCH_COFFEE_CERTS = '[Sample] Fetch Coffee Certifications',
  FETCH_COFFEE_CERTS_SUCCESS = '[Sample] Fetch Coffee Certifications Success',
  FETCH_SAMPLE_TYPE = '[SampleType] Request Sample Type',
  FETCH_SAMPLE_TYPE_SUCCESS = '[SampleType] Request Sample Type Success',
  FETCH_SAMPLE_TYPE_PER_TENANT = '[SampleType] Request Sample Type For Current Tenant',
  FETCH_SAMPLE_TYPE_PER_TENANT_SUCCESS = '[SampleType] Request Sample Type For Current Tenant Success',
  GET_CUPPING_PROCESS_AVERAGE = '[CuppingProcess] Get CuppingProcess Average',
  GET_CUPPING_PROCESS_AVERAGE_SUCCESS = '[CuppingProcess] Get CuppingProcess Average Success',
  GET_COMTRAS_STATUS_ACTION = '[Sample] Verify the comtras instances status',
  GET_COMTRAS_STATUS_ACTION_SUCCESSFULL = '[Sample] Comtras status response action',
}

export const fetchSample = createAction(SampleAction.FETCH_SAMPLE);

export const fetchSampleSuccess = createAction(SampleAction.FETCH_SAMPLE_SUCCESS, props<{ samples: Sample[] }>());

export const getLastApprovalStatusLog = createAction(
  SampleAction.GET_LAST_APPROVAL_STATUS_LOG,
  props<{ dispatchId: number }>(),
);

export const getLastApprovalStatusLogSuccess = createAction(
  SampleAction.GET_LAST_APPROVAL_STATUS_LOG_SUCCESS,
  props<{ statusLog: ApprovalStatusLog }>(),
);

export const getSampleApprovalInternalLogs = createAction(
  SampleAction.GET_SAMPLE_APPROVAL_INTERNAL_LOGS,
  props<{ sampleId: number }>(),
);

export const getSampleApprovalInternalLogsSuccess = createAction(
  SampleAction.GET_SAMPLE_APPROVAL_INTERNAL_LOGS_SUCCESS,
  props<{ sampleApprovalInternalLogs: SampleApprovalInternalLog[] }>(),
);

export const fetchSampleStatus = createAction(SampleAction.FETCH_SAMPLE_STATUS);

export const fetchSampleStatusSuccess = createAction(
  SampleAction.FETCH_SAMPLE_STATUS_SUCCESS,
  props<{ samplesStatuses: SampleStatus[] }>(),
);

export const fetchReferenceType = createAction(
  SampleAction.FETCH_REFERENCE_TYPE,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchReferenceTypeSuccess = createAction(
  SampleAction.FETCH_REFERENCE_TYPE_SUCCESS,
  props<{ referenceTypes: ReferenceType[] }>(),
);

export const fetchPackageType = createAction(
  SampleAction.FETCH_PACKAGE_TYPE,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchPackageTypeSuccess = createAction(
  SampleAction.FETCH_PACKAGE_TYPE_SUCCESS,
  props<{ packageType: PackageType[] }>(),
);

export const fetchCoffeeCertifications = createAction(
  SampleAction.FETCH_COFFEE_CERTS,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchCoffeeCertificationsSuccess = createAction(
  SampleAction.FETCH_COFFEE_CERTS_SUCCESS,
  props<{ coffeeCerts: CoffeeCertification[] }>(),
);

export const fetchSampleType = createAction(
  SampleAction.FETCH_SAMPLE_TYPE,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchSampleTypeSuccess = createAction(
  SampleAction.FETCH_SAMPLE_TYPE_SUCCESS,
  props<{ sampleType: SampleType[] }>(),
);

export const fetchSampleTypesForTenant = createAction(
  SampleAction.FETCH_SAMPLE_TYPE_PER_TENANT,
  props<{ paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchSampleTypesForTenantSuccess = createAction(
  SampleAction.FETCH_SAMPLE_TYPE_PER_TENANT_SUCCESS,
  props<{ sampleTypes: SampleType[] }>(),
);

export const getSampleAnalysisAverage = createAction(
  SampleAction.GET_CUPPING_PROCESS_AVERAGE,
  props<{ averagesIds: number[] }>(),
);

export const getSampleAnalysisAverageSuccess = createAction(
  SampleAction.GET_CUPPING_PROCESS_AVERAGE_SUCCESS,
  props<{ cuppingProcess: CuppingProcess }>(),
);

export const getCOMTRASStatusAction = createAction(SampleAction.GET_COMTRAS_STATUS_ACTION);

export const getCOMTRASStatusActionSuccess = createAction(
  SampleAction.GET_COMTRAS_STATUS_ACTION_SUCCESSFULL,
  props<{ status: ComtrasStatus }>(),
);
