import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractVersionedEntity } from '../../../../core/domain/models';

@Component({
  selector: 'item-td',
  template: `
    <button
      pButton
      type="button"
      [title]="title | translate"
      class="p-button-raised p-button-secondary"
      icon="{{ icon }}"
      style="font-size: 10px; background: darkgreen"
      (click)="onClick.emit(dataItem?.id)"></button>
  `,
})
export class CuppingProcessReportBtnComponent {
  @Input() dataItem: AbstractVersionedEntity;
  @Input() icon: string = 'pi pi-file';
  @Input() title: string = 'common.labels.report';
  @Output() onClick: EventEmitter<number> = new EventEmitter<number>();
}
