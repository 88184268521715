import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GridOptions } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { isEqual } from 'lodash';
import { GridViewNameReference } from '../views/view-type';
import { fetchGridViews, saveGridView, setGridView } from '../../../core/ngrx/actions';
import { GridView } from '../../../core/domain/models';
import {
  ColumnVisibility,
  DefaultColumnVisibility,
  DisabledGridViewColumns,
} from '../customize-table-overlay/customize-table-overlay.component';
import * as fromRoot from '../../../core/ngrx/reducers';
import { isNullOrUndefined, mergeImmutable, nullsafe } from '../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../modules/utils/string-utils';
import { NcsSelectBoxComponent } from '../../basic-shared-module/components/ncs-select-box/ncs-select-box.component';
import { CustomGridView } from '../models/enums';

@Component({
  selector: 'grid-views',
  templateUrl: './grid-views.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridViewsComponent implements OnInit, OnChanges, OnDestroy {
  /** master grid view name reference and defined on each master data */
  @Input() gridViewNameReference: GridViewNameReference;
  /** Columns disabled in each view and cannot remove */
  @Input() disabledColumns: DisabledGridViewColumns[] = [];
  /** Columns visible in each grid view */
  @Input() defaultVisibility: DefaultColumnVisibility = {};
  /** Grid properties and used by getting filters and sort models for grid Views */
  @Input() gridOptions: GridOptions;
  /** used when column is defined in a dynamic way.
   * Once the column is defined takes times for to effect and necessary to detect the column state */
  @Input() columnsState: ColumnState[];
  /** Emmit gridOptions changes through any grid view selection */
  @Output() onGridOptionChange: EventEmitter<GridOptions> = new EventEmitter<GridOptions>();
  /** Activate focus when launched dialog */
  @ViewChild('gridViewTextBox') set content(content: ElementRef) {
    if (content) {
      this.viewNameTextBox = content;
    }
  }
  /** force selection on initial view */
  @ViewChild('boxComponent') boxComponent: NcsSelectBoxComponent;

  private viewNameTextBox: ElementRef; // Native input element used for gets grid view name typed
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private gridViewsStored: GridView[]; // Grid View from db
  private gridViewReferenceStored: string; // used to fetch new grid View data when selected other master-data
  private defaultSorting: any; // Recovery default master sort model
  private defaultFilter: any; // Recovery default master filter model

  public customGridViewsOptions: CustomGridView[] = []; // user grid Options saved
  public currentGridViewOption: CustomGridView; // current grid view
  public showCustomizeOverlay: boolean = false; // enable columns selection dialog
  public gridViewName: string = ''; // grid view name and used when user requires saving any view
  public showSaveConfirmDialog: boolean = false; // enable confirmation dialog

  constructor(
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.gridViewsProps();
  }

  ngOnDestroy(): void {
    // Reset grid by default columns visibility and occurs when view mode is set to split view mode
    // Consider a case when master-data is changed, master state is set to initial values
    this.store.dispatch(setGridView({ gridView: null, yesTranslated: '' }));
    this.currentGridViewOption = null;
    this.onResetGrid();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Case 1: Save initial values of the master-data grid
    if (changes.gridOptions && !isNullOrUndefined(this.gridOptions?.api)) {
      // Save the initial basic properties of the grid because the master data grid might change display mode
      // and needs to recover the initial mode when returning to split mode.
      this.defaultSorting = this.gridOptions.api.getSortModel();
      this.defaultFilter = this.gridOptions.api.getFilterModel();
    }
    // CASE 2: Master-data detection changes
    if (changes.gridViewNameReference) {
      // When gridViewNameReference changes, it means that current master-data is changed
      if (isNotEmpty(this.gridViewNameReference)) {
        setTimeout(() => {
          // Create initial gridView
          const gridViewOption = this.createGridView(
            this.createCustomGridViewsOptions(
              this.translateService.instant('masterdata.list.labels.standard_view'),
              false,
              true, // this is set to true, because is saved immediately after being created
              false,
            ),
            true,
          );
          // Fetching all grid views and set initial current gridView.
          // If grid views for master are empty, then save initial gridView
          this.store.dispatch(
            fetchGridViews({ gridViewReference: this.gridViewNameReference, initialGridView: gridViewOption }),
          );
        });
      }
    }
  }

  /** Set current grid view when grid sorting or grid filter is changed
   * @param gridSortingModel AgGrid sorting Model
   * @param gridFilterModel AgGrid filter Model */
  public onFilterByGrid(gridSortingModel: any, gridFilterModel: any): void {
    if (!isNullOrUndefined(this.currentGridViewOption)) {
      const filterChanged: boolean = !isEqual(gridFilterModel, this.currentGridViewOption.filter);
      const sortChanged: boolean = !isEqual(gridSortingModel, this.currentGridViewOption.sorting);
      if (filterChanged || sortChanged) this.onSetCurrentGridView();
    }
  }

  private onSetCurrentGridView(): void {
    // Set currentGridView because when page changes, rowData changes to empty and clean all data on agGrid
    // For that reason it's setting current grid data to currentGridView and recovery it after rowData changes with any value
    if (!isNullOrUndefined(this.currentGridViewOption)) {
      this.currentGridViewOption.sorting = this.gridOptions.api.getSortModel();
      this.currentGridViewOption.filter = this.gridOptions.api.getFilterModel();
      this.currentGridViewOption.isSaved = false;
      this.store.dispatch(
        setGridView({
          gridView: this.currentGridViewOption,
          yesTranslated: this.translateService.instant('common.labels.yes'),
        }),
      );
    }
  }

  private onGridViewNameTypingValidation(): void {
    // grouping target values for any time and send via output
    const term$ = fromEvent<any>(this.viewNameTextBox.nativeElement, 'keyup').pipe(
      map(event => event),
      startWith({ target: { value: this.gridViewName } }),
      debounceTime(400),
      distinctUntilChanged(),
    );
    term$.pipe(takeUntil(this.destroy$)).subscribe(query => {
      if (isNotEmpty(query.target.value)) this.gridViewName = query.target.value;
    });
  }

  /** When back to split mode, gridViews don't present on masters and must be back default values (default columns visibility) */
  private onResetGrid(): void {
    if (!isNullOrUndefined(this.gridOptions?.columnApi)) {
      const originalView = this.gridOptions.columnApi.getAllColumns();
      originalView.map(cs => {
        return cs.setVisible(!!this.defaultVisibility[cs.getColId()]);
      });
      this.gridOptions.api.setSortModel(this.defaultSorting);
      this.gridOptions.api.setFilterModel(this.defaultFilter);
    }
  }

  private gridViewsProps(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentGridView),
      this.store.select(fromRoot.getAllGridViews),
      this.store.select(fromRoot.getGridViewReference),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentGridView, allGridViews, gridViewReference]) => {
        const resetViews = this.gridViewReferenceStored === gridViewReference;
        this.gridViewReferenceStored = gridViewReference;
        this.currentGridViewOption = currentGridView;
        // CASE 1: Changed master-data
        if (
          (allGridViews || []).length &&
          // Check if comparison is better with isEqual from lodash
          JSON.stringify(allGridViews) !== JSON.stringify(this.gridViewsStored) &&
          !isNullOrUndefined(this.gridOptions) &&
          resetViews
        ) {
          this.gridViewsStored = allGridViews;
          this.setGridViewsStored(this.gridViewsStored, currentGridView);
        }
        // CASE 2: Maybe added newGridView
        if (!isNullOrUndefined(currentGridView) && (allGridViews || []).length)
          this.settingCurrentValue(currentGridView);
      });
  }

  /** setting initial view selection on init any master */
  private settingCurrentValue(view: CustomGridView): void {
    if (this.boxComponent) {
      this.boxComponent.value = view;
      this.currentGridViewOption = view;
      this.cd.markForCheck();
    }
  }

  /** Find and set current grid view */
  private setGridViewsStored(gridViews: GridView[], currentGridView: CustomGridView): void {
    if (nullsafe(gridViews).length > 0) {
      this.customGridViewsOptions = gridViews.map((view: GridView) => {
        const value = JSON.parse(view.value);
        value.id = view.id;
        return value;
      });

      // First time currentGridView is null and need to find default gridView from all gridViews stored
      // Consider moving at master.reducer when fetchViewSuccess action is emitted
      const defaultGridViewStored = gridViews.find((view: GridView) => view.isDefault);
      const defaultGridViewCustomizable: CustomGridView = JSON.parse(defaultGridViewStored.value);
      defaultGridViewCustomizable.id = defaultGridViewStored.id;

      const currentTableOption = currentGridView || defaultGridViewCustomizable;
      if (!isNullOrUndefined(currentTableOption)) {
        this.store.dispatch(
          setGridView({
            gridView: currentTableOption,
            yesTranslated: this.translateService.instant('common.labels.yes'),
          }),
        );
      }
    }
    this.cd.markForCheck();
  }

  /** Apply additions or deletes columns changes on master grid selected by user */
  public onApplyColumnVisibility(colsVis: ColumnVisibility[]): void {
    this.buildCurrentGridViewOption(colsVis);
    this.showCustomizeOverlay = false;
  }

  /**
   * * Update current grid view with new columns state
   * @param colsVis {ColumnVisibility} columns updates
   */
  private buildCurrentGridViewOption(colsVis: ColumnVisibility[]): void {
    this.currentGridViewOption.isSaved = false;
    this.currentGridViewOption = this.updateColumnsState(this.currentGridViewOption, colsVis);
    this.store.dispatch(
      setGridView({
        gridView: this.currentGridViewOption,
        yesTranslated: this.translateService.instant('common.labels.yes'),
      }),
    );
  }

  /** Apply update columns visibility */
  private updateColumnsState(currentGridView: CustomGridView, colsVis: ColumnVisibility[] = []): CustomGridView {
    const sortableColumnsByUser = [];
    colsVis.forEach(cv => {
      currentGridView.viewsDefaultColumnState.map(cs => {
        if (cs.colId == cv.column.getColId()) {
          cs.hide = !cv.visible;
          sortableColumnsByUser.push(cs);
        }
        return cs;
      });
    });
    currentGridView.viewsDefaultColumnState = sortableColumnsByUser;
    return currentGridView;
  }

  /**
   * Setting a new current grid view option
   * @param selectedView {CustomGridView}
   */
  public onGridViewChanged(selectedView: CustomGridView = this.currentGridViewOption): void {
    // [QC-146] each time selected any view it'll be default view
    // Conflict master searches: If master search applied, remove all filter and
    if (isNotEmpty(this.gridViewNameReference)) {
      // Dispatch pagination changes and searches con master component
      if (selectedView.isSaved) {
        const currentGridView = this.gridViewsStored.find((gView: GridView) => gView.title === selectedView.name);
        currentGridView.isDefault = true;
        this.store.dispatch(
          saveGridView({
            gridView: currentGridView,
            gridViewReference: this.gridViewNameReference,
            forceRefresh: true,
          }),
        );
      } else {
        // QC-146 if not saved, switch to tempView
        this.store.dispatch(
          setGridView({ gridView: selectedView, yesTranslated: this.translateService.instant('common.labels.yes') }),
        );
      }
    }
  }

  /** Enable current grid view-saving confirmation dialog */
  public openSaveConfirmDialog(): void {
    this.gridViewName = this.currentGridViewOption?.name;
    this.showSaveConfirmDialog = true;
    setTimeout(() => this.onGridViewNameTypingValidation());
  }

  public saveViewDialogConfirmed(): void {
    if (this.isViewNameValid()) {
      const tableOptionsExists = this.gridViewsStored.find(x => x.title === this.gridViewName);
      let currentGridView: GridView;
      if (isNullOrUndefined(tableOptionsExists) && this.gridViewName.length != 0) {
        const tempTableOption = this.createCustomGridViewsOptions(this.gridViewName, true, true, false);
        currentGridView = this.createGridView(tempTableOption, true);
      } else {
        const tempTableOption = this.createCustomGridViewsOptions(
          this.currentGridViewOption.name,
          this.currentGridViewOption.isCustomView,
          true,
          false,
        );
        const gridView: GridView = this.gridViewsStored.find(x => x.title === this.gridViewName);
        currentGridView = mergeImmutable(gridView, {
          value: JSON.stringify(tempTableOption),
          isDefault: true,
        });
      }
      this.store.dispatch(saveGridView({ gridView: currentGridView, gridViewReference: this.gridViewNameReference }));
      this.showSaveConfirmDialog = false;
    }
  }

  /** Create Grid view properties for saving */
  private createCustomGridViewsOptions(
    name: string,
    isCustom: boolean,
    isSaved: boolean,
    isTemp: boolean,
  ): CustomGridView {
    return {
      id: -1,
      name: name,
      isTemp: isTemp,
      // if it uses this, check data don't empty or undefined because don't display columns on grid
      viewsDefaultColumnState: this.gridOptions?.columnApi?.getColumnState() || undefined,
      isCustomView: isCustom,
      isSaved: isSaved,
      filter: this.gridOptions?.api?.getFilterModel() || undefined,
      sorting: this.gridOptions?.api?.getSortModel() || undefined,
      filterState: undefined,
      parentView: undefined,
    };
  }

  private createGridView(tableOption: CustomGridView, isDefaultView: boolean = false): GridView {
    const obj = new GridView();
    obj.title = tableOption.name;
    obj.value = JSON.stringify(tableOption);
    obj.tableId = this.gridViewNameReference;
    obj.isDefault = isDefaultView;
    return obj;
  }

  public onCancelSaveView(): void {
    this.showSaveConfirmDialog = false;
  }

  public isViewNameValid(): boolean {
    const validator = /^[a-zA-Z0-9 _]*$/;
    return validator.test(this.gridViewName);
  }
}
