import { Component } from '@angular/core';

@Component({
  selector: 'nkg-toolbar',
  templateUrl: './toolbar.component.html',
})
export class NkgToolbar {
  xpand: boolean = true;
  animatingOut: boolean = false;
  animatingIn: boolean = false;
  runningTimeout: any;

  public onExpandBottombar(): void {
    if (this.xpand) {
      this.xpand = false;
      this.animatingOut = true;
    } else {
      this.xpand = true;
      this.animatingIn = true;
    }
    if (this.runningTimeout) {
      clearTimeout(this.runningTimeout);
    }
    this.runningTimeout = setTimeout(() => {
      this.animatingOut = false;
      this.animatingIn = false;
    }, 750);
  }
}
