import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../../../core/ngrx/reducers';
import { fetchStandardDefinition } from '../../../core/ngrx/actions';
import { waitingForUser } from './waiting-for-user.guard';

export const StandardDefinitionGuard: CanActivateFn = () => {
  const store = inject(Store<fromRoot.State>);
  return waitingForUser().pipe(
    map(currentUser => {
      store.dispatch(fetchStandardDefinition({ userDefaultTenantId: currentUser?.defaultTenant?.id }));
      return true;
    }),
    take(1),
  );
};
