import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeService } from '../../utils/time-service';
import { AuthorizationService } from './authorization.service';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { AppTimezoneInfo, AppVersionInfo, Language } from '../../../core/domain/models';
import { convertDate } from '../../../shared/basic-shared-module/utils/date-utils';
import { Constants } from '../../../core/utils/app.constants';

@Injectable()
export class AppMetaDataService extends AbstractCeisService {
  private qcActionUrl: string;
  private authActionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    private authService: AuthorizationService,
  ) {
    super(http, configuration, timeService);
    this.qcActionUrl = `${configuration.SERVER_WITH_QC_API_URL}rest/application`;
    this.authActionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}/administration/`;
  }

  /**
   * loads versions unauthorized, so they can be displayed in login screen
   */
  public loadAppVersionInfo(): Promise<AppVersionInfo> {
    return this.getWithParams(`${this.qcActionUrl}/versioninfo`, undefined, true, () =>
      this.authService.renewToken(),
    ).then((res: any) => {
      return Object.assign(new AppVersionInfo(), res, {
        deployTimestamp: convertDate(res.deployTimestamp),
      });
    });
  }

  /** m
   * Loads the timezone that is configured to be in sync with the server.
   */
  public loadTimezone(): Promise<AppTimezoneInfo> {
    const appTimeZone = new AppTimezoneInfo();
    appTimeZone.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return Promise.resolve(appTimeZone);
  }

  public loadLanguages(): Promise<Language[]> {
    return this.getWithParams(`${this.authActionUrl}/languages`, undefined, true, () =>
      this.authService.renewToken(),
    ).then((res: Language[]) => {
      return res;
    });
  }
}
