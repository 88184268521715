import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NcsBaseBasicComponent } from '../base-basic/base-basic.component';

@Component({
  selector: 'ncs-button-basic',
  templateUrl: './button-basic.component.html',
})
export class NcsButtonBasicComponent extends NcsBaseBasicComponent {
  @Input() icon: string; // PrimeIcons or FontAwesome.
  /** overwrite parent inputStyle */
  @Input() inputStyle: any = undefined;

  @Output() onClicked: EventEmitter<any> = new EventEmitter();

  public onClick(event: any): void {
    this.onClicked.emit(event);
  }
}
