import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { AbstractCeisService } from '../../../../../core/service/app.abstract.service';
import { Constants } from '../../../../../core/utils/app.constants';
import { AuthorizationService } from '../../../../authorization/services/authorization.service';
import { TimeService } from '../../../../utils/time-service';
import {
  Country,
  CupEvaluator,
  CuppingProcessDefaultOption,
  CuppingSession,
  CuppingSessionSample,
  EmailConfirmation,
  GuestEvaluatorDto,
  MetricsDefinitionType,
  PaginatedResponse,
  PaginationRequestParams,
} from '../../../../../core/domain/models';
import { hasOwnProperty, isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../utils/object-utils';
import { QueryService } from '../../../../services/query.service';
import { Rule, Validation } from '../../../services/master-validation';
import { NkgGridFactory } from '../../../../../shared/grid/services/grid.factory';
import { BaseMasterGridCol } from '../../../models/interfaces';
import { ColDefMap } from '../../../../../shared/search/models/enums';

@Injectable({
  providedIn: 'root',
})
export class CuppingSessionService extends AbstractCeisService {
  private readonly actionUrl: string;
  private readonly actionUrl2: string;
  private readonly actionUrl3: string;
  private readonly langQueryUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
    private queryService: QueryService,
    private translateService: TranslateService,
    public gf: NkgGridFactory,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_QC_API_URL}cupping_session`;
    this.actionUrl2 = `${configuration.SERVER_WITH_QC_API_URL}cup_evaluator`;
    this.actionUrl3 = `${configuration.SERVER_WITH_AUTH_API_URL}user`;
    this.langQueryUrl = configuration.LANG_QUERY_URL;
  }

  public async loadCuppingSessions(year: number, month: number): Promise<CuppingSession[]> {
    // return this.getWithParams(this.actionUrl + '/event_date/' + year + '/' + month, {deleted: false, _include:'status,standardDefinition,laboratory,cupEvaluator,samples,cuppingSessionCupEvaluators(cupEvaluator),metricsDefinitionTypes'}, true, () => this.authService.renewToken()).then((res: any[]) => {
    const res = await this.getWithParams<CuppingSession[]>(
      `${this.actionUrl}/event_date/${year}/${month}`,
      {
        deleted: false,
        _include: '_',
      },
      true,
      () => this.authService.renewToken(),
    );
    return <CuppingSession[]>nullsafe(res);
  }

  public async loadCupEvaluatorByTenant(
    idTenant?: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<CupEvaluator[]> {
    // WARN: if size property from paginationRequestParams is null, backend response is with clean array items [root property],
    // otherwise the backend response is with PaginatedResponse and should filter on root function
    const res = await this.getWithParams<PaginatedResponse>(
      `${this.actionUrl2}/${idTenant}/by_tenant`,
      paginationRequestParams || new PaginationRequestParams(),
      true,
      () => this.authService.renewToken(),
    );
    return nullsafe(res?.items);
  }

  public async getCuppingSessionById(cuppingSessionId: number, _include?: string): Promise<CuppingSession> {
    // _include = _include || "*,cuppingSessionSamples(sample),standardDefinition(standardDefinitionOptionScoreDescriptions,standardDefinitionOptionPositions(standardDefinitionOptions(metricsDefinition(metricsDefinitionOptions,metricsDefinitionType,metricsDefinitionTypeResult))),standardsDefinitionEquivalences(metricsDefinitionOption))"
    _include =
      _include ||
      'status,standardDefinition,laboratory,cupEvaluator,cuppingSessionSamples(sample),cuppingSessionCupEvaluators(cupEvaluator(cupEvaluatorUser)),metricsDefinitionTypes,cuppingProcesses(sample,laboratory,metricsDefinitionType,cupEvaluator,standardDefinition)';
    const cuppingSession = await this.getWithParams(
      `${this.actionUrl}/${cuppingSessionId}`,
      {
        deleted: false,
        _include: _include,
      },
      true,
      () => this.authService.renewToken(),
    );
    return <CuppingSession>cuppingSession;
  }

  public async loadCuppingProcessDefaultOption(): Promise<CuppingProcessDefaultOption> {
    const res = await this.get(`${this.actionUrl3}/cupping_process_default_option/`, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  public async saveCuppingProcessDefaultOption(
    cuppingProcessDefault: CuppingProcessDefaultOption,
  ): Promise<CuppingProcessDefaultOption> {
    cuppingProcessDefault = this.queryService.toServer(cuppingProcessDefault);
    const tokenRenew = (): Promise<boolean> => this.authService.renewToken();
    const resp = await (cuppingProcessDefault.id > 0
      ? this.put(`${this.actionUrl3}/cupping_process_default_option/`, cuppingProcessDefault, true, tokenRenew)
      : this.post(`${this.actionUrl3}/cupping_process_default_option/`, cuppingProcessDefault, true, tokenRenew));
    return this.queryService.fromServer(resp);
  }

  public async sendCuppingSessionInvitation(id: number): Promise<EmailConfirmation> {
    if (id && id >= 0) {
      const url: string = `${this.actionUrl}/${id}/invite${this.langQueryUrl}${this.getLanguage()}`;
      const res = await this.get<EmailConfirmation>(url, true, () => this.authService.renewToken());
      return <EmailConfirmation>res;
    }
    return Promise.resolve(null);
  }

  public async addGuestEvaluatorTo(cuppingSessionId: number, evaluatorDto: GuestEvaluatorDto): Promise<CupEvaluator> {
    if (cuppingSessionId >= 0) {
      const url: string = `${this.actionUrl2}/new_guest/${cuppingSessionId}`;
      const x = await this.post(url, evaluatorDto, true, () => this.renewToken());
      return this.queryService.fromServer(x);
    }

    return Promise.resolve(null);
  }

  public async addGuestEvaluatorsTo(
    cuppingSessionId: number,
    evaluatorsDto: GuestEvaluatorDto[],
  ): Promise<CupEvaluator[]> {
    if (cuppingSessionId >= 0) {
      const url: string = `${this.actionUrl2}/new_guests/${cuppingSessionId}`;
      const x = await this.post(url, evaluatorsDto, true, () => this.renewToken());
      return this.queryService.fromServer(x);
    }
    return Promise.resolve(null);
  }

  loadSampleColDefs(): ColDef[] {
    const gridColDefs: ColDef[] = [];

    gridColDefs.push({
      headerName: '#',
      colId: 'rowNum',
      valueGetter: params => {
        const charCode = 65;
        if (charCode + params.node.rowIndex > 90) {
          const asciiJoin = String.fromCharCode(65);
          return `${asciiJoin}${String.fromCharCode(((charCode + params.node.rowIndex - 65) % 26) + 65)}`;
        }
        return String.fromCharCode(65 + params.node.rowIndex);
      },
      minWidth: 50,
      pinned: 'left',
      rowDrag: true,
      resizable: true,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.system_id'),
      colId: 'sample',
      field: 'sample',
      resizable: true,
      minWidth: 200,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.sample_source'),
      colId: 'sampleSource',
      field: 'sampleSource.sample',
      resizable: true,
      minWidth: 200,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.sample_reference'),
      colId: 'sampleReference',
      field: 'sampleReference',
      resizable: true,
      minWidth: 200,
    });

    return gridColDefs;
  }

  loadCupEvaluatorColDefs(): ColDef[] {
    const gridColDefs: ColDef[] = [];
    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.cup_evaluators'),
      colId: 'cupEvaluator',
      field: 'cupEvaluator.cupEvaluator',
      resizable: true,
      rowDrag: false,
      minWidth: 200,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.valid'),
      colId: 'valid',
      field: 'valid',
      minWidth: 200,
      cellRenderer: params => {
        const input = document.createElement('input');
        input.type = 'checkbox';
        input.checked = params.value;
        input.value = params.value;
        input.disabled = !params.context.getEditMode();
        input.addEventListener('click', function () {
          let newUpdate = params.node.data;
          newUpdate = mergeImmutable(newUpdate, { valid: !params.value });
          params.context.onUpdateCuppingSessionEvaluatorsByAgGrid(newUpdate);
        });
        return input;
      },
      resizable: true,
      editable: params => {
        return params.context.onGetEditMode();
      },
    });

    return gridColDefs;
  }

  loadCuppingMasterColDefs(): BaseMasterGridCol[] {
    const gridColDefs: BaseMasterGridCol[] = [];
    const cellClassRules = {
      allBorders: function (): boolean {
        return true;
      },
    };

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getNumberColDef('common.labels.id', 'data.id')
        .colId('id')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
          pinned: true,
          minWidth: 40,
        })
        .pattern('1.0')
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getDateColDef('common.labels.date', (params: ValueGetterParams) => {
          return hasOwnProperty(params.data, 'cuppingDate') ? params.data.cuppingDate : '';
        })
        .colId('cuppingDate')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
          pinned: true,
          /* minWidth: 70,
                    maxWidth: 80, */
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.id_session', 'data.idSession')
        .colId('idSession')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
          minWidth: 150,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.session', 'data.session')
        .colId('session')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
          minWidth: 150,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.status', (params: ValueGetterParams) => {
          return params.data.status ? params.data.status.name : '';
        })
        .colId('status.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 100,
          maxWidth: 300,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.blind', (params: ValueGetterParams) => {
          return params.data.blind
            ? this.translateService.instant('common.labels.yes')
            : this.translateService.instant('common.buttons.no');
        })
        .colId('blind')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 60,
          maxWidth: 100,
          sortable: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: [
              this.translateService.instant('common.labels.yes'),
              this.translateService.instant('common.labels.no'),
            ],
          },
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.laboratory', (params: ValueGetterParams) => {
          return params.data.laboratory ? params.data.laboratory.name : '';
        })
        .colId('laboratory.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 100,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.type', (params: ValueGetterParams) => {
          const metricsType: MetricsDefinitionType[] = params.data.metricsDefinitionTypes;
          return isNullOrUndefined(metricsType) ? '' : metricsType.map(x => x.typeName).join(', ');
        })
        .colId('metricsDefinitionTypes.typeName')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 100,
          sortable: false,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.standard_definition_sensory', (params: ValueGetterParams) => {
          return params.data.standardDefinition ? params.data.standardDefinition.name : '';
        })
        .colId('standardDefinition.name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 200,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.system_id', (params: ValueGetterParams) => {
          const samplesNames = (params.data.cuppingSessionSamples || [])
            .map((sessionSample: CuppingSessionSample) => sessionSample.sample.sample)
            .join(', ');
          return samplesNames || '';
        })
        .colId('cuppingSessionSamples.samples.sample')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          sortable: false,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.cup_evaluator', (params: ValueGetterParams) => {
          const { cuppingSessionCupEvaluators } = params.data;
          return nullsafe(cuppingSessionCupEvaluators).length > 0
            ? cuppingSessionCupEvaluators.map(x => (x.cupEvaluator ? x.cupEvaluator.cupEvaluator : '')).join(', ')
            : '';
        })
        .colId('cuppingSessionCupEvaluators.cupEvaluator.cupEvaluator')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 200,
          sortable: false,
        })
        .build(),
    });

    return gridColDefs;
  }

  loadCuppingProcessColDef(): ColDef[] {
    const gridColDefs: ColDef[] = [];

    gridColDefs.push({
      headerName: '#',
      colId: 'id',
      field: 'id',
      resizable: true,
      sort: 'asc',
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.system_id'),
      colId: 'sample',
      field: 'sample.sample',
      resizable: true,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.type'),
      colId: 'metricsDefinitionType',
      field: 'metricsDefinitionType.typeName',
      resizable: true,
      sort: 'asc',
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.cup_evaluator'),
      colId: 'cupEvaluator',
      field: 'cupEvaluator.cupEvaluator',
      resizable: true,
      sort: 'asc',
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.comment'),
      colId: 'comment',
      field: 'comment',
      resizable: true,
    });

    gridColDefs.push({
      headerName: this.translateService.instant('common.labels.score_to'),
      colId: 'score',
      field: 'value',
      resizable: true,
    });

    return gridColDefs;
  }

  loadMasterRuleValidation(): Rule {
    return {
      // TODO: Is unique? validate : no validate (backend)
      session: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      // TODO: Is unique? validate : no validate (backend)
      laboratory: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      metricsDefinitionTypes: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      cuppingSessionSamples: (v: Validation): void => {
        const deletedItems = nullsafe(v.ctx).length ? (v.ctx || []).filter(x => !x.deleted) : [];
        if (!deletedItems.length) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      cuppingSessionCupEvaluators: (v: Validation): void => {
        const deletedItems = nullsafe(v.ctx).length ? (v.ctx || []).filter(x => !x.deleted) : [];
        if (!deletedItems.length) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      standardDefinition: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      totalCups: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      sampleWeight: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
    };
  }

  loadSampleColDefinition(countries: Country[]): ColDefMap[] {
    return [
      { label: '#', value: 'data.id' },
      { label: 'common.labels.date', value: 'data.date' },
      { label: 'common.labels.system_id', value: 'data.sample' },
      {
        label: 'common.labels.sample_reference',
        value: 'data.sampleReference',
        pagination_cols: 'sampleReference',
      },
      {
        label: 'common.labels.country',
        value: (params: ValueGetterParams): string => {
          const country = (countries || []).find(item => item.id === params.data.countryId);
          return (country && country.country) || '';
        },
      },
      {
        label: 'common.labels.quality',
        value: (params: ValueGetterParams): string => {
          const quality = params.data.quality && params.data.quality.name;
          return quality || '';
        },
      },
    ];
  }
}
