import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RoleService } from '../../../modules/authorization/services/role.service';
import {
  directoryUsersFetched,
  errorResponse,
  fetchDirectoryUsers,
  fetchPermission,
  fetchPermissionSuccess,
  fetchUserRoles,
  fetchUserRolesSuccess,
} from '../actions';
import { DirectoryUser, PaginationRequestParams, Permissions, Role } from '../../domain/models';
import { UserProfileService } from '../../../modules/user-profile/services/user-profile.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Injectable()
export class UserEffects {
  public constructor(
    protected actions$: Actions,
    protected roleService: RoleService,
    private profileService: UserProfileService,
  ) {}

  public readonly fetchRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserRoles),
      switchMap(() => this.roleService.loadRoles()),
      map((roles: Role[]) => fetchUserRolesSuccess({ roles: roles })),
    ),
  );

  public readonly fetchDirectoryUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDirectoryUsers),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'id,DESC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(() => this.profileService.fetchDirectoryUsers()),
      map((res: DirectoryUser[]) => directoryUsersFetched({ users: res })),
      catchError(err => of(errorResponse({ _httpErrorResponse: err }))),
    ),
  );

  public readonly fetchPermission$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPermission),
      switchMap(() => this.roleService.loadPermissions()),
      map((permission: Permissions[]) => fetchPermissionSuccess({ permissions: permission })),
    ),
  );
}
