export enum ColumnsType {
  AG_GRID_COLUMNS,
  COL_DEF,
}

export enum GridSelectionMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface ImageGallery {
  source: string;
  title?: string;
  alt?: string;
}

export interface DispatchMailManage {
  sendTo: string[];
  bcc: string[];
  cc: string[];
}
