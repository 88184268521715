import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { CoffeeSpecie, PaginatedResponse, PaginationRequestParams, QualityType } from '../../domain/models';
import { fetchCoffeeSpecies, fetchCoffeeSpeciesSuccess, fetchQualityType, fetchQualityTypeSuccess } from '../actions';
import { QueryService } from '../../../modules/services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Injectable()
export class QualityEffects {
  constructor(
    private actions$: Actions,
    private queryService: QueryService,
  ) {}

  public readonly fetchQualityType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQualityType),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(QualityType, action.pagination)),
      map((qualityType: PaginatedResponse) => fetchQualityTypeSuccess({ qualityTypes: qualityType.items })),
    ),
  );

  public readonly fetchCoffeeSpecies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCoffeeSpecies),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'coffeeSpecie,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(CoffeeSpecie, action.pagination)),
      map((response: PaginatedResponse) => fetchCoffeeSpeciesSuccess({ coffeeSpecies: response.items })),
    ),
  );
}
