import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import Chart from 'chart.js/auto';
import { Constants } from '../../../../../../../../../core/utils/app.constants';
import { State } from '../../../../../../../../../core/ngrx/reducers';
import { AuthorizationService } from '../../../../../../../../authorization/services/authorization.service';
import { TimeService } from '../../../../../../../../utils/time-service';
import { QueryService } from '../../../../../../../../services/query.service';
import { AbstractCeisService } from '../../../../../../../../../core/service/app.abstract.service';
import {
  CuppingProcess,
  CuppingProcessSearch,
  PaginatedResponse,
  PaginationRequestParams,
  StandardDefinition,
} from '../../../../../../../../../core/domain/models';
import { setMasterDataSelectedItem } from '../../../../../../../../../core/ngrx/actions';
import { isNullOrUndefined, nullsafe } from '../../../../../../../../utils/object-utils';

@Injectable({
  providedIn: 'root',
})
export class CuppingProcessService extends AbstractCeisService {
  private readonly qcUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    private store: Store<State>,
    timeService: TimeService,
    private queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.webStorage = window.localStorage;
    this.qcUrl = configuration.SERVER_WITH_QC_API_URL;
  }

  public async reportCupping(processId: number, languageData?: string): Promise<Blob> {
    const paginationRequestParams = new PaginationRequestParams();
    paginationRequestParams._include =
      '*,sample,cuppingProcessMetrics(cuppingProcessMetricDefects,' +
      'cuppingProcessMetricOptions(standardsDefinitionEquivalences.metricsDefinitionOption),standardDefinitionOption(metricsDefinition(metricsDefinitionTypeResult)))';
    const cuppingProcess = await this.queryService.querySearch<CuppingProcess>(
      CuppingProcess,
      paginationRequestParams,
      null,
      null,
      null,
      processId,
      false,
    );
    const radarBase64 = await this.getRadarBase64(cuppingProcess);
    const formData: FormData = new FormData();
    formData.append('imageBase64', radarBase64);
    const lang = languageData || this.getLanguage();
    const url = `${this.qcUrl}cuppingProcess/report/by-id/${processId}/${this.langUrl}${lang}`;
    const res = await this.postWithParams(
      url,
      {},
      formData,
      true,
      () => this.authService.renewToken(),
      'multipart/form-data',
      false,
      'blob',
    );
    return <Blob>res;
  }

  public async getStandardDefinitionsByTenant(
    tenantId: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<any> {
    // WARN: if size property from paginationRequestParams is null, backend response is with clean array items [root property],
    // otherwise the backend response is with PaginatedResponse and should filter on root function
    const request = { ...paginationRequestParams } || new PaginationRequestParams();
    request.sort = 'name,ASC';
    const url = `${this.qcUrl}standard_definition/${tenantId}/by_tenant`;
    const res = await this.getWithParams(url, request, true, () => this.authService.renewToken());
    return <any>res;
  }
  public async getLaboratoriesByTenant(
    tenantId: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<any> {
    // WARN: if size property from paginationRequestParams is null, backend response is with clean array items [root property],
    // otherwise the backend response is with PaginatedResponse and should filter on root function
    const request = { ...paginationRequestParams } || new PaginationRequestParams();
    request.sort = 'name,ASC';
    const url = `${this.qcUrl}laboratory/${tenantId}/by_tenant`;
    const res = await this.getWithParams(url, request, true, () => this.authService.renewToken());
    return <any>res;
  }

  // TODO: pending when admin see all cuppingProcess data of the cuppingSession. Add feature into backend service
  public async getCuppingProcessBySessionAndEvaluator(
    sessionId: number,
    evaluatorId?: number,
    sampleId?: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<CuppingProcess[]> {
    // WARN: if size property from paginationRequestParams is null, backend response is with clean array items [root property],
    // otherwise the backend response is with PaginatedResponse and should filter on root function
    const cupEvaluator = evaluatorId || '';
    const url = `${this.qcUrl}cupping_process/by-cupping-session/${sessionId}/by-cup-evaluator/${cupEvaluator}`;

    const request = paginationRequestParams || new PaginationRequestParams();
    request.sort = 'cupEvaluator,ASC';

    const cuppingProcesses = await this.getWithParams<CuppingProcess[]>(url, request, true, () =>
      this.authService.renewToken(),
    );
    const cuppingProcess = (cuppingProcesses || []).find(x => x.sample.id == sampleId) || new CuppingProcess();
    this.store.dispatch(setMasterDataSelectedItem({ selectedItem: cuppingProcess }));
    return cuppingProcesses;
  }

  public async searchCriteria(
    cuppingProcessSearch: CuppingProcessSearch,
    paginationRequestParams: PaginationRequestParams,
  ): Promise<PaginatedResponse> {
    if (!isNullOrUndefined(cuppingProcessSearch)) {
      cuppingProcessSearch = this.queryService.toServer(cuppingProcessSearch);
    }
    const data = await this.postWithParams(
      `${this.qcUrl}cupping_process/search-criteria`,
      paginationRequestParams,
      cuppingProcessSearch,
      true,
      () => this.authService.renewToken(),
    );
    return <PaginatedResponse>data;
  }

  onSaveCuppingProcess(cuppingProcess: CuppingProcess, pagination: PaginationRequestParams): Promise<CuppingProcess> {
    return this.queryService.saveEntity(cuppingProcess, CuppingProcess, pagination);
  }

  getRadarBase64(cuppingProcess: CuppingProcess): Promise<string> {
    const labels: string[] = [];
    const values: number[] = [];
    nullsafe(cuppingProcess.cuppingProcessMetrics).map(cuppingProcessMetric => {
      const value = isNullOrUndefined(cuppingProcessMetric.valueResult) ? 0 : cuppingProcessMetric.valueResult;
      if (
        !isNullOrUndefined(cuppingProcessMetric.standardDefinitionOption) &&
        cuppingProcessMetric.standardDefinitionOption.optionGraph
      ) {
        labels.push(
          `${cuppingProcessMetric.standardDefinitionOption.metricsDefinition.name} ${Math.round(value * 100) / 100}`,
        );
        values.push(value);
      }
    });

    const data =
      labels.length > 0
        ? {
            labels: labels,
            datasets: [
              {
                label: cuppingProcess.sample.sample,
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: '#006330',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: values,
              },
            ],
          }
        : undefined;

    // Chart.defaults.font.size = 16;
    const ctx = document.createElement('canvas');
    ctx.width = 650;
    ctx.height = 350;
    const chart = new Chart(ctx, {
      type: 'radar',
      data: data,
      options: {
        responsive: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            beginAtZero: true,
            min: 0,
            max: cuppingProcess.standardDefinition.scoreTo,
            font: {
              size: 16,
            },
            ticks: {
              display: false,
              stepSize: 1,
            },
            pointLabels: {
              display: true,
              font: {
                size: 25,
              },
              color: '#000',
            },
            grid: {
              color: '#bdbdbd',
            },
          },
        },
        animation: {
          onComplete: function (): void {},
        },
      },
    });

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(chart.toBase64Image());
      }, 2000);
    });
  }

  public onGetStandardDefinitionById(standardId: number): Promise<StandardDefinition> {
    const url = `${this.qcUrl}standard_definition/${standardId}/`;
    return this.get(url, true, () => this.authService.renewToken());
  }

  public onGetCuppingProcessById(
    cuppingProcessId: number,
    requestParams: PaginationRequestParams,
  ): Promise<CuppingProcess> {
    const url = `${this.qcUrl}cupping_process/${cuppingProcessId}`;
    return this.getWithParams(url, requestParams, true, () => this.authService.renewToken());
  }
}
