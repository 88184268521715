import { AgEditorComponent } from 'ag-grid-angular';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MonthCompletion } from '../../basic-shared-module/components/date-input/date-input.component';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Component({
  selector: 'date-inline-cell-editor-component',
  templateUrl: 'date-inline-cell-editor.component.html',
})
export class DateCellEditorComponent implements AgEditorComponent {
  @ViewChild('dateInlineCellEditor') public input: ViewContainerRef;

  value: Date;
  cellEditorStyle: any;
  showIcon: boolean = true;
  inline: boolean = false;
  monthCompletion: MonthCompletion = 'none';
  minDate: Date;
  disabled: boolean;
  focusable: boolean = true;
  tabIndex: number;

  agInit(params): void {
    this.value = params.value;
    this.disabled = isNullOrUndefined(params.disabled) ? false : params.disabled;
    this.focusable = isNullOrUndefined(params.focusable) ? true : params.focusable;
    this.tabIndex = isNullOrUndefined(params.tabIndex) ? null : params.tabindex;
    this.showIcon = isNullOrUndefined(params.showIcon) ? null : params.showIcon;
    this.minDate = isNullOrUndefined(params.minDate) ? null : params.minDate;
    this.monthCompletion = isNullOrUndefined(params.monthCompletion) ? 'none' : params.monthCompletion;
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  onValueChanged(event): void {
    if (!isNullOrUndefined(event)) this.value = event;
  }

  onKeyDown(event): void {
    event.stopPropagation();
  }
}
