<nkg-tooltip [messages]="validationResult" [fieldWithIcon]="true">
  <nkg-tooltip-target class="ncs-basic-wrapper">
    <ng-container *ngIf="readOnly; then secondContent; else primaryContent"></ng-container>
    <ng-template #primaryContent>
      <p-checkbox
        label="{{ label | translate }}"
        [binary]="true"
        [ngStyle]="disabledType == disabledTypeEnum.HARD_AUTOFILL ? { 'background-color': 'white', opacity: 1 } : {}"
        [disabled]="disabledType == disabledTypeEnum.HARD_AUTOFILL || disabledType == disabledTypeEnum.YES"
        [ngModel]="value"
        [tabindex]="tabindex"
        (onChange)="checkedChanged($event)">
      </p-checkbox>
    </ng-template>
    <ng-template #secondContent>
      <span>{{ (value ? 'common.labels.yes' : 'common.labels.no') | translate }}</span>
    </ng-template>
  </nkg-tooltip-target>
</nkg-tooltip>
