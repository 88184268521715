import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { merge } from 'lodash';
import { isEmpty } from '../../../../../../../utils/string-utils';
import { MasterdataValidationService } from '../../../../../../services/masterdata-validation.service';
import * as fromRoot from '../../../../../../../../core/ngrx/reducers';
import { MasterChildComponent } from '../../../../../../components/master-child.component';
import { isNullOrUndefined } from '../../../../../../../utils/object-utils';
import { Rule, Validation } from '../../../../../../services/master-validation';
import { CupIllustrationService } from '../../../../../metric-definition/modules/cup-illustration/services/cup-illustration.service';
import { GuestEvaluatorDto } from '../../../../../../../../core/domain/models';
import {
  getAppUserByEmailSuccess,
  getAppUserByMail,
  getAppUserByUsername,
  getAppUserByUsernameSuccess,
} from '../../../../../../../../core/ngrx/actions';

@Component({
  selector: 'guest-evaluator-form',
  templateUrl: './guest-evaluator.component.html',
  providers: [CupIllustrationService],
})
export class GuestEvaluatorComponent extends MasterChildComponent<GuestEvaluatorDto> implements OnInit, OnDestroy {
  protected guestEvaluatorsQueue: GuestEvaluatorDto[];
  private guestEvaluatorSub$: Subscription;
  emailAvailable: boolean = true;
  usernameAvailable: boolean = true;
  codeAvailable: boolean = true;

  @Output() onConfirmed: EventEmitter<GuestEvaluatorDto> = new EventEmitter<GuestEvaluatorDto>();

  constructor(
    protected store: Store<fromRoot.State>,
    protected validationService: MasterdataValidationService,
  ) {
    super(validationService, store);
    this.guestEvaluatorSub$ = this.guestEvalSubscription();
  }

  ngOnDestroy(): void {
    this.guestEvaluatorSub$.unsubscribe();
    this.store.dispatch(getAppUserByEmailSuccess({ user: null }));
    this.store.dispatch(getAppUserByUsernameSuccess({ user: null }));
  }

  ngOnInit(): void {
    this.onNew();
    this.emailAvailable = true;
    this.usernameAvailable = true;
    this.codeAvailable = true;
  }

  guestEvalSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getRetrievedUserByEmail),
      this.store.select(fromRoot.getRetrievedUserByUsername),
    ]).subscribe(([byEmail, byUsername]) => {
      this.emailAvailable = isNullOrUndefined(byEmail) || byEmail.id <= 0;
      if (
        this.emailAvailable &&
        !isNullOrUndefined(this.onGetCurrentChildItem()) &&
        isEmpty(this.onGetCurrentChildItem().password)
      ) {
        const randomPwd: string = Math.random().toString(36).slice(-8);
        this.onItemFieldChange(randomPwd, 'password');
      }
      this.usernameAvailable = isNullOrUndefined(byUsername) || byUsername.id <= 0;
    });
  }

  public onUsernameChanged(username: string): void {
    // validate if username is already used
    const newUsername = username && username.toLowerCase();
    if (!isNullOrUndefined(newUsername)) {
      this.usernameAvailable = !(this.guestEvaluatorsQueue || []).some(x => x.username.toLowerCase() === newUsername);
      if (this.usernameAvailable) this.store.dispatch(getAppUserByUsername({ username: newUsername }));
    }
    this.onItemFieldChange(newUsername, 'username');
  }

  public onEmailChanged(email: string): void {
    // validate if email is already used
    const newEmail = email && email.toLowerCase();
    if (!isNullOrUndefined(newEmail)) {
      this.emailAvailable = !(this.guestEvaluatorsQueue || []).some(x => x.email.toLowerCase() === newEmail);
      if (this.emailAvailable) {
        this.store.dispatch(getAppUserByMail({ email: newEmail }));
      }
    }
    this.onItemFieldChange(newEmail, 'email');
  }

  public clean(): void {
    this.onNew();
    this.emailAvailable = true;
    this.usernameAvailable = true;
    this.codeAvailable = true;
  }

  public isValid(): boolean {
    let valid: boolean = this.usernameAvailable && this.emailAvailable && this.codeAvailable;
    valid =
      valid &&
      !isNullOrUndefined(this.onGetCurrentChildItem().email) &&
      !isNullOrUndefined(this.onGetCurrentChildItem().username);
    this.validationResult = merge(
      this.validationResult,
      this.validationService.validateItem(this.onGetCurrentChildItem(), this.onGetRuleChildValidation()),
    );
    return valid && this.validationResult.valid;
  }

  public onAdd(): GuestEvaluatorDto {
    return this.isValid() ? this.onGetCurrentChildItem() : null;
  }

  public onCancel(): void {
    return this.ngOnInit();
  }

  protected onNewChildItem(): GuestEvaluatorDto {
    return new GuestEvaluatorDto();
  }
  protected onGetRuleChildValidation(): Rule {
    return {
      name: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      lastName: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      code: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      email: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      username: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      password: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
    };
  }
  protected onHideParentToolbar(event: any): void {}
  protected onAfterItemFieldChange(): void {}
}
