import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { combineLatest, lastValueFrom, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { delay, filter } from 'rxjs/operators';
import { AppNotification, User, View } from '../../domain/models';
import { logout } from '../../ngrx/actions';
import * as fromRoot from '../../ngrx/reducers';
import { State } from '../../ngrx/reducers';
import { AbstractCeisService } from '../../service/app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { isNotEmpty } from '../../../modules/utils/string-utils';
import { AuthorizationService } from '../../../modules/authorization/services/authorization.service';
import { TimeService } from '../../../modules/utils/time-service';
import { NotificationService } from '../../../modules/services/notification.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent extends AbstractCeisService implements OnDestroy, OnInit {
  @Output() messageItemClicked: EventEmitter<any> = new EventEmitter();
  protected webStorage: Storage;
  private navigationSubscription$: Subscription;
  private notification: Subscription;
  private authorizationService: AuthorizationService;
  public user: User; // used by newGrid instance
  public title: string;
  public navigateBackLink: string;
  public navigateBackText: string;
  public userMenuEntries: MenuItem[] = [];
  public displayVersionInfo: boolean = false;
  public view: View;
  public notificationsStored: AppNotification[];
  public isAuthenticated: boolean = false;

  constructor(
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    protected store: Store<State>,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    authorizationService: AuthorizationService,
  ) {
    super(http, configuration, timeService);

    this.webStorage = window.localStorage;
    this.onCheckLanguage();
    this.userMenuEntries = this.createUserMenuEntries();
    this.notification = this.notificationProps();
    this.authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.navigationSubscription$ = this.onSubscribe();
  }

  ngOnDestroy(): void {
    this.notification.unsubscribe();
    this.navigationSubscription$.unsubscribe();
  }

  private onCheckLanguage(): void {
    this.translate.onLangChange.subscribe(() => {
      this.onUiLangChange();
    });
  }

  private onSubscribe(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentViewNavigation).pipe(
        delay(300),
        filter(item => !isNullOrUndefined(item)),
      ),
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getIsAuthenticated),
    ]).subscribe(([view, user, isAuthenticated]) => {
      this.user = user;
      this.isAuthenticated = isAuthenticated;
      this.view = view;
      const { previousView } = view;
      if (previousView) {
        this.navigateBackLink = previousView.uri;
        this.navigateBackText = previousView.title;
      } else {
        this.navigateBackLink = undefined;
        this.navigateBackText = undefined;
      }
    });
  }

  private onUiLangChange(): void {
    lastValueFrom(this.translate.get('ANY_KEY')).then(() => {
      // wait for isoCode loaded
      this.userMenuEntries = this.createUserMenuEntries();
    });
  }

  private createUserMenuEntries(): MenuItem[] {
    return [
      {
        label: this.translate.instant('topbar.labels.my_profile'),
        command: (): void => {
          this.router.navigate(['/user-profile']);
        },
      },
      {
        label: this.translate.instant('topbar.labels.logout'),
        command: (): void => {
          this.store.dispatch(logout());
        },
      },
    ];
  }

  public onMessageItemClick(): void {
    this.messageItemClicked.emit();
  }

  private notificationProps(): Subscription {
    return this.store.select(fromRoot.getNotifications).subscribe((notification: AppNotification[]) => {
      this.notificationsStored = notification;
    });
  }

  public numberOfNewNotifications(): number {
    if (!isNullOrUndefined(this.notificationsStored)) {
      return this.notificationsStored.filter(
        n => n?.createdAt.getTime() > this.notificationService.getLastReadTime().getTime(),
      ).length;
    }
    return 0;
  }

  public hasNewNotifications(): boolean {
    return this.numberOfNewNotifications() > 0;
  }

  public onConfirm(key: string): void {
    this.messageService.clear(key);
    this.store.dispatch(logout());
  }

  public onReject(key: string): void {
    const refreshToken: string = this.authorizationService.getRefreshToken();
    if (isNotEmpty(refreshToken)) {
      this.authorizationService
        .refreshToken(refreshToken)
        .then(() => {
          this.messageService.clear(key);
        })
        .catch(() => this.store.dispatch(logout()));
    }
  }
}
