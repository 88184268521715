import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as HttpStatus from 'http-status-codes';
import { lastValueFrom } from 'rxjs';
import { Constants } from '../utils/app.constants';
import { TimeService } from '../../modules/utils/time-service';
import { hasOwnProperty, isNullOrUndefined } from '../../modules/utils/object-utils';

export class UnauthorizedError extends Error {
  isUnauthorizedError: boolean = true;
}

export class HttpError extends Error {
  constructor(
    public status: number,
    public statusText: string,
    public responseText: string,
  ) {
    super(responseText || statusText);
  }
}

export abstract class AbstractCeisService {
  public static readonly STORAGE_KEY_JWT_TOKEN = '__jwt_token__';
  public static readonly STORAGE_KEY_JWT_REFRESH_TOKEN = '__jwt_refresh_token__';
  public static readonly STORAGE_KEY_USERNAME = '__username__';
  public static readonly STORAGE_KEY_TOKEN_TTL = '__token_ttl__';
  public static readonly STORAGE_KEY_MENU = '__menu__';
  public static readonly STORAGE_KEY_AUTH = '__auth__';
  public static readonly STORAGE_KEY_MESSAGE_SESSION = '__session__';
  public static readonly STORAGE_MSAL_TOKEN = '__msal_token__';
  public static readonly STORAGE_MSAL_ON_REDIRECT = '__msal_on_redirect__';

  protected webStorage: Storage;
  private readonly logoutUrl: string;
  public readonly langUrl: string;

  protected constructor(
    protected httpClient: HttpClient,
    protected configuration: Constants,
    protected timeService: TimeService,
  ) {
    this.webStorage = window.localStorage;
    this.logoutUrl = `${configuration.SERVER_WITH_AUTH_API_URL}logout`;
    this.langUrl = configuration.LANG_QUERY_URL;
  }

  public getLogoutUrl(): string {
    return this.logoutUrl;
  }

  private static transformError(err: HttpError | any): any {
    if (err && err.headers && err.headers.get('content-type') == 'application/json') {
      err.json = err.error;
    }

    if (err && err.status && err.error) {
      err.message = err.error;
    }

    if (err && err.status === 400) {
      return err;
    }

    return err && err.status && typeof err.text === 'function'
      ? new HttpError(err.status, err.statusText, err.text())
      : err;
  }

  public async logout(): Promise<boolean> {
    if (this.isLoggedIn()) {
      try {
        const res = await this.get(this.logoutUrl, this.isLoggedIn(), null);
        this.clearWebStorage();
        return !!res;
      } catch (err) {
        this.clearWebStorage();
        throw AbstractCeisService.transformError(err);
      }
    }
    return Promise.resolve(true);
  }

  public clearWebStorage(): void {
    this.webStorage.removeItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN);
    this.webStorage.removeItem(AbstractCeisService.STORAGE_KEY_USERNAME);
    this.webStorage.removeItem(AbstractCeisService.STORAGE_KEY_TOKEN_TTL);
    this.webStorage.removeItem(AbstractCeisService.STORAGE_KEY_MENU);
    this.webStorage.removeItem(AbstractCeisService.STORAGE_KEY_AUTH);
  }

  public getAccessToken(): string {
    return this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN);
  }

  public getRefreshToken(): string {
    return this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_REFRESH_TOKEN);
  }

  public isLoggedChecker(): Promise<void> {
    return Promise.resolve();
  }

  public getLanguage(): string {
    return (
      this.webStorage.getItem(this.configuration.UI_LANGUAGE) ||
      this.webStorage.getItem(this.configuration.DEFAULT_UI_LANGUAGE)
    );
  }

  public getLanguageShort(): string {
    return this.webStorage.getItem(this.configuration.UI_LANGUAGE)
      ? this.webStorage.getItem(this.configuration.UI_LANGUAGE).split('-')[0]
      : 'en';
  }

  public isLoggedIn(): boolean {
    return !!this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN);
  }

  public renewToken(): Promise<boolean> {
    // logic move to backend-interceptor
    return Promise.resolve(true);
  }

  protected tokenRenewalSucceeded(token: string, refreshToken: string, expires_in_mls: number): void {
    const currentTimeMs = this.timeService.obtainNow().getTime();
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN, token);
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_JWT_REFRESH_TOKEN, refreshToken);
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_TOKEN_TTL, `${currentTimeMs + expires_in_mls * 0.7}`);
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_MESSAGE_SESSION, `${currentTimeMs + expires_in_mls * 0.9}`);
  }

  protected async postWithParams<T>(
    url: string,
    params: any,
    body: any,
    authorized: boolean,
    tokenRenew: () => Promise<any>,
    contentType = 'application/json',
    returnResponse: boolean = false,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
  ): Promise<any> {
    let headers = this.createRequestHeader(authorized, 'application/json', contentType);
    if (!isNullOrUndefined(body) && hasOwnProperty(body, 'tenantAbbreviation')) {
      headers = headers.append('X-Tenant-Id', body.tenantAbbreviation);
    }

    params = this.getIncludeParams(params);

    let options = {
      headers: headers,
      params: this.createHttpParams(params),
      responseType: responseType as 'json',
    };

    if (returnResponse) {
      options = Object.assign(options, { observe: 'response' });
    }

    try {
      return await lastValueFrom(this.httpClient.post<T>(url, body, options));
    } catch (err) {
      // Error not catching and catching in backend-pending.interceptor.ts like solution
      this.onError(authorized, err);
    }
  }

  protected post(url: string, body: any, authorized: boolean, tokenRenew: () => Promise<any>): Promise<any> {
    return this.postWithParams(url, undefined, body, authorized, tokenRenew, 'application/json');
  }

  protected async putWithParams<T>(
    url: string,
    params: any,
    body: any,
    authorized: boolean,
    tokenRenew: () => Promise<any>,
    contentType = 'application/json',
  ): Promise<T> {
    params = this.getIncludeParams(params);
    try {
      return <any>await lastValueFrom(
        this.httpClient.put(url, body, {
          params: this.createHttpParams(params),
          headers: this.createRequestHeader(authorized, 'application/json', contentType),
        }),
      );
    } catch (err) {
      this.onError(authorized, err);
    }
  }

  protected put<T>(url: string, body: any, authorized: boolean, tokenRenew: () => Promise<any>): Promise<T> {
    return this.putWithParams(url, undefined, body, authorized, tokenRenew);
  }

  protected async delete<T>(url: string, authorized: boolean, tokenRenew: () => Promise<any>): Promise<T> {
    if (authorized && tokenRenew) {
      return this.delete(url, authorized, null);
    }
    try {
      return <T>await this.httpClient.delete(url, { headers: this.createRequestHeader(authorized) }).toPromise();
    } catch (err) {
      this.onError(authorized, err);
    }
  }

  protected get<T>(url: string, authorized: boolean, tokenRenew: () => Promise<any>): Promise<T> {
    return this.getWithParams(url, null, authorized, tokenRenew);
  }

  protected async getWithParams<T>(
    url: string,
    params: { [key: string]: any | any[] },
    authorized: boolean,
    tokenRenew: () => Promise<any>,
    mediaType = 'application/json',
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' /* default is undefined */,
  ): Promise<T> {
    params = this.getIncludeParams(params);
    try {
      return await lastValueFrom(
        this.httpClient.get<T>(url, {
          params: this.createHttpParams(params),
          headers: this.createRequestHeader(authorized, mediaType),
          responseType: responseType as 'json',
        }),
      );
    } catch (err) {
      this.onError(authorized, err);
    }
  }

  protected onError(authorized: boolean, err: any): void {
    if (!authorized && HttpStatus.UNAUTHORIZED != err.status && !this.isLoggedIn()) {
      throw AbstractCeisService.transformError(err);
    }
  }

  protected getIncludeParams(params: { [key: string]: any | any[] }): { [p: string]: any } {
    const includeKey: string = '_include';
    if (!isNullOrUndefined(params)) {
      let includes = '';
      if (!hasOwnProperty(params, includeKey)) {
        params[includeKey] = '*';
      } else {
        includes = params[includeKey];
        if (isNullOrUndefined(includes) || includes === '') {
          includes = '*';
          params[includeKey] = includes;
        }
      }
      return params;
    }
    return { _include: '*' };
  }

  /**
   * Creates a request header with:
   * Content-Type: application/json
   * Accept: application/json
   * Authorization: bearer <current user token>
   *
   * @returns {Headers}
   */
  private createRequestHeader(
    authorized: boolean,
    mediaType = 'application/json',
    contentType = 'application/json',
  ): HttpHeaders {
    let headers = new HttpHeaders();

    if (contentType != 'multipart/form-data') {
      headers = headers.append('Content-Type', contentType);
      headers = headers.append('Accept', mediaType);
      headers = headers.append('Access-Control-Allow-Origin', '*');
    }

    if (authorized) {
      if (!this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN)) {
        throw Error('cannot create backend request without authorization token!');
      }
      headers = headers.append(
        'Authorization',
        `Bearer ${this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN)}`,
      );
    }
    return headers;
  }

  private createHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        if (!isNullOrUndefined(params[key])) {
          httpParams = httpParams.set(key, params[key]);
        }
      });
    }
    return httpParams;
  }
}
