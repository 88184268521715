import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../../../../../../authorization/services/authorization.service';
import { TimeService } from '../../../../../../utils/time-service';
import { QueryService } from '../../../../../../services/query.service';
import { nullsafe } from '../../../../../../utils/object-utils';
import { AbstractCeisService } from '../../../../../../../core/service/app.abstract.service';
import { Agency, Country, PaginatedResponse } from '../../../../../../../core/domain/models';
import { Constants } from '../../../../../../../core/utils/app.constants';
import { LookupParams } from '../../../../../../../shared/search/models/enums';

@Injectable()
export class ThirdPartyService extends AbstractCeisService {
  private readonly actionAuth: string;
  private readonly actionQC: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private authService: AuthorizationService,
    timeService: TimeService,
    private queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.webStorage = window.localStorage;
    this.actionAuth = configuration.SERVER_WITH_AUTH_API_URL;
    this.actionQC = configuration.SERVER_WITH_QC_API_URL;
  }

  async loadCountryId(countryId: number): Promise<Country> {
    const res = await this.get(`${this.actionAuth}country/${countryId}`, true, () => this.authService.renewToken());
    return this.queryService.fromServer(res);
  }

  async loadAgencyId(agencyId: number): Promise<Agency> {
    const res = await this.get(`${this.actionAuth}agency/${agencyId}`, true, () => this.authService.renewToken());
    return this.queryService.fromServer(res);
  }

  async loadThirdPartyByThirdType(thirdPartyTypeId: number, queryRequest: LookupParams): Promise<PaginatedResponse> {
    const params = {
      thirdPartyType: thirdPartyTypeId,
      thirdParty: '',
      page: queryRequest.page,
      size: 20,
      deleted: false,
    };
    const url = `${this.actionQC}third_party_third_party_type/byThirdPartyTypeId`;

    const res = await this.getWithParams<PaginatedResponse>(url, params, true, () => this.authService.renewToken());
    res.items = nullsafe(res.items).map(item => this.queryService.fromServer(item));
    return res;
  }
}
