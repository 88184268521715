import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { MasterComponent } from '../../../../../components/master.component';
import {
  CupEvaluator,
  CupIllustration,
  CuppingProcessDefaultOption,
  CuppingProcessType,
  CuppingSession,
  CuppingSessionCupEvaluator,
  CuppingSessionStatus,
  EmailConfirmation,
  GuestEvaluatorDto,
  Laboratory,
  MetricsDefinitionType,
  PaginationRequestParams,
  Sample,
  StandardDefinition,
  User,
  View,
} from '../../../../../../../core/domain/models';
import * as fromRoot from '../../../../../../../core/ngrx/reducers';
import { MasterdataValidationService } from '../../../../../services/masterdata-validation.service';
import {
  authNotificationEmitter,
  dddGuestEvaluator,
  fetchCountries,
  fetchMetricsDefTypes,
  getCuppingSessionByIdSession,
  guestEvaluatorsQueue,
  saveGuestEvaluatorsQueue,
  selectCuppingSession,
  sendCupInvitation,
} from '../../../../../../../core/ngrx/actions';
import { handleLabelClick } from '../../../../../../../shared/basic-shared-module/utils/component.util';
import { hasOwnProperty, isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../../../utils/object-utils';
import { CuppingSessionService } from '../../../services/cupping-sesion.service';
import { CupIllustrationService } from '../../../../metric-definition/modules/cup-illustration/services/cup-illustration.service';
import { GuestEvaluatorComponent } from './guest-evaluator/guest-evaluator.component';
import { CuppingSessionEvaluatorsComponent } from './cupping-session-evaluators/cupping-session-evaluators.component';
import { laboratoryRequestParams } from '../../../../../utils/master-request-params-utils';
import { FieldValidationResult, Rule } from '../../../../../services/master-validation';
import { ToastSeverityEnum } from '../../../../../../../core/ngrx/notification-utils';
import { NkgGridFactory } from '../../../../../../../shared/grid/services/grid.factory';
import { GridViewNameReference } from '../../../../../../../shared/grid/views/view-type';
import { SampleService } from '../../../../sample/services/sample.service';
import { MetricDefinitionType } from '../../sample-analysis/modules/cupping-process-list/components/cupping-form/cupping-form-container.component';
import { BaseMasterGridCol } from '../../../../../models/interfaces';

export const CUPPING_SESSION_DEFAULT_INCLUDE =
  'status,standardDefinition(name),laboratory,cupEvaluator,cuppingSessionSamples(sample(sample,quality,sampleSource)),' +
  'cuppingSessionCupEvaluators(cupEvaluator(cupEvaluatorUser)),metricsDefinitionTypes,cupIllustration.cupIllustrationImages';

@Component({
  templateUrl: './cupping-session.component.html',
  providers: [CupIllustrationService, SampleService],
})
export class CuppingSessionComponent extends MasterComponent<CuppingSession> implements OnInit, OnDestroy {
  protected readonly CuppingProcessType = CuppingProcessType;
  public paginationRequestParams: PaginationRequestParams = new PaginationRequestParams();

  private cuppingSessionSubscription$: Subscription;
  cuppingStatus: SelectItem[] = [];
  selectedLaboratory: Laboratory;
  selectedStandardDefinition: StandardDefinition;
  cuppingSessionSamplesRowData: Sample[] = [];
  selectedMetricsType: MetricsDefinitionType[];
  metricsDefinitionTypesOpt: SelectItem[];
  handleLabelClick = handleLabelClick;
  currentUser: User;
  standardDefinitionsOptions: StandardDefinition[];
  standardDefinitionSuggestions: SelectItem[];
  showImageDialog: boolean = false;
  imageOptions: any[]; // Custom cupIllustration collection
  invitationConfirmation: EmailConfirmation;
  // list with the new guest evaluator to add to the cupping session.
  showGuestFormDialog: boolean = false;
  pendingGuestEvaluators: GuestEvaluatorDto[] = [];
  checkSavedInterval: any;
  tabViewIndex: number = 0;
  laboratoryRequestParams: PaginationRequestParams;
  protected MASTER_ENTITY_MODULE_ID: string;

  @ViewChild(GuestEvaluatorComponent)
  guestFormComponent: GuestEvaluatorComponent;
  @ViewChild(CuppingSessionEvaluatorsComponent)
  cuppingSessionEvaluator: CuppingSessionEvaluatorsComponent;
  public laboratoryOptions: Laboratory[];

  public constructor(
    confirmationService: ConfirmationService,
    translationService: TranslateService,
    protected store: Store<fromRoot.State>,
    public gf: NkgGridFactory,
    protected router: Router,
    protected route: ActivatedRoute,
    protected validationService: MasterdataValidationService,
    private cuppingSessionService: CuppingSessionService,
    private cupIllustrationService: CupIllustrationService,
  ) {
    super(store, confirmationService, translationService, router, route, validationService, gf);

    this.listModeDefault = true;
    this.sizeToFit = false;
    this.MASTER_ENTITY_MODULE_ID = 'master_cupping_session';
    this.typeFunction = CuppingSession;
    this.gridViewNameReference = GridViewNameReference.CUPPING_SESSION_MASTER_DATA;
    this.gridColDef = this.getColDefs();
    this.setDisabledColumns('session');
    this.setDisabledColumns('status');
    this.paginationRequestParams.columns =
      'id,idSession,session,status.name,blind,laboratory.name,' +
      'metricsDefinitionTypes.typeName,standardDefinition.name,cuppingSessionSamples.sample.sample,cuppingSessionCupEvaluators.cupEvaluator.cupEvaluator';
    this.paginationRequestParams.sort = 'cuppingDate,desc';
    this.paginationRequestParams._include = CUPPING_SESSION_DEFAULT_INCLUDE;
    this.redirectUrl = '/master/general/cupping-session/';
    this.cuppingSessionSubscription$ = this.cuppingSessionSubscription();
    this.store.dispatch(fetchMetricsDefTypes({}));
    this.store.dispatch(fetchCountries({}));
    this.laboratoryRequestParams = laboratoryRequestParams();
  }

  ngOnInit(): void {
    this.onInitData();
    const view = new View('common.labels.cupping_session', '#/master/general/cupping-session/');
    this.onSetDisplayUrl(view, '#/master/general/cupping-session/');
  }

  ngOnDestroy(): void {
    this.store.dispatch(selectCuppingSession({ cuppingSession: null }));
    this.cuppingSessionSubscription$.unsubscribe();
    clearInterval(this.checkSavedInterval);
    this.onRemoveSubs();
  }

  protected getColDefs(): BaseMasterGridCol[] {
    return this.cuppingSessionService.loadCuppingMasterColDefs();
  }

  protected getRuleValidation(): Rule {
    return this.cuppingSessionService.loadMasterRuleValidation();
  }

  /** ngRx subscriptions */
  private cuppingSessionSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getMasterCurrentItem),
      this.store.select(fromRoot.getStandardDefinitions),
      this.store.select(fromRoot.getCuppingSessionStatus),
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getMetricsDefinitionTypes),
      this.store.select(fromRoot.getInvitationConfirmation),
      this.store.select(fromRoot.getGuestEvaluatorsQueue),
      this.store.select(fromRoot.getLaboratories),
    ]).subscribe(
      ([
        currentItem,
        standardDefinitions,
        cuppingSessionStatus,
        currentUser,
        metricDefinitionTypes,
        invitationConfirmation,
        guestEvaluators,
        laboratories,
      ]) => {
        this.currentUser = currentUser;
        this.standardDefinitionsOptions = this.onSetStandardDefinitionOptions(standardDefinitions);
        this.onLoadCuppingSessionStatuses(cuppingSessionStatus);
        this.loadMetricsDefinitionTypes(metricDefinitionTypes);

        if (!isNullOrUndefined(currentItem)) {
          this.selectedMetricsType = currentItem.metricsDefinitionTypes;
          this.selectedLaboratory = currentItem.laboratory;
          this.selectedStandardDefinition = currentItem.standardDefinition;
        }
        this.pendingGuestEvaluators = guestEvaluators;
        this.laboratoryOptions = laboratories;
        if (!isNullOrUndefined(invitationConfirmation)) {
          this.invitationConfirmation = invitationConfirmation;
        }
      },
    );
  }

  /** Load metric definition types options and convert to SelectItem[] type
   * @param {MetricDefinitionType} metricDefinitionTypes[] - metrics definition types */
  private loadMetricsDefinitionTypes(metricDefinitionTypes: MetricsDefinitionType[]): void {
    if (!nullsafe(this.metricsDefinitionTypesOpt).length && nullsafe(metricDefinitionTypes).length > 0) {
      this.metricsDefinitionTypesOpt = metricDefinitionTypes.map(metricType => ({
        label: metricType.typeName,
        value: metricType,
      }));
    }
  }

  /** Filter All Metrics types by sensorial type
   * @return {MetricDefinitionType} - metrics definition types */
  private findSensorialMetricType(): MetricsDefinitionType {
    return (this.metricsDefinitionTypesOpt || []).find(
      metricType => metricType.value.typeCode === MetricDefinitionType.SENSORIAL,
    ).value;
  }

  private onLoadCuppingSessionStatuses(statuses: CuppingSessionStatus[]): void {
    if (!nullsafe(this.cuppingStatus).length) {
      this.cuppingStatus = <SelectItem[]>(statuses || []).map(status => ({ label: status.name, value: status }));
    }
  }

  /** Create New CuppingSession entity
   * @return {CuppingSession} cupping session entity */
  protected newCurrentItemInstance(): CuppingSession {
    // Select first tab when create a new item
    this.tabViewIndex = 0;
    const newCuppingSession = mergeImmutable(new CuppingSession(), {
      status: nullsafe(this.cuppingStatus)[0]?.value,
      cuppingDate: new Date(),
      metricsDefinitionTypes: [this.findSensorialMetricType()],
    });
    this.cuppingSessionService.loadCuppingProcessDefaultOption().then(cuppingProcessDefault => {
      this.cuppingSessionSamplesRowData = [];
      // Try to set default values when clicked again and avoids null values
      this.onSetInitialValues(newCuppingSession, cuppingProcessDefault);
    });
    return newCuppingSession;
  }

  private onSetInitialValues(cuppingSession: CuppingSession, cuppingProcessDefault: CuppingProcessDefaultOption): void {
    if (nullsafe(this.laboratoryOptions).length && nullsafe(this.standardDefinitionsOptions).length) {
      // Avoid null currentItem
      setTimeout(() => {
        cuppingSession = mergeImmutable(this.getCurrentItem(), {
          standardDefinition: this.standardDefinitionsOptions.find(
            item => item.id == cuppingProcessDefault?.standardDefinitionId,
          ),
          laboratory: this.laboratoryOptions.find(item => item.id == cuppingProcessDefault?.laboratoryId),
          sampleWeight: cuppingProcessDefault?.sampleWeight,
          totalCups: cuppingProcessDefault?.totalCups || 3,
        });
        this.setCurrentItem(cuppingSession);
      });
    }
  }

  public rowClick(event: any): void {
    if (!isNullOrUndefined(event) && !isNullOrUndefined(event.data)) {
      this.store.dispatch(getCuppingSessionByIdSession({ cuppingSessionId: event.data.id }));
      super.rowClick(event);
    }
    this.tabViewIndex = 0;
  }

  /** @param {string} query - Filter standard definitions match data */
  public loadStandardDefinitionsSuggestions(query: string): void {
    setTimeout(() => {
      this.standardDefinitionSuggestions = (this.standardDefinitionsOptions || [])
        .filter(suggest => {
          const regex = new RegExp(query, 'gi');
          return suggest.name.match(regex);
        })
        .map(res => ({ label: res.name, value: res }));
    });
  }

  /** Setting totalCups and cupIllustration property according standard definition selection
   * @param {StandardDefinition} standardDefinition - standard definition selected */
  public onSetCupByStandard(standardDefinition: StandardDefinition): void {
    if (standardDefinition) {
      setTimeout(() => {
        const newCurrentItem = mergeImmutable(this.getCurrentItem(), {
          totalCups: standardDefinition.totalCups,
          cupIllustration: standardDefinition.cupIllustration,
        });
        this.setCurrentItem(newCurrentItem);
      }, 300);
    }
  }

  // TODO: update/remove imageSelected when standardDefinition is changed
  /** cup illustration update
   * @param imageSource - image source by PrimeNg */
  public onSelectedImage(imageSource: CupIllustration): void {
    this.onItemFieldChange(imageSource, 'cupIllustration');
    this.showImageDialog = false;
  }

  /** CupIllustration searches validation by total cups
   * @return {boolean} - is valida or not for cup illustration searches */
  public isValidForSearches(): boolean {
    return hasOwnProperty(this.getCurrentItem(), 'totalCups') && this.getCurrentItem().totalCups > 0;
  }

  /** Send notification to CupEvaluators to participate on this cupping session.  */
  public inviteEvaluators(): void {
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('common.buttons.yes'),
      rejectLabel: this.translateService.instant('common.buttons.no'),
      key: 'inviteCupEvaluatorsDialog',
      message: this.translateService.instant('masterdata.dialog.inviteCupEvaluatorsDialog.message'),
      accept: () => {
        this.store.dispatch(sendCupInvitation({ cuppingInvitationId: this.getCurrentItemId() }));
        this.changeResponsePending(true);
      },
      reject: () => {},
    });
  }

  public onLoadImages(): void {
    const { totalCups } = this.getCurrentItem();
    this.cupIllustrationService.onGetWithTenants(totalCups).then(res => {
      this.imageOptions /* CupIllustration[] */ = (res || []).map(cupImg =>
        mergeImmutable(cupImg, {
          source: cupImg?.cupIllustrationImages[0]?.path || '',
          title: this.translateService.instant('common.buttons.select'),
        }),
      );
      this.showImageDialog = nullsafe(res).length > 0;
      if (!this.showImageDialog) {
        this.store.dispatch(
          authNotificationEmitter({
            severity: ToastSeverityEnum.INFO,
            summary: 'common.labels.no_results_found',
          }),
        );
      }
    });
  }

  public onChangeTotalCups(cups: number): void {
    this.onItemFieldChange(cups, 'totalCups');
    const { cupIllustration: cupImg } = this.getCurrentItem();
    // total cups must be equal to cupItem property on cupIllustrationImage
    if (cupImg && cupImg.cupItem !== cups) {
      return this.onItemFieldChange(undefined, 'cupIllustration');
    }
  }

  public onGuestEvaluatorFormConfirmed(evaluatorDto: GuestEvaluatorDto): void {
    // add this evaluator to the queue
    this.store.dispatch(dddGuestEvaluator({ cuppingSessionId: this.getCurrentItemId(), evaluator: evaluatorDto }));
  }

  public onGuestFormConfirmed(): void {
    const guestItem = this.guestFormComponent.onAdd();
    if (!isNullOrUndefined(guestItem)) {
      this.showGuestFormDialog = false;
      this.pendingGuestEvaluators = !isNullOrUndefined(this.pendingGuestEvaluators) ? this.pendingGuestEvaluators : [];
      this.guestFormComponent.clean();
      this.pendingGuestEvaluators.push(guestItem);
      this.store.dispatch(guestEvaluatorsQueue({ evaluators: this.pendingGuestEvaluators }));

      let cupEvaluator: CupEvaluator = new CupEvaluator();
      cupEvaluator = mergeImmutable(cupEvaluator, {
        cupEvaluator: `${guestItem.name} ${guestItem.lastName} (*) `,
        codeCupEvaluator: guestItem.code,
        guest: true,
      });

      this.cuppingSessionEvaluator.onSelectCupEvaluator([cupEvaluator]);
    }
  }

  protected afterConfirmSave(saved: boolean): void {
    if (saved) {
      this.checkSavedInterval = setInterval(() => {
        if (!this.responsePending) {
          if (nullsafe(this.pendingGuestEvaluators).length > 0) {
            const thisSessionId: number = this.getCurrentItemId();
            if (thisSessionId > 0) {
              this.store.dispatch(
                saveGuestEvaluatorsQueue({
                  cuppingSessionId: thisSessionId,
                  metadata: {
                    typeFunction: this.typeFunction,
                    redirectUrl: this.redirectUrl,
                    paginationRequestParams: this.paginationRequestParams,
                  },
                  user: this.currentUser,
                  evaluators: this.pendingGuestEvaluators,
                }),
              );
            }
          }
          // When save a master item and retrieving it, some properties don't return,
          // and new request needs to be made
          // It means retrieving the same data with additional properties
          this.store.dispatch(getCuppingSessionByIdSession({ cuppingSessionId: this.getCurrentItemId() }));
          clearInterval(this.checkSavedInterval);
        }
      }, 500);
    }
  }

  public onSaveClicked(): void {
    const sessionEvaluator: CuppingSessionCupEvaluator[] = this.getCurrentItem().cuppingSessionCupEvaluators || [];
    const deletedGuests: CuppingSessionCupEvaluator[] = sessionEvaluator.filter(x => x.cupEvaluator.id > 0);
    this.onItemFieldChange(deletedGuests, 'cuppingSessionCupEvaluators');
    this.onSave();
  }

  public activeIndexChange(event: number): void {
    this.tabViewIndex = event;
  }

  public isValidationFailure(validationResult: FieldValidationResult): boolean {
    return !isNullOrUndefined(validationResult) && validationResult.invalid;
  }

  private onSetStandardDefinitionOptions(standardDefinitions: StandardDefinition[]): StandardDefinition[] {
    if (nullsafe(standardDefinitions).length > 0)
      return nullsafe(standardDefinitions).filter(
        standardDefinition => nullsafe(standardDefinition.standardDefinitionOptionsSensorial).length > 0,
      );
  }
}
