<span class="nkg-block">
  <span *ngIf="label" class="mainCaption" [ngClass]="{ 'nkg-disabled-label': disabled }">
    {{ label | translate }}
  </span>
  <span class="number-input-wrapper hover-env">
    <ng-container *ngIf="editable; then editableContent; else readOnlyContent"></ng-container>
    <ng-template #editableContent>
      <nkg-error-tooltip [validationResult]="internalValidationResult" [enableCleanValidation]="true">
        <div class="ncs-basic-wrapper">
          <input
            *ngIf="!hasFocus"
            pInputText
            type="text"
            class="focusOutInput setFocus"
            [ngStyle]="inputStyle"
            [attr.tabindex]="focusable ? (tabIndex !== undefined ? tabIndex : null) : -1"
            [disabled]="disabled"
            [value]="stringValue"
            [maxLength]="maxInteger"
            (focus)="onFocus($event)"
            [readonly]="readOnly" />
          <input
            *ngIf="hasFocus"
            #inputChange
            pInputText
            type="number"
            pTooltip="{{ tooltip | translate }}"
            class="focusInput setFocus"
            [ngStyle]="inputStyle"
            [disabled]="disabled"
            [attr.tabindex]="focusable ? (tabIndex !== undefined ? tabIndex : null) : -1"
            [(ngModel)]="internalValue"
            [ngClass]="{ 'arrow-down': onArrowDown }"
            (focus)="onFocus($event)"
            (blur)="onBlur()"
            (change)="onChange($event)"
            (keydown)="onKeyDown($event)"
            [readonly]="readOnly" />
          <span *ngIf="suffix != ''">&nbsp;{{ suffix }}</span>
          <span *ngIf="showbtn" class="number-button-group">
            <span class="p-button button-up p-button-icon-only" (click)="incDecNumber('inc')">
              <i class="pi pi-angle-up"></i>
            </span>
            <span class="p-button button-down p-button-icon-only" (click)="incDecNumber('dec')">
              <i class="pi pi-angle-down"></i>
            </span>
          </span>
          <ng-content></ng-content>
        </div>
      </nkg-error-tooltip>
    </ng-template>
    <ng-template #readOnlyContent>
      <span>{{ value || '' }}</span>
      <span>{{ suffix }}</span>
    </ng-template>
  </span>
</span>
