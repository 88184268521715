import { MasterEffects } from './master.effects';
import { UserEffects } from './user.effects';
import { AuthEffects } from './auth.effects';
import { AppEffects } from './app.effects';
import { MetricEffects } from './metric.effects';
import { StandardDefinitionEffects } from './standard-definition.effects';
import { ThirdPartyEffects } from './third-party.effects';
import { QualityEffects } from './quality.effects';
import { ItemDefinitionEffects } from './item-definition.effects';
import { DispatchEffects } from './dispatch.effects';
import { SampleEffects } from './sample.effects';
import { CuppingSessionEffects } from './cupping-session.effects';
import { CuppingProcessEffects } from './cupping-process.effects';
import { ApprovalEffects } from './approval.effects';

export const effectsArr: any[] = [
  MasterEffects,
  UserEffects,
  AuthEffects,
  AppEffects,
  MetricEffects,
  StandardDefinitionEffects,
  ThirdPartyEffects,
  QualityEffects,
  ItemDefinitionEffects,
  DispatchEffects,
  SampleEffects,
  CuppingSessionEffects,
  CuppingProcessEffects,
  ApprovalEffects,
];

export * from './auth.effects';
export * from './master.effects';
export * from './user.effects';
export * from './app.effects';
export * from './metric.effects';
export * from './standard-definition.effects';
export * from './third-party.effects';
export * from './quality.effects';
export * from './item-definition.effects';
export * from './dispatch.effects';
export * from './sample.effects';
export * from './cupping-session.effects';
export * from './cupping-process.effects';
export * from './approval.effects';
