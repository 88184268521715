<ncs-button-basic
  *ngIf="showButtonSession"
  [label]="'common.buttons.enter_session'"
  inputClass="nkg-primary-btn"
  (onClicked)="onClickedButton()"></ncs-button-basic>

<ncs-dialog-basic
  [headerText]="'common.buttons.enter_session'"
  [visible]="visibleDialog"
  (onHide)="onHideDialog()"
  (onSecondaryClicked)="onHideDialog()"
  [secondaryButtonText]="'common.buttons.close'"
  [primaryButtonShow]="false"
  [primaryButtonText]="'common.buttons.enter_session'">
  <ng-container
    *ngIf="cupEvaluatorSelected || isEditAllCuppingPermission; then isAdmin; else infoMessage"></ng-container>
  <div class="grid dialog-enter-cupping-process">
    <ng-template #isAdmin>
      <div class="col-12" *ngIf="cuppingSession?.status?.code !== 'CL'">
        <!-- CL == CLOSED STATUS -->
        <p-fieldset class="cupping-session-info">
          <p-header style="font-weight: bold"
            >{{ 'common.labels.new_item' | translate }}&nbsp; &#40;{{ selectMetricType?.typeName }}&#41;</p-header
          >
          <div class="col-12 flex flex-wrap justify-content-between">
            <ceis-number-input
              [editable]="false"
              [label]="'common.labels.cups'"
              [value]="cuppingSession?.totalCups || cuppingSession?.standardDefinition?.totalCups">
            </ceis-number-input>
            <ncs-lookupSearch-basic
              [label]="'common.labels.cup_evaluator'"
              [suggestions]="cupEvaluatorSuggestions"
              [fieldReadOnly]="'cupEvaluator'"
              [editable]="isEditAllCuppingPermission"
              [disabled]="!isEditAllCuppingPermission || (!cupEvaluatorSelected && !isEditAllCuppingPermission)"
              [validationResult]="childValidationResult?.fieldResults?.cupEvaluator"
              [value]="cupEvaluatorSelected"
              (onCompleteMethod)="onLoadSuggestions($event?.query)"
              (onSelected)="onItemFieldChange($event, 'cupEvaluator')">
            </ncs-lookupSearch-basic>
            <ncs-button-basic
              *ngIf="showButtonSession"
              [label]="'common.buttons.enter_session'"
              [disabled]="!cupEvaluatorSelected && cuppingSession?.totalCups > 0"
              inputClass="nkg-primary-btn"
              (onClicked)="onEnterToSession()">
            </ncs-button-basic>
          </div>
        </p-fieldset>
      </div>
    </ng-template>
    <ng-template #infoMessage>
      <div class="col-12">
        <span>
          <i class="material-icons" style="color: #ffa413">warning</i>
          {{ 'common.labels.cup_evaluator_not_found' | translate: { param0: currentUser?.displayName } }}&nbsp;
        </span>
      </div>
    </ng-template>

    <div class="col-12" *ngIf="cuppingProcessesRowData">
      <p-fieldset>
        <p-header style="font-weight: bold"
          >{{ 'common.labels.cupping_process' | translate }} / {{ 'common.labels.total' | translate }} :
          {{ cuppingProcessesRowData.length }}</p-header
        >
        <nkg-generic-grid
          *ngIf="visibleDialog"
          [agGridStyle]="{ width: '100%', height: '250px' }"
          [wrappedInCard]="false"
          [quickFilterEnabled]="true"
          [enabledFooter]="false"
          [sizeToFit]="true"
          [rowData]="cuppingProcessesRowData"
          [colDef]="columnDefsCuppingProcess"
          [gridOptions]="gridOptionsEnterCupping"
          [rowId]="'CuppingProcess'"
          (rowDoubleClicked)="rowDoubleClicked($event?.data)">
        </nkg-generic-grid>
      </p-fieldset>
    </div>
  </div>
</ncs-dialog-basic>
