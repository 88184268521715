/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
export function roundToN(number, precision): number {
  const factor = 10 ** precision;
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

export function hasMoreDigits(num, digits): boolean {
  // true for invalid
  for (let i = 0; i < 2; i++) {
    if (roundToN(num, i + digits) !== roundToN(num, i + digits + 1)) {
      return true;
    }
  }
  return false;
}
