import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  createDate,
  createEndOfMonthDate,
  createStartOfMonthDate,
  ncsFormatDateShort,
  ncsFormatDateTimeShort,
  removeTimepart,
  toJavaScriptDateIgnoringTimezone,
  toTimezone,
} from '../../shared/basic-shared-module/utils/date-utils';
import * as fromRoot from '../../core/ngrx/reducers';

// allow for mock impls to provide times and dates
@Injectable()
export class TimeService implements OnDestroy {
  private timezone: string = null; // UTC
  private timezoneSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>) {
    this.timezoneSubscription = this.store.select(fromRoot.getTimezone).subscribe(timezone => {
      if (timezone) {
        this.timezone = timezone.timezone;
      }
    });
  }

  obtainNow(): Date {
    return toTimezone(new Date(), this.timezone);
  }

  obtainTodayDate(): Date {
    return removeTimepart(new Date(), this.timezone);
  }

  toTimezone(date: Date): Date {
    return toTimezone(date, this.timezone);
  }

  formatDateShort(date: Date): string {
    return ncsFormatDateShort(date, 'en', this.timezone);
  }

  formatDateTimeShort(date: Date): string {
    return ncsFormatDateTimeShort(date, 'en', this.timezone);
  }

  // all starting with 1, not 0!
  createDate(year: number, month: number, day: number): Date {
    return createDate(year, month, day, this.timezone);
  }

  // all starting with 1, not 0!
  createEndOfMonthDate(year: number, month: number): Date {
    return createEndOfMonthDate(year, month, this.timezone);
  }

  // all starting with 1, not 0!
  createStartOfMonthDate(year: number, month: number): Date {
    return createStartOfMonthDate(year, month, this.timezone);
  }

  // special function that can mimic a Javascript Date with the same Year Month Date but in a totally different time zone (that is the browser one)
  toJavaScriptDateIgnoringTimezone(date: Date): Date {
    return toJavaScriptDateIgnoringTimezone(date, this.timezone);
  }

  getTimezone(): string {
    return this.timezone;
  }

  ngOnDestroy(): void {
    this.timezoneSubscription.unsubscribe();
  }

  msToTime(duration: number): string {
    const milliseconds = parseInt(String((duration % 1000) / 100), 10);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    /* hours = (hours < 10) ? 0 + hours : hours;
        minutes = (minutes < 10) ? 0 + minutes : minutes;
        seconds = (seconds < 10) ? 0 + seconds : seconds; */

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  convertMS(milliseconds): any {
    let seconds = Math.floor(milliseconds / 1000);
    let minute = Math.floor(seconds / 60);
    let hour = Math.floor(minute / 60);
    const day = Math.floor(hour / 24);
    seconds %= 60;
    minute %= 60;
    hour %= 24;
    return {
      day: day,
      hour: hour,
      minute: minute,
      seconds: seconds,
    };
  }
}
