import { Component, OnDestroy } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CupEvaluator,
  CuppingProcess,
  CuppingProcessType,
  CuppingSession,
  CuppingSessionCupEvaluator,
  CuppingSessionSample,
} from '../../../../../../../../core/domain/models';
import * as fromRoot from '../../../../../../../../core/ngrx/reducers';
import { NkgGridFactory } from '../../../../../../../../shared/grid/services/grid.factory';
import { MasterdataValidationService } from '../../../../../../services/masterdata-validation.service';
import { CuppingSessionService } from '../../../../services/cupping-sesion.service';
import {
  authNotificationEmitter,
  clearAuthNotification,
  getCuppingSessionByIdSession,
  setResponsePending,
} from '../../../../../../../../core/ngrx/actions';
import { isNullOrUndefined } from '../../../../../../../utils/object-utils';
import { ToastSeverityEnum } from '../../../../../../../../core/ngrx/notification-utils';
import { SampleService } from '../../../../../sample/services/sample.service';
import { CuppingProcessService } from '../../../sample-analysis/modules/cupping-process/services/cupping-process.service';

@Component({
  selector: 'app-cupping-session-average',
  templateUrl: './cupping-session-average.component.html',
})
export class CuppingSessionAverageComponent implements OnDestroy {
  public selectedCupEvaluatorsValid: CupEvaluator[];

  public cuppingProcessesAvg: CuppingProcess[];

  public colsCuppingProcesses: any[];

  public cupEvaluatorEntity: Function = CupEvaluator;

  public cuppingProcesses: CuppingProcess[];

  public cuppingSessionSample: CuppingSessionSample;

  public colsCuppingProcessesAvg: ColDef[];

  public editMode: boolean;

  private currentItem: CuppingSession;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected confirmationService: ConfirmationService,
    protected translateService: TranslateService,
    protected store: Store<fromRoot.State>,
    protected gf: NkgGridFactory,
    protected router: Router,
    protected route: ActivatedRoute,
    protected validationService: MasterdataValidationService,
    protected cuppingSessionService: CuppingSessionService,
    protected sampleService: SampleService,
    private cuppingProcessService: CuppingProcessService,
  ) {
    this.colsCuppingProcessesAvg = this.getColDefCuppingProcessAvg();
    this.colsCuppingProcesses = this.getColDefCuppingProcess();
    this.cuppingSessionSubscription();
  }

  private cuppingSessionSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCuppingSession).pipe(filter(item => !isNullOrUndefined(item))),
      this.store.select(fromRoot.getEditMasterModeActive),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentItem, editMode]) => {
        if (this.getCurrentItem() && this.getCurrentItem().id != currentItem.id) {
          this.cuppingProcesses = [];
        }
        this.currentItem = currentItem;
        this.editMode = editMode;
        this.cuppingProcessesAvg = (currentItem.cuppingProcesses || []).filter(
          (cupping: CuppingProcess) => cupping.averageResult,
        );
        this.selectedCupEvaluatorsValid = this.loadValidCupEvaluators(currentItem);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getColDefCuppingProcessAvg(): any[] {
    return this.sampleService.getColDefCuppingProcessAvg();
  }

  private getCurrentItem(): CuppingSession {
    return this.currentItem;
  }

  public onClickCuppingProcess(cuppingProcess: CuppingProcess): void {
    const url: UrlTree = this.router.createUrlTree([`/master/general/cupping-process/${cuppingProcess.sample.id}`], {
      relativeTo: this.route,
      queryParams: {
        cupping_process_id: cuppingProcess.id,
        type: CuppingProcessType.CUPPING_SESSION,
        onlyContent: 1,
      },
    });
    window.open(`#${url.toString()}`, '_blank');
  }

  public reportCupping(cuppingId: number): void {
    this.cuppingProcessService.reportCupping(cuppingId).then(blob => {
      if (blob) {
        window.open(window.URL.createObjectURL(blob));
      }
    });
  }

  public doAverage(): void {
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('common.buttons.yes'),
      rejectLabel: this.translateService.instant('common.buttons.no'),
      key: 'confirmAverage',
      message: this.translateService.instant('masterdata.dialog.doAverage.message'),
      accept: () => {
        // valid if the session has two or more cup evaluator
        if ((this.loadCupEvaluatorsAvailableToAverage() || []).length > 1) {
          // valid if all samples have sample analysis
          // * for each cup evaluator that is valid
          // * have two or more sample analyses
          this.validAllSamples();
        } else {
          // notify,
          // please consider more than one cup evaluator
          authNotificationEmitter({
            severity: ToastSeverityEnum.INFO,
            sticky: true,
            summary: this.translateService.instant('common.labels.invalid_data'),
            detail: this.translateService.instant('common.labels.cup_evaluators'),
          });
        }
      },
      reject: () => {},
    });
  }

  private validAllSamples(): void {
    clearAuthNotification();
    let isValid = false;
    for (const cuppingSessionSample of this.getCurrentItem().cuppingSessionSamples) {
      const cuppingProcessesBySample: CuppingProcess[] =
        this.getCurrentItem().cuppingProcesses ||
        [].filter(
          (x: CuppingProcess) => x.sample.id == cuppingSessionSample.sample.id && !isNullOrUndefined(x.cupEvaluator),
        );

      const summary: string = this.translateService.instant('common.labels.invalid_data');
      const messageSample: string = `${this.translateService.instant(
        'common.labels.sample',
      )} ${cuppingSessionSample.sample.sample} `;

      // valid if exist more than one sample analysis
      if ((cuppingProcessesBySample || []).length > 1) {
        // valid if for each cup evaluator that is valid have one sample analysis
        for (const cuppingSessionCupEvaluator of this.loadCupEvaluatorsAvailableToAverage()) {
          isValid =
            cuppingProcessesBySample.filter(
              (x: CuppingProcess) => x.cupEvaluator.id == cuppingSessionCupEvaluator.cupEvaluator.id,
            ).length > 0;
          if (!isValid) {
            const additionalMessage: string = `${this.translateService.instant('common.labels.cup_evaluator')} ${cuppingSessionCupEvaluator.cupEvaluator.cupEvaluator}`;
            authNotificationEmitter({
              severity: ToastSeverityEnum.INFO,
              sticky: true,
              summary: summary,
              detail: messageSample + additionalMessage,
            });
          }
        }
      } else {
        // notify
        authNotificationEmitter({
          severity: ToastSeverityEnum.INFO,
          sticky: true,
          summary: summary,
          detail: messageSample,
        });
        /* break; */
      }
    }

    if (isValid) {
      this.store.dispatch(setResponsePending({ isResponsePending: true }));
      this.sampleService
        .getCuppingProcessAverageSession(this.getCurrentItem().id)
        .then((cuppingSession: CuppingSession) => {
          this.store.dispatch(getCuppingSessionByIdSession({ cuppingSessionId: cuppingSession.id }));
          this.store.dispatch(setResponsePending({ isResponsePending: false }));
          authNotificationEmitter({
            severity: ToastSeverityEnum.SUCCESS,
            sticky: true,
            summary: this.translateService.instant('notification.success_saved'),
            detail: this.translateService.instant('common.labels.do_average'),
          });
        });
    }
  }

  private loadCupEvaluatorsAvailableToAverage(): CuppingSessionCupEvaluator[] {
    return this.getCurrentItem().cuppingSessionCupEvaluators.filter((x: CuppingSessionCupEvaluator) => x.valid);
  }

  public loadCuppingProcessBySample(cuppingProcess: CuppingProcess): void {
    const { sample } = cuppingProcess;
    this.cuppingSessionSample = this.getCurrentItem().cuppingSessionSamples.find(
      (item: CuppingSessionSample) => item.sample.id == sample.id,
    );
    this.cuppingProcesses = (this.getCurrentItem().cuppingProcesses || []).filter(
      (cupping: CuppingProcess) => !cupping.averageResult && cupping.sample.id == sample.id,
    );
  }

  public getColDefCuppingProcess(): any[] {
    return [
      { field: 'id', header: '#' },
      {
        field: 'cupEvaluator.cupEvaluator',
        header: 'common.labels.cup_evaluator',
      },
      { field: 'sampleWeight', header: 'common.labels.sample_weight' },
      { field: 'value', header: 'common.labels.metrics_type_result_score' },
      { field: 'cuppingDate', header: 'common.labels.date' },
    ];
  }

  private loadValidCupEvaluators(cuppingSession: CuppingSession): CupEvaluator[] {
    return (cuppingSession.cuppingSessionCupEvaluators || [])
      .filter((x: CuppingSessionCupEvaluator) => x.valid)
      .map((x: CuppingSessionCupEvaluator) => x.cupEvaluator);
  }
}
