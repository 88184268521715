import { assign, cloneDeep, isNil } from 'lodash';

/**
 * Return either the given array if it is not null and not undefined, or return the empty array.
 *
 * @param {T[]} array the array which can be null or undefined
 * @returns {T[]} the given array if it is not null and not undefined, otherwise the empty array
 */
export function nullsafe<T>(array?: T[]): T[] {
  if (isNil(array)) {
    return [];
  }
  return array;
}
/**
 * Return if the object is null or undefined
 * @param {any} object the object evaluated
 * @return {boolean} the object is null or undefined
 * */
export function isNullOrUndefined(object: any): boolean {
  return isNil(object);
}

export function hasOwnProperty(object: any, key: string): boolean {
  return Object.prototype.hasOwnProperty.call(object, key);
}

export interface ObjectPath {
  [key: string]: ObjectPath | boolean;
}

export type Path = number | string | PathArray | ObjectPath;

export type PathArray = string[];

/**
 * Build a path specification string from the given ObjectPath.
 *
 * @param path the path to build a path spec string from
 * @returns the path specification string of the given path
 */
export function toPathSpec(path: ObjectPath | boolean): string {
  if (path === null || path === undefined || typeof path === 'boolean') {
    return '';
  }
  let str = '';
  const keys = Object.keys(path);
  let i = 0;
  const _length = keys.length;
  for (const key of keys) {
    let closeParens = false;
    const val = path[key];
    str += key;
    if (typeof val === 'object') {
      if (Object.keys(val).length > 1) {
        str += '(';
        str += toPathSpec(val);
        str += ')';
        closeParens = true;
      } else {
        str += '.';
        str += toPathSpec(val);
      }
    }
    if (i < _length - 1 && !closeParens) str += ',';
    i++;
  }
  return str;
}

export function mergeImmutable<T extends object>(object: T, mapToMerge: object): T {
  /* Create shallow copy, merge changes and make immutable again */
  return assign(cloneDeep(object), mapToMerge);
}
