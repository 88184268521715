<span [ngClass]="{ 'read-only': readOnly }" class="nkg-block autocomplete-magnifier">
  <span *ngIf="label && !onlyGrid" class="mainCaption">{{ label | translate }}</span>
  <ng-content select="[label-info-message]"></ng-content>
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <nkg-error-tooltip [validationResult]="validationResult">
      <p-autoComplete
        class="ncs-basic-wrapper"
        [ngClass]="{
          'lookup-dropdown-active': enableDropDown,
          'lookup-disabled': disabled,
          'search-magnifier': btnMagnifier,
          'ncs-disabled-wrapper': editable && onlyGrid
        }"
        [appendTo]="'body'"
        [baseZIndex]="100"
        [disabled]="disabled"
        [tabindex]="tabIndex"
        [ngModel]="internalSelection ? (multiple ? internalSelection : internalSelection[0]) : ''"
        [suggestions]="suggestions"
        [placeholder]="placeholder || 'grid.labels.searchOoo' | translate"
        [multiple]="multiple"
        [field]="'label'"
        [dropdown]="enableDropDown"
        [showClear]="showClearBtn"
        (completeMethod)="onSearch({ query: $event?.query, page: 0 })"
        (onClear)="onClearSelection()"
        (ngModelChange)="onChangeModel($event)"
        (onUnselect)="onUnSelection($event)"
        (onSelect)="onSelectionEvent($event)"
        (onBlur)="onBlurEvent($event)">
      </p-autoComplete>
      <span *ngIf="btnMagnifier && editable" class="btn-magnifier">
        <p-button
          [ngClass]="{ 'search-name-btn': onlyGrid }"
          [icon]="btnIcon"
          [pTooltip]="searchNameBtn"
          [disabled]="disabled || displayGrid"
          (onClick)="clickedButton($event)">
        </p-button>
      </span>
      <span *ngIf="tooltipInfo && editable" class="tooltip hvr" style="margin-left: 0.5em">
        <span class="fa fa-question-circle"></span>
        <span class="tooltiptext right info">{{ tooltipInfo | translate }}</span>
      </span>
    </nkg-error-tooltip>
    <ng-content></ng-content>
  </ng-template>
  <!-- only content -->
  <ng-template #secondContent>
    <ul class="read-only-list">
      <li style="display: inline-block" *ngFor="let item of internalSelection; let last = last">
        {{ item?.label }}
        <ng-container *ngIf="!last">,&nbsp;</ng-container>
      </li>
    </ul>
  </ng-template>
</span>

<magnifier-basic
  *ngIf="displayGrid && (colDefArray || colDefinition)"
  [gridColumnType]="gridColumnType"
  [titleGrid]="titleGrid"
  [optionsData]="suggestions"
  [colDefArray]="colDefArray"
  [colDefMap]="colDefinition"
  [selectionMode]="multiple ? gridSelectionMode.MULTIPLE : gridSelectionMode.SINGLE"
  [filterEnable]="true"
  [preload]="btnMagnifier"
  [paginationResponse]="paginationResponse"
  (onChangePage)="onSearch({ query: this.lastTargetValue, page: $event })"
  (onSetQuerySearch)="onSearch({ query: $event?.query, page: 0 })"
  (onchange)="getResultSelections($event)"
  (deactivate)="displayGrid = false">
</magnifier-basic>
