import { createReducer, on } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Injector } from '@angular/core';
import { assign, cloneDeep } from 'lodash';
import {
  AppNotification,
  AppTimezoneInfo,
  AppVersionInfo,
  ComtrasInstance,
  Country,
  Language,
  PaginatedResponse,
  Role,
  State,
  Tenant,
  View,
} from '../../domain/models';
import { AppActionTypes } from '../actions';
import * as AppActions from '../actions/app.action';
import { nullsafe } from '../../../modules/utils/object-utils';

export interface AppState {
  viewOpened: View;
  version: AppVersionInfo;
  timezone: AppTimezoneInfo;
  notifications: AppNotification[];
  tenants: Tenant[];
  roles: Role[];
  languages: Language[];
  countries: Country[];
  states: State[];
  backendErrorText: string[];
  backendSuccessText: string[];
  genericSearchItems: PaginatedResponse;
  isResponsePending: boolean;
  // TODO: move tenant feature to masterData section
  comtrasInstances: ComtrasInstance[];
  tenantByPrefix: Tenant;
}

export const initialState: AppState = {
  viewOpened: null,
  version: null,
  timezone: null,
  notifications: [],
  tenants: null,
  roles: null,
  languages: [],
  countries: null,
  states: null,
  backendErrorText: null,
  backendSuccessText: null,
  genericSearchItems: null,
  isResponsePending: false,
  comtrasInstances: [],
  tenantByPrefix: null,
};

// TODO create exclusive file for functionality. This logic is like action creator of last development
function addNotification(state: AppState, action: any): AppNotification[] {
  const injector = Injector.create({
    providers: [{ provide: MessageService, deps: [] }],
  });
  const messageService: MessageService = injector.get(MessageService);
  if (action.type == AppActionTypes.ADD_NOTIFICATION) {
    const notificationsStored = state.notifications;
    const notificationAdd = action.appNotification;
    const notifications = [].concat(notificationAdd, nullsafe(notificationsStored));
    if (action.triggerGrowl && notifications?.length) {
      messageService.add({
        severity: notificationLevelToGrowlMessageSeverity(notificationAdd),
        summary: '',
        detail: buildMessage(notificationAdd),
      });
    }
    return notifications;
  }
  return state.notifications;
}

function notificationLevelToGrowlMessageSeverity(notification: AppNotification): string {
  switch (notification?.level) {
    case 'SUCCESS':
      return 'success';
    case 'INFO':
      return 'info';
    case 'WARN':
      return 'warn';
    case 'ERROR':
      return 'error';
    default:
      return 'info';
  }
}

function buildMessage(n: AppNotification): string {
  // TODO pending translate message
  return `notification${n?.message}`;
}

export const appStateReducer = createReducer(
  initialState,
  on(
    AppActions.setAllLanguages,
    (state: AppState, { languages }): AppState => ({
      ...state,
      languages: languages,
    }),
  ),
  on(
    AppActions.setTimezone,
    (state: AppState, { timezone }): AppState => ({
      ...state,
      timezone: timezone,
    }),
  ),
  on(
    AppActions.setAppVersion,
    (state: AppState, { appVersion }): AppState => ({
      ...state,
      version: appVersion,
    }),
  ),
  on(AppActions.setBreadcrumbNavigation, (state: AppState, action): AppState => {
    let view: View = state.viewOpened;
    if (!state.viewOpened) {
      // no view was yet opened, or the view history should be ignored
      view = action.view;
    } else {
      // there is already a view open
      if (view.uri === action.view.uri) {
        // same view opened again, nothing to be done (can this ever happen?)
      } else if (view.previousView && view.previousView.uri === action.view.uri) {
        // backward navigation, previous view was opened
        view = view.previousView;
      } else if (action.basePath && view && view.uri.startsWith(action.basePath)) {
        // 'stay' - replace last one
        view = assign(cloneDeep(action.view), { previousView: view.previousView });
      } else {
        // forward navigation, push current view on stack
        view = assign(cloneDeep(action.view), { previousView: view });
      }
    }
    return {
      ...state,
      viewOpened: view,
    };
  }),
  on(
    AppActions.setAppNotifications,
    (state: AppState, { notifications }): AppState => ({
      ...state,
      notifications: notifications,
    }),
  ),
  on(AppActions.addAppNotification, (state: AppState, action): AppState => {
    const notificationsStored = addNotification(state, action);
    return {
      ...state,
      notifications: notificationsStored,
    };
  }),
  on(
    AppActions.setSearchGenericItems,
    (state: AppState, { paginationResponse }): AppState => ({
      ...state,
      genericSearchItems: paginationResponse,
    }),
  ),
  on(
    AppActions.fetchSearchGenericItems,
    (state: AppState): AppState => ({
      ...state,
      genericSearchItems: null,
    }),
  ),
  on(
    AppActions.setAppMessages,
    (state: AppState, { messages }): AppState => ({
      ...state,
      backendSuccessText: messages,
    }),
  ),
  on(AppActions.setAppErrorMessages, (state: AppState, { errorMessages }) => ({
    ...state,
    backendErrorMessages: errorMessages,
  })),
  on(AppActions.setAllCountries, (state: AppState, { countries }) => ({
    ...state,
    countries: countries,
  })),
  on(
    AppActions.setAllStates,
    (state: AppState, { states }): AppState => ({
      ...state,
      states: states,
    }),
  ),
  on(
    AppActions.setAllTenants,
    (state: AppState, { tenants }): AppState => ({
      ...state,
      tenants: tenants,
    }),
  ),
  on(
    AppActions.setResponsePending,
    (state: AppState, { isResponsePending }): AppState => ({
      ...state,
      isResponsePending: isResponsePending,
    }),
  ),
  on(
    AppActions.setTenantByCOMTRASPrefix,
    (state: AppState, { tenant }): AppState => ({
      ...state,
      tenantByPrefix: tenant,
    }),
  ),
  on(
    AppActions.setCOMTRASInstance,
    (state: AppState, { comtrasInstances }): AppState => ({
      ...state,
      comtrasInstances: comtrasInstances,
    }),
  ),
);

export const getCurrentViewNavigation = (state: AppState): View => state.viewOpened;
export const getTimezone = (state: AppState): AppTimezoneInfo => state.timezone;
export const getErrorText = (state: AppState): string[] => state.backendErrorText;
export const getSuccessText = (state: AppState): string[] => state.backendSuccessText;
export const getGenericSearchItems = (state: AppState): PaginatedResponse => state.genericSearchItems;
export const getLanguages = (state: AppState): Language[] => state.languages;
export const getCountries = (state: AppState): Country[] => state.countries;
export const getStates = (state: AppState): State[] => state.states;
export const getTenants = (state: AppState): Tenant[] => state.tenants;
export const getNotifications = (state: AppState): AppNotification[] => state.notifications;
export const getIsResponsePending = (state: AppState): boolean => state.isResponsePending;
export const getCOMTRASInstances = (state: AppState): ComtrasInstance[] => state.comtrasInstances;
export const getTenantByCOMTRASPrefix = (state: AppState): Tenant => state.tenantByPrefix;
