import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FieldValidationResult } from '../../../../modules/master/services/master-validation';
import { isNotEmpty } from '../../../../modules/utils/string-utils';
import { isNullOrUndefined } from '../../../../modules/utils/object-utils';
import { isNumber } from '../../../../modules/utils/number-utils';
import { NcsBaseBasicComponent } from '../base-basic/base-basic.component';
import { TooltipMessage } from '../tooltip/tooltip-message.component';

const UP_KEY: number = 38;
const DOWN_KEY: number = 40;

@Component({
  selector: 'ceis-number-input',
  templateUrl: 'number-input.component.html',
})
export class NumberInputComponent extends NcsBaseBasicComponent implements OnChanges, OnInit {
  // TODO: PrimeNG highest version exist numberInput as component
  @Input() value: number;
  @Input() maxValue: number = Number.POSITIVE_INFINITY;
  @Input() minValue: number = Number.NEGATIVE_INFINITY;
  @Input() maxDecimals: number = 2; // maximal number of digits. If not set, it defaults to toLocaleString(this.locale)
  @Input() maxInteger: number = 8;
  @Input() showEnterNumberOnSingleSign: boolean = true;
  @Input() locale: string = 'en';
  @Input() showPlusSign: boolean;
  @Input() validationResult: FieldValidationResult; // avoid two tooltips - use inner one for all
  @Input() infoMessage: TooltipMessage;
  @Input() step: number = 1;

  @Input() focusable: boolean = true;
  @Input() tabIndex: number;
  /** If set to true, then two digits at the end, otherwise zero digits. */
  @Input() decimalMode: boolean = false;
  /** String at the end of the value. */
  // WARN: Separate it from inputNumber because there's an issue when put it in the ngFor directive
  @Input() suffix: string = '';
  @Input() showbtn: boolean = false;

  @Input() tooltip: string;
  @Input() showPinBtn: boolean = true;
  @Output() focusTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  // private filterSearchProps$: Subscription;
  @ViewChild('inputChange') set content(content: ElementRef) {
    if (content) this.inputChange = content;
  }
  private inputChange: ElementRef;
  internalValue: string;
  stringValue: string;
  lastValue: string;
  errorMessage: string;
  errorParam: Object;
  hasFocus: boolean = true;
  isWrongInput: boolean = false;

  onArrowDown: boolean = false;
  internalValidationResult: FieldValidationResult;

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setValue(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationResult || changes.value) {
      this.internalValidationResult = this.getValidationResult();
    }
    const { value } = changes;
    if (value) {
      this.setValue(value.currentValue);
      if (typeof value.currentValue === 'number') {
        this.setWrongInput(value.currentValue.toString());
      }
      this.cd.markForCheck();
    }
    if (changes.disabled) {
      this.isWrongInput = false;
    }
  }

  onChange(event: any): void {
    let { value } = event.target;
    if (isNotEmpty(value)) {
      const reg = /[^\d,.+-]/g;
      const saneInputRegex = /^[-+]?\d+(?:[.,]\d+)?$/;

      value = value.replace(reg, '');

      if (value.includes(',') && value.includes('.')) {
        if (this.lastValue.includes(',')) {
          value = value.replace('.', '');
        } else {
          value = value.replace(',', '');
        }
      } else if (value.includes(',')) {
        value = this.noSecondSign(value, ',');
      } else if (value.includes('.')) {
        value = this.noSecondSign(value, '.');
      }
      if (value[0] === '.' || value[0] === ',') {
        value = `0${value}`;
        // ++cursorPos;
      }

      // const isCorrected = this.internalValue !== value;

      // handle single '-' when allowed
      this.internalValue = value;
      this.lastValue = value;
      event.target.value = value;
      // input not a valid number
      if (!this.internalValue.match(saneInputRegex)) {
        this.value = undefined;
      } else {
        this.value = value ? +this.internalValue.replace(',', '.') : undefined;
      }
      // this.emitChange(this.value);
      this.stringValue = this.formatStringAsNumber(this.internalValue);

      this.cd.markForCheck();

      /* if (isCorrected) {
        event.target.setSelectionRange(cursorPos - 1, cursorPos - 1);
      } else {
        event.target.setSelectionRange(cursorPos, cursorPos);
      } */

      this.setWrongInput(value);
    } else {
      this.setValue(this.minValue);
      // this.value = this.minValue;
      // this.emitChange(this.value);
      // this.stringValue = '';
    }
    this.emitChange(this.value);
  }

  onFocus(focusEvent: any): void {
    focusEvent?.target?.select();
    this.hasFocus = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.focusTrigger.emit(null);
    }, 0);
    // grouping target values for any time and send via output
    const term$ = fromEvent<any>(this.inputChange.nativeElement, 'keyup').pipe(
      map(event => event),
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
    );
    term$.subscribe(query => {
      if (query) this.onChange(query);
    });
  }

  onBlur(): void {
    if (!this.onArrowDown) {
      this.hasFocus = false;
      this.internalValue = this.correctDecimalSeparator(this.internalValue || '');
      this.lastValue = this.internalValue;
      this.cd.markForCheck();
      this.onblur.emit(this.value);
    }
  }

  onKeyDown(event): void {
    if (UP_KEY === event.keyCode) {
      this.incDecNumber('inc');
      event.preventDefault();
    }
    if (DOWN_KEY === event.keyCode) {
      this.incDecNumber('dec');
      event.preventDefault();
    }
  }

  incDecNumber(incDec: string): void {
    if (this.disabled) {
      return;
    }

    /* a single minus is a first, initial valid input for CR-775
     * define how inc and dec on '-' works */
    if (this.internalValue.match('^-$')) {
      // inc(-) = 0 ; dec(-) = -1
      this.internalValue = incDec == 'inc' ? '-1.0' : '0.0';
    }
    this.onArrowDown = false;

    const arr: string[] = this.internalValue.split('.');
    let currDecimals = 1;
    if (arr.length > 1) {
      currDecimals = 10 ** arr[1].length;
    }

    let nbr = +this.internalValue.replace(',', '.');
    nbr = Math.round(currDecimals * (incDec == 'inc' ? nbr + 1 : nbr - 1)) / currDecimals;
    // this.value = nbr;
    this.setWrongInput(nbr.toString());
    // this.emitChange(this.value);
    // this.internalValue = this.correctDecimalSeparator(nbr.toString());
    // this.lastValue = this.internalValue;
    // this.stringValue = this.formatStringAsNumber(this.internalValue);
    this.emitChange(this.value);
    this.cd.markForCheck();
  }

  private emitChange(currentValue: number): void {
    this.onchange.emit(currentValue);
  }

  // CR-775 : '-' is allowed as first (initial) input, threat it as no input to initialValue
  private handleMinusValues(value): string {
    if (this.showEnterNumberOnSingleSign) {
      return isNullOrUndefined(value) ? '' : value.toString();
    }
    if (value && value.toString().match('^-$')) {
      return '';
    }
    return isNullOrUndefined(value) ? '' : value.toString();
  }

  private setValue(value: any): void {
    this.internalValue = this.handleMinusValues(value);
    this.stringValue = this.formatStringAsNumber(this.internalValue);
    this.lastValue = this.internalValue;
  }

  private setWrongInput(inputValue: string): void {
    let error = false;
    if (isNumber(this.maxDecimals) || isNumber(this.maxInteger)) {
      const arr = inputValue.includes('.') ? inputValue.split('.') : inputValue.split(',');
      let safeInteger: string = (arr || [])[0];
      let safeDecimal: string = (arr || [])[1];
      if (!isNullOrUndefined(this.maxDecimals) && arr.length > 1 && arr[1].length > this.maxDecimals) {
        safeDecimal = arr[1].substring(0, this.maxDecimals);
        error = true;
        this.errorMessage = 'numberinput.validation.too_many_decimal_digits';
        this.errorParam = undefined;
      }
      if (isNumber(this.maxInteger) && arr.length > 0 && arr[0].length > this.maxInteger) {
        safeInteger = `${this.maxValue}`;
        error = true;
        this.errorMessage = 'numberinput.validation.too_many_integer_digits';
        this.errorParam = undefined;
      }
      if (isNotEmpty(safeDecimal)) {
        safeInteger = `${safeInteger}.${safeDecimal}`;
      }
      inputValue = safeInteger;
    }

    if (isNumber(this.minValue) || isNumber(this.maxValue)) {
      const minVal = Math.min(this.minValue, this.maxValue);
      const maxVal = Math.max(this.minValue, this.maxValue);
      let nbr = +inputValue.replace(',', '.');
      if (nbr < minVal) {
        error = true;
        this.errorMessage = 'numberinput.validation.less_than_not_permitted';
        this.errorParam = { value: this.minValue };
        nbr = minVal;
      } else if (nbr > maxVal) {
        error = true;
        this.errorMessage = 'numberinput.validation.maximum_not_permitted';
        this.errorParam = { value: this.maxValue };
        nbr = maxVal;
      } else if (Number.isNaN(nbr)) {
        if (this.showEnterNumberOnSingleSign) {
          error = true;
          this.errorMessage = 'numberinput.validation.please_enter_a_number';
          this.errorParam = undefined;
        } else {
          if (!inputValue.toString().match('^-$')) {
            error = true;
            this.errorMessage = 'numberinput.validation.please_enter_a_number';
            this.errorParam = undefined;
          }
          if (inputValue.toString().match('^-$') && this.minValue > 0) {
            error = true;
            this.errorMessage = 'numberinput.validation.please_enter_a_positive_number';
            this.errorParam = undefined;
          }
        }
      }
      this.setValue(nbr);
    }

    this.isWrongInput = error;
    this.internalValidationResult = this.getValidationResult();
    if (error) this.tooltipSeverity = 'error';
  }

  private correctDecimalSeparator(value: string): string {
    if (this.locale === 'de') {
      return value.replace('.', ',');
    }
    return value.replace(',', '.');
  }

  private formatStringAsNumber(str: string): string {
    let res: string;
    res = str.replace(',', '.');
    if (res !== '') {
      const nbr: number = +res;
      if (Number.isNaN(nbr)) {
        return str;
      }
      res = nbr.toLocaleString(this.locale, {
        maximumFractionDigits: this.maxDecimals,
      });
      if (this.showPlusSign && nbr > 0) {
        res = `+${res}`;
      }
    }
    return res;
  }

  private noSecondSign(str: string, separator: string): string {
    if (this.lastValue !== undefined) {
      const signPos = this.lastValue.indexOf(separator);
      if (signPos >= 0 && str.includes(separator)) {
        const first = str.indexOf(separator);
        const last = str.lastIndexOf(separator);
        if (first !== last) {
          if (first === signPos) {
            str = str.slice(0, last) + str.slice(last + 1);
          } else {
            str = str.slice(0, first) + str.slice(first + 1);
          }
        }
      }
    }
    return str;
  }

  // as tooltips become even more difficult - and we want to pass external values: use getters for it
  private translateErrorMessage(): string {
    return this.translate.instant(this.errorMessage, this.errorParam); // safe 2 use instant, we have translation loaded now
  }

  getValidationResult(): FieldValidationResult {
    let vld: FieldValidationResult;
    // QC-683: When there's an external validation, avoid an internal validation message
    if (this.isWrongInput && isNullOrUndefined(this.validationResult)) {
      // we have our own - and need to translate inline, even though this is not nice
      vld = {
        invalid: true,
        errors: [{ message: this.translateErrorMessage(), messageParams: undefined }],
        warnings: [],
      };
    } else {
      vld = this.validationResult;
    }
    return vld;
  }
}
