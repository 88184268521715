import { hasMoreDigits } from '../../../shared/basic-shared-module/components/number-input/number-utils';
import { FieldResults, Rule, validate, Validation, ValidationResult } from './master-validation';
import { mergeImmutable, Path } from '../../utils/object-utils';

export abstract class ValidationService {
  /**
   * Removes a possibly existing FieldValidationResult at the given path from the given validationResult
   *
   * @param {ValidationResult} validationResult
   * @param {Path | string} path
   * @returns {ValidationResult}
   */
  public clearFieldResults(validationResult: ValidationResult, path: Path): ValidationResult {
    if (validationResult && validationResult.fieldResults && path) {
      const newFieldResults: FieldResults = mergeImmutable(validationResult.fieldResults, { path: null });
      return mergeImmutable(validationResult, { fieldResults: newFieldResults });
    }
    return validationResult;
  }

  /**
   * Performs validation of a given source object against the given ruleset. If a path is provided
   * only the attribute depicted by the given path is validated, else the whole source object.
   *
   * Should be called from an implementing validation service.
   *
   * @param source
   * @param {Rule} ruleset
   * @param {Path} path
   * @returns {ValidationResult}
   */
  protected doValidate(source: any, ruleset: Rule, path?: Path): ValidationResult {
    /* Check if path is a simple string */
    if (typeof path === 'string') {
      path = { [path]: true };
    }
    return validate(source, ruleset, path);
  }

  /** basic rules used in the rulesets of many entities */

  tooManyDecimalDigits(v: Validation, maxDecimals: number): void {
    if (typeof v.ctx === 'number' && hasMoreDigits(v.ctx, maxDecimals)) {
      v.error('numberinput.validation.too_many_decimal_digits');
    }
  }

  lessThanNotAllowed(v: Validation, lessThan: number): void {
    if (typeof v.ctx === 'number' && v.ctx < lessThan) {
      v.errorWithparams('numberinput.validation.less_than_not_permitted', {
        value: lessThan,
      });
    }
  }
}
