import { Injectable } from '@angular/core';
import { Rule, validate, ValidationResult } from './master-validation';
import { Path } from '../../utils/object-utils';
import { ValidationService } from './validation.service';

/**
 * Service that allows to validate processing masterdata.
 *
 * Expects to have access to a full list of all processing via store (necessary to validate name and code are unique).
 */
@Injectable()
export class MasterdataValidationService extends ValidationService {
  /**
   * validates the given processing and returns a new immutable validation result. Work on all fields or only the given path
   *
   * @param user
   * @param {Rule} rule
   * @param {Path} path
   * @returns {ValidationResult}
   */
  public validateItem(user: any, rule: Rule, path?: Path): ValidationResult {
    /* Check if a path is a simple string */
    if (typeof path === 'string') {
      path = { [path]: true };
    }
    return validate(user, rule, path);
  }
}
