import { createReducer, on } from '@ngrx/store';
import { CuppingSession, CuppingSessionStatus, EmailConfirmation, GuestEvaluatorDto } from '../../domain/models';

import * as CuppingSessionActionTypes from '../actions/cupping-session.action';

export interface CuppingSessionState {
  cuppingSessions: CuppingSession[];
  cuppingSession: CuppingSession;
  cuppingSessionStatus: CuppingSessionStatus[];
  invitationConfirmation: EmailConfirmation;
  guestEvaluatorQueue: GuestEvaluatorDto[];
}

export const initialCuppingSessionState: CuppingSessionState = {
  cuppingSessions: null,
  cuppingSession: null,
  cuppingSessionStatus: [],
  invitationConfirmation: undefined,
  guestEvaluatorQueue: undefined,
};

export const cuppingSessionReducer = createReducer(
  initialCuppingSessionState,
  on(
    CuppingSessionActionTypes.fetchCuppingSessionSuccess,
    (state: CuppingSessionState, { cuppingSessions }): CuppingSessionState => ({
      ...state,
      cuppingSessions: cuppingSessions,
    }),
  ),
  on(
    CuppingSessionActionTypes.selectCuppingSession,
    (state: CuppingSessionState, { cuppingSession }): CuppingSessionState => ({
      ...state,
      cuppingSession: cuppingSession,
    }),
  ),
  on(
    CuppingSessionActionTypes.fetchCuppingSessionStatusSuccess,
    (state: CuppingSessionState, { cuppingSessionStatus }): CuppingSessionState => ({
      ...state,
      cuppingSessionStatus: cuppingSessionStatus,
    }),
  ),
  on(
    CuppingSessionActionTypes.storeInvitationEmailConfirmation,
    (state: CuppingSessionState, { confirmation }): CuppingSessionState => ({
      ...state,
      invitationConfirmation: confirmation,
    }),
  ),
  on(
    CuppingSessionActionTypes.guestEvaluatorsQueue,
    (state: CuppingSessionState, { evaluators }): CuppingSessionState => ({
      ...state,
      guestEvaluatorQueue: evaluators,
    }),
  ),
  on(
    CuppingSessionActionTypes.resetAllSessions,
    (): CuppingSessionState => ({
      ...initialCuppingSessionState,
    }),
  ),
);

export const getCuppingSessions = (state: CuppingSessionState): CuppingSession[] => state.cuppingSessions;
export const getCuppingSession = (state: CuppingSessionState): CuppingSession => state.cuppingSession;
export const getCuppingSessionStatus = (state: CuppingSessionState): CuppingSessionStatus[] =>
  state.cuppingSessionStatus;
export const getInvitationConfirmation = (state: CuppingSessionState): EmailConfirmation =>
  state.invitationConfirmation;
export const getGuestEvaluatorsQueue = (state: CuppingSessionState): GuestEvaluatorDto[] => state.guestEvaluatorQueue;
