<div style="position: relative">
  <ng-content></ng-content>
  <div class="flex justify-content-start flex-wrap" *ngIf="isRowDataEmpty">
    <div
      class="card message-card info-card overlay"
      [ngClass]="{ 'column-filter-visible': columnFilterVisible }"
      style="width: 50%; position: absolute">
      <span>{{ 'grid.messages.no_rows_found' | translate }}</span>
    </div>
  </div>
</div>
