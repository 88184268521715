<div class="grid col-12">
  <div class="col-12">
    <p-fieldset [ngStyle]="{ width: '100%', overflow: 'scroll' }">
      <p-header [ngStyle]="{ 'font-weight': 'bold' }">{{ 'common.labels.sample_average' | translate }}</p-header>
      <div class="grid">
        <div class="col-12">
          <p-button
            [ngStyle]="{ float: 'left' }"
            [label]="'common.labels.do_average' | translate"
            icon="pi pi-chart-bar"
            iconPos="right"
            (click)="doAverage()">
          </p-button>
        </div>
        <div class="col-12">
          <ncs-table-crud
            [cols]="colsCuppingProcessesAvg"
            [items]="cuppingProcessesAvg"
            [selectionMode]="'whatever'"
            [showOnModal]="false"
            [activateShowDialog]="false"
            [checkbox]="false"
            [editMode]="editMode"
            [addEnable]="false"
            [editEnable]="false"
            [removeEnable]="false"
            [showEnable]="true"
            [itemTemplate]="templateRef2"
            (onRowClick)="onClickCuppingProcess($event)"
            (onRowSelected)="loadCuppingProcessBySample($event)">
            <ng-template let-item #templateRef2>
              <item-td [dataItem]="item" (onClick)="reportCupping($event)"></item-td>
            </ng-template>
          </ncs-table-crud>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="col-12">
    <p-fieldset *ngIf="(cuppingProcesses || []).length">
      <p-header [ngStyle]="{ 'font-weight': 'bold' }">{{ 'common.labels.do_average' | translate }}</p-header>
      <div class="grid">
        <div class="p-col-6">
          <ncs-generic-search
            [label]="'common.labels.cup_evaluators'"
            [typeEntitySearch]="cupEvaluatorEntity"
            [editable]="false"
            [value]="selectedCupEvaluatorsValid"
            [fieldDisplay]="['cupEvaluator']">
          </ncs-generic-search>
        </div>
        <div class="col-12">
          <p-fieldset [ngStyle]="{ width: '100%', overflow: 'scroll' }">
            <p-header [ngStyle]="{ 'font-weight': 'bold' }">
              {{ cuppingSessionSample?.sample?.sample || '-' }}
            </p-header>
            <ncs-table-crud
              [cols]="colsCuppingProcesses"
              [items]="cuppingProcesses"
              [selectionMode]="'whatever'"
              [exportCsv]="false"
              [showOnModal]="false"
              [activateShowDialog]="false"
              [checkbox]="false"
              [editMode]="editMode"
              [addEnable]="false"
              [editEnable]="false"
              [removeEnable]="false"
              [showEnable]="true"
              [itemTemplate]="templateRef"
              (onRowClick)="onClickCuppingProcess($event)">
              <ng-template let-item #templateRef>
                <item-td [dataItem]="item" (onClick)="reportCupping($event)"></item-td>
              </ng-template>
            </ncs-table-crud>
          </p-fieldset>
        </div>
      </div>
    </p-fieldset>
  </div>
</div>
