import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { AfterContentChecked, ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CuppingProcess,
  CuppingProcessMetric,
  CuppingProcessMetricOption,
  MetricDefinition,
  StandardDefinition,
  StandardDefinitionOption,
  StandardDefinitionOptionScoreDescription,
  StandardsDefinitionEquivalences,
} from '../../../../../../../../../../core/domain/models';
import { isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../../../../../../utils/object-utils';
import {
  setMasterDataSelectedItem,
  setMasterEditMode,
  setMasterModifyMode,
} from '../../../../../../../../../../core/ngrx/actions';
import * as fromRoot from '../../../../../../../../../../core/ngrx/reducers';

@Directive()
export abstract class CuppingFormContainerComponent implements OnInit, OnDestroy, AfterContentChecked {
  editMode: boolean = false;
  cuppingProcess: CuppingProcess;
  cuppingProcessMetrics: CuppingProcessMetric[] = [];
  standardDefinition: StandardDefinition;
  sampleWeight: number;
  totalCups: number;

  getMetricTypeResult = CuppingFormContainerComponent.onCheckTypeResult;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  readonly metricTypeResult = MetricTypeResult;

  /**
   * Taking PH or SS values according to {@link MetricDefinitionType} enum
   * @protected
   */
  protected static METRIC_DEFINITION_TYPE_CODE: string;

  protected abstract setAdditionalProperties(
    a: CuppingProcessMetric,
    b?: StandardDefinitionOption,
  ): CuppingProcessMetric;

  protected constructor(
    protected store: Store<fromRoot.State>,
    protected changeDetector: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    // WARNING: avoid start in constructor because doesn't set some necessary vars
    this.cuppingProcessSubscription();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private cuppingProcessSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getMasterCurrentItem).pipe(filter(item => !isNullOrUndefined(item))),
      this.store.select(fromRoot.getCuppingProcessStandardDefinition).pipe(filter(item => !isNullOrUndefined(item))),
      this.store.select(fromRoot.getModifyMasterModeActive),
      this.store.select(fromRoot.getEditMasterModeActive),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([cuppingProcess, standardDefinition, isModified, editMode]) => {
        this.editMode = editMode;
        this.standardDefinition = standardDefinition;
        this.cuppingProcessMetrics = cuppingProcess.cuppingProcessMetrics || [];
        this.cuppingProcess = cuppingProcess;
        this.sampleWeight = cuppingProcess.sampleWeight;
        this.totalCups = cuppingProcess.totalCups;
        // you must wait for it not to emit a new cupping process until the backend retrieves that request.
        if (this.cuppingProcess.id > 0) {
          this.onSetCuppingProcessMetricOptions(this.cuppingProcessMetrics, isModified); // Only Re-order the cupping process after saving
          // New cuppingProcess
        } else if (
          this.cuppingProcess.id < 0 &&
          !isNullOrUndefined(this.standardDefinition) &&
          !(this.cuppingProcessMetrics || []).length &&
          !isModified
        ) {
          this.createCuppingProcessMetrics();
        }
      });
  }

  private createCuppingProcessMetrics(): void {
    if (this.standardDefinition && !(this.cuppingProcessMetrics || []).length) {
      this.store.dispatch(setMasterEditMode({ isEditMode: true }));
      const newProcessMetrics = nullsafe(this.standardDefinition.standardDefinitionOptions)
        .filter(
          (sdo: StandardDefinitionOption) =>
            sdo.metricsDefinition.metricsDefinitionType.typeCode ==
            CuppingFormContainerComponent.METRIC_DEFINITION_TYPE_CODE,
        )
        .map((sdo: StandardDefinitionOption) =>
          this.setAdditionalProperties(
            mergeImmutable(new CuppingProcessMetric(), {
              standardDefinitionOption: sdo,
              position: sdo.position,
              cupsTotal: this.totalCups || this.standardDefinition.totalCups,
            }),
            sdo,
          ),
        );
      this.onSetCuppingProcessMetricOptions(newProcessMetrics);
      this.changeProcessMetrics();
    }
  }

  private onSetCuppingProcessMetricOptions(
    cuppingProcessMetrics: CuppingProcessMetric[],
    isModified: boolean = false,
  ): void {
    let newCuppingProcessMetrics = this.onLoadAllEquivalenceOptions(cuppingProcessMetrics);
    if (!isModified) newCuppingProcessMetrics = this.onSortingCuppingProcess(newCuppingProcessMetrics);
    this.cuppingProcessMetrics = newCuppingProcessMetrics;
  }

  private onLoadAllEquivalenceOptions(cuppingProcessMetrics: CuppingProcessMetric[]): CuppingProcessMetric[] {
    const initialCuppingProcess = [];
    nullsafe(cuppingProcessMetrics).forEach((item: CuppingProcessMetric) => {
      if (this.isOptionListType(item)) {
        const { metricsDefinition: metricSrc } = item.standardDefinitionOption;
        const validMetric: MetricDefinition = this.findMetricWithOptions(metricSrc.id);
        const isPhysical = CuppingFormContainerComponent.METRIC_DEFINITION_TYPE_CODE == MetricDefinitionType.PHYSICAL;
        const allCuppingOptions = this.onGetAllEquivalencesByStandard(validMetric, isPhysical);
        const isSelectable = this.isSelectableOption(CuppingFormContainerComponent.onCheckTypeResult(item));
        if (isSelectable) {
          item = mergeImmutable(item, { additionalCuppingProcessMetricOptions: allCuppingOptions });
        }
        if (item?.id < 0) {
          // Only case: When cuppingProcessMetric is new, need to setting cuppingProcessMetricOptions
          let requiredCuppingOptions = allCuppingOptions;
          if (isSelectable) {
            requiredCuppingOptions = [...allCuppingOptions].filter(
              itemRequired => itemRequired.standardsDefinitionEquivalences.required,
            );
          }
          item = mergeImmutable(item, { cuppingProcessMetricOptions: requiredCuppingOptions });
        }
      }
      initialCuppingProcess.push(item);
    });
    return initialCuppingProcess;
  }

  private findMetricWithOptions(metricId: number): MetricDefinition {
    return (this.standardDefinition.standardDefinitionOptions || [])
      .map(item => item.metricsDefinition)
      .find(item => item.id == metricId);
  }

  /**
   * Evaluate if any metric set options
   * @param {CuppingProcessMetric} cuppingProcessMetric
   * @private
   */
  private isOptionListType(cuppingProcessMetric: CuppingProcessMetric): boolean {
    return cuppingProcessMetric?.standardDefinitionOption.metricsDefinition.optionShowList;
  }

  protected onSortingCuppingProcess(cuppingProcessMetrics: CuppingProcessMetric[]): CuppingProcessMetric[] {
    const sortProcessMetrics = (cuppingProcessMetrics || []).slice();
    return sortProcessMetrics.map(cupProcessMetrics => {
      // WARN: Should it save step property in CuppingProcessMetric?;
      const step = (this.standardDefinition && this.standardDefinition.step) || 0.25;
      cupProcessMetrics = mergeImmutable(cupProcessMetrics, { step: step });
      const cuppingProcessMetricOption = (cupProcessMetrics.cuppingProcessMetricOptions || []).slice();
      // TODO: Sort feature should be from backend
      const sortCuppingProcessOption = cuppingProcessMetricOption.sort(
        (a, b) => a.standardsDefinitionEquivalences?.position || 0 - b.standardsDefinitionEquivalences?.position || 0,
      );
      return mergeImmutable(cupProcessMetrics, {
        cuppingProcessMetricOptions: sortCuppingProcessOption,
      });
    });
  }

  /**
   * Get all {@link StandardsDefinitionEquivalences} by current {@link StandardDefinition}.
   * @param {MetricDefinition} matchMetric Filtering all equivalences by this
   * @param {boolean} isPhysical When cupping is not physical needs setting initial value result
   * @return {CuppingProcessMetricOption[]} All equivalences filtered are converted to {@link CuppingProcessMetricOption} arrays.
   * @protected
   */
  protected onGetAllEquivalencesByStandard(
    matchMetric: MetricDefinition,
    isPhysical: boolean,
  ): CuppingProcessMetricOption[] {
    let cuppingProcessMetricOptions: CuppingProcessMetricOption[] = [];
    // All cuppingProcessOptions(Metric Definition Options) must be added as StandardEquivalences
    // and ensure displaying as options or suggested options.
    // These items are available into Standard Definition master (Right grid)
    const standardFind = (this.standardDefinition.standardDefinitionOptions || []).find(
      item => item.metricsDefinition.id == matchMetric.id,
    );
    (standardFind?.standardsDefinitionEquivalences || []).map((equiv: StandardsDefinitionEquivalences) => {
      (matchMetric.metricsDefinitionOptions || []).some(matchOption => {
        if (matchOption.id == equiv.metricsDefinitionOption.id) {
          const newCuppingMetricOpt = new CuppingProcessMetricOption();
          newCuppingMetricOpt.standardsDefinitionEquivalences = equiv;
          if (equiv.required && equiv.metricsDefinitionOption.optionNumeric && !isPhysical) {
            newCuppingMetricOpt.valueResult = equiv.metricsDefinitionOption.optionNumericMin;
          }
          cuppingProcessMetricOptions.push(newCuppingMetricOpt);
          return true;
        }
        return false;
      });
    });

    // TODO: Sort feature should be from backend
    cuppingProcessMetricOptions = cuppingProcessMetricOptions.sort(
      (a, b) => a.standardsDefinitionEquivalences.position - b.standardsDefinitionEquivalences.position,
    );
    return cuppingProcessMetricOptions;
  }

  public onChangeProcessMetric(processMetric: CuppingProcessMetric, idx: number): void {
    if (this.editMode) {
      const newProcessMetrics = this.cuppingProcessMetrics.slice();
      newProcessMetrics[idx] = mergeImmutable(newProcessMetrics[idx], processMetric);
      this.cuppingProcessMetrics = newProcessMetrics;
      this.changeProcessMetrics();
    }
  }

  /** Get all Score values descriptions.
   *  Only retrieves descriptions of type result {@link MetricTypeResult.SCORE} */
  public onGetScoreDescription(cuppingProcessMetric: CuppingProcessMetric): StandardDefinitionOptionScoreDescription[] {
    const metricDefinitionIdByCPM = cuppingProcessMetric.standardDefinitionOption.metricsDefinition.id;
    const standardDefinitionOption =
      this.standardDefinition &&
      (this.standardDefinition.standardDefinitionOptions || []).find(
        item => item.metricsDefinition.id == metricDefinitionIdByCPM,
      );
    return (
      (standardDefinitionOption && standardDefinitionOption.standardDefinitionOptionScoreDescriptions) ||
      []
    ).filter(scoreDescription => {
      const metricDefinitionComparison = scoreDescription.metricsDefinition.id || scoreDescription.metricsDefinition;
      return metricDefinitionComparison === cuppingProcessMetric.standardDefinitionOption.metricsDefinition.id;
    });
  }

  /** Check metric type result code
   * @param cuppingProcessMetric {CuppingProcessMetric}
   * @return metric type result code
   * */
  static onCheckTypeResult(cuppingProcessMetric: CuppingProcessMetric): string {
    if (!isNullOrUndefined(cuppingProcessMetric))
      return cuppingProcessMetric.standardDefinitionOption.metricsDefinition.metricsDefinitionTypeResult.typeResultCode;
    return '';
  }

  public trackByIdx(i: number): number {
    return i;
  }

  /**
   * Get all changes when any cupping item is changed. In addition, set value results only sensorial cupping
   * @private
   */
  private changeProcessMetrics(): void {
    // WARN: Important set modified as true and avoid reset logic again
    let finalScore: number = 0;
    // Convenient uses abstract const because did set the first time and doesn't change its value
    if (CuppingFormContainerComponent.METRIC_DEFINITION_TYPE_CODE == MetricDefinitionType.SENSORIAL) {
      // let finalScore: number = 0;
      finalScore = this.cuppingProcessMetrics
        .filter(item => this.onCheckIncludesIntoScore(item))
        .reduce((a, b) => a + (b?.valueResult || 0), 0);
    }

    const updateCurrentItem = mergeImmutable(this.cuppingProcess, {
      cuppingProcessMetrics: this.cuppingProcessMetrics,
      value: Number(finalScore.toFixed(2)),
    });
    if (this.editMode) {
      // WARN: Important set modified as true and avoid reset logic again
      this.store.dispatch(setMasterModifyMode({ isModifyMode: true }));
    }

    this.store.dispatch(setMasterDataSelectedItem({ selectedItem: updateCurrentItem }));
  }

  /**
   * Check if a value result is can to add into final scoring of any Cupping
   * Current exclusion: OPT, CBF and RL type result
   * @param cuppingProcessMetric {CuppingProcessMetric}
   * @return boolean
   */
  public onCheckIncludesIntoScore(cuppingProcessMetric: CuppingProcessMetric): boolean {
    if (!isNullOrUndefined(cuppingProcessMetric))
      return cuppingProcessMetric.standardDefinitionOption.metricsDefinition.metricsDefinitionTypeResult
        .typeResultScore;
    return false;
  }

  /**
   * Evaluate whether any metric needs options for selection.
   * When it's selectable, it means that no metric options are added in an initial load.
   * @param {string} typeResult metric type result code
   * @private
   */
  private isSelectableOption(typeResult: String): boolean {
    return [
      MetricTypeResult.COLOR_OPTION,
      MetricTypeResult.CUP_DEFECT,
      MetricTypeResult.SCORE,
      MetricTypeResult.OPTION,
      MetricTypeResult.DEFECT,
    ].some((item: String) => item == typeResult);
  }
}

/** Metric Definition type codes: Physical (PH) and Sensorial (SS) */
export enum MetricDefinitionType {
  PHYSICAL = 'PH',
  SENSORIAL = 'SS',
}

/**
 * Metrics Definition Type results codes
 * Used on cupping-forms templates: physical and sensory
 */
export enum MetricTypeResult {
  CUP_DEFECT = 'CD',
  ROAST_LEVEL = 'RL',
  SCREEN = 'SCR',
  SAMPLE_PERCENT = 'SP',
  DEFECT = 'DF',
  NUMERIC = 'NM',
  OPTION = 'OPT',
  COLOR_OPTION = 'COP',
  CHECKBOX_FRAME = 'CBF',
  OPTION_CUP_LIST_VAL = 'OPCV',
  OPTION_CUP_LIST_NO_VAL = 'OPC',
  SCORE = 'SC',
  CUP_SCALE = 'CS',
  OPTION_PER_CUP_VAL = 'OPCIV',
  OPTION_PER_CUP_NO_VAL = 'OPCI',
}
