import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../../../core/ngrx/reducers';
import { AbstractCeisService } from '../../../core/service/app.abstract.service';
import { nullsafe } from '../../utils/object-utils';
import { fetchLanguages } from '../../../core/ngrx/actions';

export const LanguageGuard: CanActivateFn = () => {
  const store = inject(Store<fromRoot.State>);
  return store.select(fromRoot.getListLanguages).pipe(
    map((languages: any[]) => {
      // for first load, need token for do any petition to server
      const token = !!localStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN);
      if (token && !nullsafe(languages).length) {
        store.dispatch(fetchLanguages());
      }
      return true;
    }),
    take(1),
  );
};
