<p-dialog
  [styleClass]="'customize-table-overlay-dialog layout-wrapper layout-compact'"
  [visible]="visibleFinally"
  [closeOnEscape]="false"
  modal="modal"
  [resizable]="false"
  [blockScroll]="true"
  [closable]="false"
  [header]="'common.labels.choose_table_columns' | translate"
  [modal]="true"
  [appendTo]="'body'"
  [style]="{ width: '1000px' }"
  [transitionOptions]="'400ms cubic-bezier(0.86, 0, 0.07, 1)'">
  <p-pickList
    [source]="availableColumns"
    [target]="currentColVisibility"
    sourceHeader="{{ 'common.labels.available_columns' | translate }}"
    targetHeader="{{ 'common.labels.current_columns' | translate }}"
    [responsive]="true"
    dragdrop="true"
    [sourceStyle]="{ height: '300px' }"
    [targetStyle]="{ height: '300px' }"
    [showSourceControls]="false"
    (onMoveToSource)="columnsChanges = true"
    (onMoveAllToSource)="columnsChanges = true"
    (onMoveAllToTarget)="columnsChanges = true"
    (onMoveToTarget)="columnsChanges = true"
    (onSourceReorder)="columnsChanges = true"
    (onTargetReorder)="columnsChanges = true">
    <ng-template let-column pTemplate="item">
      <ng-container *ngIf="isColumnValid(column); let col">
        <div class="ui-helper-clearfix" style="padding: 0.5em 1em; display: flex; flex-direction: column">
          <span>{{ col.column.getColDef().headerName }}</span>
        </div>
      </ng-container>
    </ng-template>
  </p-pickList>

  <p-footer style="display: inline-block">
    <ncs-button-basic
      inputClass="nkg-secondary-btn"
      style="float: left"
      (onClicked)="onCancel()"
      [label]="'common.buttons.discard'"></ncs-button-basic>
    <ncs-button-basic
      inputClass="nkg-secondary-btn show-search-generic"
      (onClicked)="onSetAsDefaultView()"
      [label]="'masterdata.list.labels.standard_view_column'"></ncs-button-basic>
    <ncs-button-basic
      inputClass="nkg-primary-btn"
      style="float: right"
      (onClicked)="onApplyChanges()"
      [disabled]="!columnsChanges"
      [label]="'common.buttons.apply'"></ncs-button-basic>
  </p-footer>
</p-dialog>
