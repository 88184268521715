<nkg-tooltip [messages]="validationResult" [fieldWithIcon]="true">
  <nkg-tooltip-target>
    <span class="nkg-block" [title]="title | translate">
      <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
      <ng-template #primaryContent>
        <span *ngIf="labelTop && label" class="mainCaption">{{ label | translate }}</span>
        <p-checkbox
          [disabled]="disabled"
          [ngModel]="value"
          [tabindex]="tabIndex"
          [binary]="true"
          (onChange)="onChangeEvent($event)">
        </p-checkbox>
        <span *ngIf="!labelTop && label" class="mainCaption" [ngClass]="inputClass" style="display: inline-block">
          &nbsp;{{ label | translate }}
        </span>
        <span *ngIf="tooltipInfo" class="tooltip hvr" style="display: inline-block; margin-left: 0.5em">
          <span class="fa fa-question-circle" style="font-size: 1.2em"></span>
          <span class="tooltiptext right info" [ngClass]="tooltipInfoAlign">{{ tooltipInfo | translate }}</span>
        </span>
      </ng-template>
      <ng-template #secondContent>
        <span>{{ value }}</span>
      </ng-template>
      <ng-content></ng-content>
    </span>
  </nkg-tooltip-target>
</nkg-tooltip>
