import { Component, Input } from '@angular/core';
import { FieldValidationResult, Message } from '../../../../modules/master/services/master-validation';
import { nullsafe } from '../../../../modules/utils/object-utils';

@Component({
  selector: 'nkg-error-tooltip',
  templateUrl: './error-tooltip.component.html',
})
export class ErrorTooltipComponent {
  // make conversion from existing code as simple as poss.

  @Input() validationResult: FieldValidationResult;
  @Input() checkbox: boolean = false; // for formatting
  @Input() ngStyle: any;
  @Input() enableCleanValidation: boolean = false;

  isFieldInvalid(): boolean {
    if (this.validationResult) {
      return this.validationResult.invalid;
    }
    return false;
  }

  isOnlyWarnings(): boolean {
    if (this.validationResult) {
      return nullsafe(this.validationResult.errors).length == 0 && nullsafe(this.validationResult.warnings).length > 0;
    }
    return false;
  }

  getValidationMessages(): Message[] {
    if (this.validationResult) {
      return [].concat(nullsafe(this.validationResult.errors), nullsafe(this.validationResult.warnings));
    }
    return [];
  }

  public cleanValidationResult(): void {
    if (this.enableCleanValidation) {
      this.validationResult = undefined;
    }
  }
}
