<span class="nkg-block full-size-block" (click)="handleLabelClick($event)" [ngStyle]="labelStyle">
  <nkg-tooltip
    [messages]="validationResult"
    [fieldWithIcon]="true"
    [tabindex]="tabIndex !== undefined ? tabIndex : null">
    <nkg-tooltip-target style="position: relative; display: inline-block">
      <span *ngIf="label" class="mainCaption" [ngClass]="{ 'nkg-disabled-label': disabled }">
        {{ label | translate }}
      </span>
      <p-selectButton
        [tabindex]="tabIndex"
        [options]="options"
        [ngModel]="selection"
        [disabled]="disabled"
        [multiple]="multiple"
        (onChange)="onChanges($event?.value)">
      </p-selectButton>
      <ng-content></ng-content>
    </nkg-tooltip-target>
  </nkg-tooltip>
</span>
