import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NcsBaseBasicComponent } from '../base-basic/base-basic.component';
import { FieldValidationResult } from '../../../../modules/master/services/master-validation';

@Component({
  selector: 'ncs-textarea-basic',
  templateUrl: './textarea-basic.component.html',
})
export class NcsTextareaBasicComponent extends NcsBaseBasicComponent {
  @Input() placeholder: string;
  @Input() title: string;
  @Input() height: number = 45;
  @Input() width: number;
  @Input() validationResult: FieldValidationResult;
  @Input() focusable: boolean = true;
  @Input() maxLengthCharacter: number = 342345;
  @Output() onblur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();
  @Input() rows: 300;
  @Input() cols: 500;

  focusIn: boolean = false;
}
