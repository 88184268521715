import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
  selector: 'number-inline-cell-editor-component',
  templateUrl: './number-inline-cell-editor.component.html',
})
export class NumberInlineCellEditorComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('numberInlineCellEditor') public input: ViewContainerRef;

  params: any;
  value: number;
  maxValue: number;
  minValue: number;
  maxDecimals: number;
  maxInteger: number;
  ngStyle: any;
  cellEditorStyle: any;
  locale: string;
  showPlusSign: boolean;
  calcMaxValueCallback: (_: any) => number;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input.element.nativeElement.querySelector('.setFocus').focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.maxValue = params.maxValue || Number.POSITIVE_INFINITY;
    this.minValue = params.minValue || Number.NEGATIVE_INFINITY;
    this.maxDecimals = params.maxDecimals;
    this.maxInteger = params.maxInteger;
    this.ngStyle = params.ngStyle;
    this.cellEditorStyle = params.cellEditorStyle;
    this.locale = params.locale || 'en';
    this.showPlusSign = params.showPlusSign;
    this.calcMaxValueCallback = params.calcMaxValueCallback;
    if (this.calcMaxValueCallback) {
      this.maxValue = this.calcMaxValueCallback(params.node.data);
    }
  }

  onValueChange(event): void {
    this.value = event.value;
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return true;
  }

  onKeyDown(event): void {
    const key = event.which || event.keyCode;
    if (key == 37 || key == 38 || key == 39 || key == 40) {
      event.stopPropagation();
    }
  }
}
